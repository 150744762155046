import {alpha, useTheme} from '@mui/material/styles';
import {InputAdornment} from '@mui/material';
import Iconify from './iconify';
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {useTranslation} from 'react-i18next';
import {MRT_Localization_RU} from 'material-react-table/locales/ru';
import {MRT_Localization_EN} from 'material-react-table/locales/en';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useActions} from '../hook/useActions';

const MaterialTable = ({
                         data,
                         columns,
                         loading = false,
                         initialState = {},
                         id = null,
                         ...props
                       }) => {
  // const {t, i18n} = useTranslation();
  //const language = i18n.language;
  const theme = useTheme();
  const [localization, setLocalization] = useState(MRT_Localization_RU);
  // useEffect(() => {
  //   switch (language) {
  //     case 'ru':
  //       setLocalization(MRT_Localization_RU);
  //       break;
  //     case 'en':
  //       setLocalization(MRT_Localization_EN);
  //       break;
  //     default:
  //       setLocalization(MRT_Localization_EN);
  //   }
  // }, [language])

  const tableStates = useSelector((state) => state.tableStatesReducer);
  const {setTableState} = useActions();

  useEffect(() => {
    if (id) {
      return () => {
        const tableState = table.getState();
        setTableState({id, tableState});
      };
    }
  }, [id]);

  const savedState = tableStates[id];
  const pageSize = savedState?.pagination?.pageSize;
  const sorting = savedState?.sorting;
  const globalFilter = savedState?.globalFilter;
  const columnFilters = savedState?.columnFilters;
  const showColumnFilters = savedState?.showColumnFilters;

  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    localization: localization,
    enableColumnFilterModes: true,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    positionGlobalFilter: 'left',
    globalFilterFn: 'contains',
    paginationDisplayMode: 'pages',
    muiPaginationProps: {
      siblingCount: 2,
    },
    initialState: {
      showGlobalFilter: true,
      pagination: {pageIndex: 0, pageSize: pageSize || 5},
      sorting: sorting || [],
      globalFilter: globalFilter || [],
      columnFilters: columnFilters || [],
      showColumnFilters: showColumnFilters || false,
      ...initialState,
    },
    state: {isLoading: loading},
    muiTableHeadCellProps: {
      sx: {
        py: 2,
        backgroundColor: 'background.paper',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        py: 2,
        backgroundColor: 'background.paper',
      },
    },
    muiTopToolbarProps: {
      sx: {
        px: '8px !important',
        backgroundColor: 'background.paper',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTablePaperProps: {elevation: 0},
    muiSearchTextFieldProps: {
      // placeholder: t('src.sections.@dashboard.search'),
      placeholder: 'Поиск',
      InputProps: {
        sx: {
          width: '240px',
          transition: theme.transitions.create(['box-shadow', 'width'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          '&.Mui-focused': {
            width: '320px',
            boxShadow: theme.customShadows.z8,
          },
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
          },
        },
        startAdornment: (
          <InputAdornment position='start'>
            <Iconify
              icon='eva:search-fill'
              sx={{color: 'text.disabled', width: 20, height: 20}}
            />
          </InputAdornment>
        ),
      },
      variant: 'outlined',
      size: 'small',
    },
    ...props,
  });

  return (
    <MaterialReactTable table={table}/>
  );
};

export default MaterialTable;
