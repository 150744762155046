import React, {useEffect, useMemo, useState} from 'react';
import Stack from '@mui/material/Stack';
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Typography
} from '@mui/material';
import Container from '@mui/material/Container';
import {useTranslation} from 'react-i18next';
import ProdCalen from '../components/ProdCalen';
import {useSelector} from 'react-redux';
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import {$authHost} from "../http";
import {useActions} from "../hook/useActions";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {LocalizationProvider, MonthCalendar, YearCalendar} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TableCellsWrap} from "../components/TableCellsWrap";
import {TIMESHEET_STATUSES} from "../constants";
import MaterialTable from "../components/MaterialTable";

const LinearProgressWithLabel = ({label, currentValue, totalValue}) => {
  const progress = (currentValue / totalValue * 100)

  return (
    <Stack alignItems='center'>
      <Typography>
        {label}
      </Typography>
      <Stack direction='row' alignItems='center' spacing={2} sx={{width: '100%'}}>
        <Typography variant='subtitle1' sx={{minWidth: 30}} textAlign='end'>
          {currentValue}
        </Typography>
        <LinearProgress variant="determinate" sx={{height: 16, width: '100%'}} value={progress > 100 ? 100 : progress}/>
        <Typography variant='subtitle1' sx={{minWidth: 30}}>
          {totalValue}
        </Typography>
      </Stack>
    </Stack>
  )
}

const MainPage = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingTimesheet, setLoadingTimesheet] = useState(false);
  const [users, setUsers] = useState([]);
  const [timesheet, setTimesheet] = useState({});
  const [monthCalendar, setMonthCalendar] = useState(dayjs().format('M'));
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);
  const {display_name} = useSelector((state) => state.authReducer);
  const {statistics} = useSelector((state) => state.statisticsReducer);
  const {setStatistics} = useActions();

  const {
    teams_tracked_hours,
    clickup_tracked_hours,
    plan_month_hours,
    vacation_days,
    plan_vacation_days,
    sick_days,
    plan_sick_days,
    overworked_days,
    summarized_clickup_trackers = [],
    clickup_closed_tasks = [],
    clickup_day_trackers = [],
  } = statistics;

  const loadStatistics = async () => {
    setLoading(true);

    try {
      const selectedMonth = dayjs(month).month() + 1;
      const selectedYear = dayjs(year).year();

      const response = await $authHost.get(
        `/statistics/me/?month=${selectedMonth}&year=${selectedYear}`,
      );

      setStatistics(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const loadUsers = async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/users/all/birthday/?month=${monthCalendar}`,
      );

      setUsers(response.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const loadTimesheet = async () => {
    setLoadingTimesheet(true);

    try {
      const selectedMonth = dayjs(month).month() + 1;
      const selectedYear = dayjs(year).year();

      const response = await $authHost.get(`/report_cards/me/?year=${selectedYear}&month=${selectedMonth}&is_calendar_with_month=false`);
      setTimesheet([response.data]);
    } catch (e) {
    } finally {
      setLoadingTimesheet(false);
    }
  };

  useEffect(() => {
    loadStatistics();
    loadTimesheet();
  }, [month, year]);

  useEffect(() => {
    loadUsers();
  }, [monthCalendar]);

  const options1 = {
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      // width: 3,
    },
    grid: {
      // borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    xaxis: {
      // categories: days,
      // title: {
      //   text: 'Дни'
      // },
      // type: 'category',
      // type: 'numeric',
      type: 'datetime',
      // tickAmount: 'dataPoints',
      // tickPlacement: 'between',
      labels: {
        format: 'd MMM',
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format('D MMM')
        },
      },
    },
    yaxis: {
      title: {
        text: 'Часы'
      },
      // min: 0,
      // max: 120
    },
    noData: {
      text: 'Нет данных для отображения',
      style: {
        color: '#000000',
        fontSize: '16px',
      }
    }
  }

  const series1 =
    [
      {
        name: "Кол-во часов",
        data: summarized_clickup_trackers,
        color: '#2065D1',
      }
    ]

  const options2 = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: [0, 2],
      curve: ['smooth', 'stepline']
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'd MMM',
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format('D MMM')
        },
      },
    },
    yaxis: [
      {
        title: {
          text: 'Закрытые задачи',
        },
      },
      {
        opposite: true,
        title: {
          text: 'Часы'
        }
      }
    ],
    noData: {
      text: 'Нет данных для отображения',
      style: {
        color: '#000000',
        fontSize: '16px',
      }
    }
  }


  const series2 = [
    {
      name: 'Закрытые задачи',
      type: 'column',
      data: clickup_closed_tasks,
      color: '#aac4ed',
    },
    {
      name: 'Часы',
      type: 'line',
      data: clickup_day_trackers,
      color: '#2065D1',
    }
  ]

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, 'month');

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, 'month');

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const days = useMemo(() => {
    const daysInMonth = dayjs(month).daysInMonth();
    const daysArr = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArr.push(i);
    }

    return daysArr;
  }, [month]);

  const tableColumns = [
    ...days.map((d) => ({
      accessorKey: `calendar.${d.toString()}`,
      header: d.toString(),
      size: 1,
      muiTableBodyCellProps: ({cell, table}) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find((status) => status.value === value);
        if ((status && !status?.editing) || !status) {
          return {
            sx: {
              backgroundColor: `${status?.color} !important`,
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              cursor: 'default',
            },
          };
        } else {
          return {
            sx: {
              backgroundColor: `${status?.color} !important`,
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
          };
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    })),
  ];

  return (
    <Container sx={{pb: 0}}>
      <Stack
        direction='row'
        alignItems='center'
        sx={{height: '70px'}}
        justifyContent='space-between'
        mb={0}
      >
        <Typography variant='h4' gutterBottom>
          Главная
        </Typography>
      </Stack>
      <Stack spacing={2} pb={2}>
        <Card sx={{p: 2}}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant='h5'>
                  Мои данные:
                </Typography>
                <Stack spacing={1} pt={2} px={2}>
                  <Typography variant='h6' pb={1}>
                    {display_name}
                  </Typography>
                  <LinearProgressWithLabel label='Количество затреканных часов в месяц'
                                           currentValue={clickup_tracked_hours}
                                           totalValue={plan_month_hours}/>
                  <LinearProgressWithLabel label='Количество отпускных дней' currentValue={vacation_days}
                                           totalValue={plan_vacation_days}/>
                  <LinearProgressWithLabel label='Количество оплачиваемых больничных' currentValue={sick_days}
                                           totalValue={plan_sick_days}/>
                  <Typography textAlign='center'>
                    Количество переработок: <b>{overworked_days}</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' pb={2}>
                  Производственный календарь:
                </Typography>
                <Stack px={2} direction='row' spacing={2}>
                  <ProdCalen setDate={setMonthCalendar} users={users}/>
                  <Stack spacing={0.5}>
                    <Typography pb={1}>
                      Дни рождения в этом месяце:
                    </Typography>
                    {Array.isArray(users) && users.length > 0 && users.map(user => (
                      <Typography variant='body2' key={user?.source_id}>
                        {dayjs(user.birth_date).format('DD.MM')} - {user.display_name}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Card>
        <Card sx={{p: 2}}>
          <Stack spacing={2}>
            <Stack>
              <Stack direction='row' justifyContent='space-between'>
                <Typography variant='h5'>
                  Учет рабочего времени:
                </Typography>
                <Stack direction='row' alignItems='center'>
                  <IconButton onClick={handlePrev}>
                    <ArrowCircleLeftIcon fontSize='large'/>
                  </IconButton>
                  <ButtonGroup variant='outlined'>
                    <Button
                      onClick={handleClickMonth}
                      sx={{minWidth: '110px !important'}}
                    >
                      {dayjs(month).format('MMMM')}
                    </Button>
                    <Button
                      onClick={handleClickYear}
                      sx={{minWidth: '70px !important'}}
                    >
                      {dayjs(year).format('YYYY')}
                    </Button>
                  </ButtonGroup>
                  <IconButton onClick={handleNext}>
                    <ArrowCircleRightIcon fontSize='large'/>
                  </IconButton>
                  <Popover
                    open={!!anchorMonth}
                    anchorEl={anchorMonth}
                    onClose={handleCloseMonth}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MonthCalendar onChange={(v) => setMonth(v)} value={month}/>
                    </LocalizationProvider>
                  </Popover>
                  <Popover
                    open={!!anchorYear}
                    anchorEl={anchorYear}
                    onClose={handleCloseYear}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <YearCalendar onChange={(v) => setYear(v)} value={year}/>
                    </LocalizationProvider>
                  </Popover>
                </Stack>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ReactApexChart options={options1} series={series1} type="line" height={350}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ReactApexChart options={options2} series={series2} type="line" height={350}/>
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={2}>
              <Typography variant='h5'>
                Табель рабочего времени:
              </Typography>
              <MaterialTable
                data={timesheet}
                columns={tableColumns}
                initialState={{
                  density: 'compact',
                }}
                state={{isLoading: loadingTimesheet}}
                enableBottomToolbar={false}
                enableTopToolbar={false}
                enableSorting={false}
                muiTableContainerProps={{
                  sx: {
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                    borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    backgroundColor: '#ffffff'
                  },
                }}
              />
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  )
    ;
};

export default MainPage;
