import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  clients: [],
  clientsAll: [],
  selectedClient: null,
  clientsTypeFormIsAdd: false
};

const ClientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    logOut() { return initialState },
    setClients(state, { payload }) { state.clients = payload },
    setClientsAll(state, { payload }) { state.clientsAll = payload },
    setSelectedClient(state, { payload }) {
      const index = state.clients.findIndex(
        (project) => project.source_id === payload,
      );
      state.selectedClient = state.clients[index];
    },
    setClientsTypeFormIsAdd(state, { payload }){ state.clientsTypeFormIsAdd = payload  },
    updateFullSelectedClient(state, { payload }){ state.selectedClient = payload  }

  },
});

export const { actions: clientsActions, reducer: clientsReducer } = ClientsSlice
export default ClientsSlice.reducer;
