import {Autocomplete, autocompleteClasses, ListSubheader, Popper, TextField, Typography} from "@mui/material";
import {VariableSizeList} from "react-window";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {createContext, forwardRef, useContext, useEffect, useRef} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";

const LISTBOX_PADDING = 8;

const renderRow = (props) => {
  const {data, index, style} = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  // TODO: Оставить только {dataSet[1]} и разобраться с getOptionLabel
  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]?.name}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const {children, ...other} = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const VirtualizedAutocomplete = ({
                                   name,
                                   label,
                                   options,
                                   required,
                                   loading,
                                   rules,
                                   InputLabelProps,
                                   inputProps,
                                   sx,
                                   value: customValue,
                                   getOptionLabel = (option) => option,
                                   isOptionEqualToValue = (option, value) => option === value,
                                   ...props
                                 }) => {
  const {t} = useTranslation();

  const {
    control,
  } = useFormContext()

  if (required) {
    rules = {required: t('main.another.requiredField'), ...rules}
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({field: {onChange, value, ...field}, fieldState: {error}}) => {
        return (
          <Autocomplete
            {...field}
            options={options || []}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            value={customValue ? customValue : (value || null)}
            onChange={(e, value) => onChange(value)}
            noOptionsText='Нет доступных вариантов'
            sx={{
              mb: 4,
              ...sx,
            }}
            disableListWrap
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            {...props}
            renderInput={params => (
              <TextField
                {...params}
                label={`${label}${required || !!rules?.required ? ' *' : ''}`}
                error={!!error}
                helperText={error && (error?.message || 'Unknown error!')}
                FormHelperTextProps={{
                  sx: {
                    position: 'absolute',
                    bottom: '-20px',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  ...InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20}/> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                fullWidth
                size='small'
                variant="outlined"
                {...inputProps}
              />
            )}
            renderOption={(props, option, state) => [props, option, state.index]}
          />
        )
      }
      }
    />
  )
}

export default VirtualizedAutocomplete;