import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: [],
  plan: {},
};

const PlanningSlice = createSlice({
  name: 'planning',
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setPlans(state, { payload }) {
      state.plans = payload;
    },
    setPlan(state, { payload }) {
      state.plan = payload;
    },
  },
});

export const { actions: planningActions, reducer: planningReducer } =
  PlanningSlice;
export default PlanningSlice.reducer;
