import React, {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import BlockFormModal from '../BlockFormModal'

const BaseAddEditForm = ({
                           children,
                           setLoading,
                           selectedRow,
                           action,
                           resetValue,
                           UpdateAction,
                           AddAction,
                           ViewAction,
                           setConfirmation
                         }) => {
  // const {
  //   resetSandboxFieldForm,
  // } = useActions()

  const methods = useForm({
    mode: 'onBlur',
  })
  const {
    handleSubmit,
    reset,
    getValues,
    formState: {isDirty, isSubmitSuccessful},
  } = methods

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      switch (action) {
        case 'Update':
          await UpdateAction(data)
          break
        case 'Add':
          await AddAction(data)
          break
        case 'View':
          await ViewAction(data)
          break
        default:
          break
      }
    } catch {
    } finally {
    }
  }

  function resetSandboxFieldForm() {
    reset(resetValue)
  }

  setConfirmation(isDirty && !isSubmitSuccessful)

  const handleClearFields = () => {
    const fieldValues = getValues()
    const fieldsToClear = Object.keys(fieldValues).reduce((acc, fieldName) => {
      // if (Array.isArray(fieldValues[fieldName])) {
      //   acc[fieldName] = []
      // } else {
      acc[fieldName] = ''
      // }
      return acc
    }, {})
    if (selectedRow) {
      reset({...selectedRow, full_name: selectedRow?.person?.full_name}) // устанавливаем значения полей из выбранной строки
    } else {
      reset(fieldsToClear) // очистка полей формы согласно имеющимся полям
    }
  }

  useEffect(() => {
    handleClearFields()
  }, [selectedRow])

  useEffect(() => {
    if (action === 'Add') {
      resetSandboxFieldForm()
    }
  }, [action])

  return (
    <>
      <FormProvider {...methods} handleClearFields={handleClearFields}>
        <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>
    </>
  )
}

export default BaseAddEditForm
