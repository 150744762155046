import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useForm } from 'react-hook-form';
import { $authHost } from '../../http';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { CURRENCIES } from '../../constants';
import { useActions } from '../../hook/useActions';
import SkeletonInput from '../SkeletonInput';
import BlockFormModal from '../BlockFormModal';

const BudgetsEdit = ({ handleClose, loadDataBudgets, budgetId }) => {
  const [loading, setLoading] = useState(false);
  const [currencyAdornmentLabel, setCurrencyAdornmentLabel] = useState('$');

  const { setBudget } = useActions();

  const { budget } = useSelector((state) => state.budgetsReducer);
  const { project } = useSelector((state) => state.projectsReducer);

  const loadBudget = useCallback(() => {
    setLoading(true);
    try {
      const getBudget = async () => {
        const response = await $authHost.get(`/budgets/${budgetId}/`);
        setBudget(response.data);
        setLoading(false);
      };
      getBudget();
    } catch (e) {}
  }, []);

  useEffect(() => {
    budgetId && loadBudget();
  }, [budgetId]);

  useEffect(() => {
    if (project?.currency) {
      const currency = CURRENCIES.find((v) => v.value === project?.currency);
      currency && setCurrencyAdornmentLabel(currency.label);
    }
  }, [project]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return budget;
    }, [budget]),
  });

  const {
    register,
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
  } = methods;

  useEffect(() => {
    reset(budget);
  }, [budget]);

  const onSubmit = async (data) => {
    setLoading(true);

    const { currency_amount, date, hours_amount, justification, name, type } =
      data;

    const newData = {
      currency_amount,
      hours_amount,
      justification,
      type,
      // name,
      // date: dayjs(date).format('YYYY-MM-DDTHH:mm:ss'),
    };

    try {
      const response = await $authHost.put(
        `/budgets/${budget?.id}/update/`,
        newData,
      );
      setLoading(false);
      handleClose();
      loadDataBudgets();
    } catch (e) {
      setLoading(false);
    }
  };

  const typesList = ['Внутренний', 'Коммерческий', 'Риски', 'НЗ'];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card sx={{ p: 3, mb: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              alignItems='flex-start'
            >
              <SkeletonInput loading={loading}>
                <Controller
                  name='date'
                  control={control}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Дата'
                        control={control}
                        value={dayjs(value)}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            size: 'small',
                          },
                        }}
                        sx={{
                          width: '100%',
                          mb: 2,
                        }}
                        disabled
                      />
                    </LocalizationProvider>
                  )}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Имя бюджета'
                  {...register('name', {
                    // required: 'Обязательное поле',
                  })}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  disabled
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                  name='type'
                  render={({
                    field: { onChange, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        options={typesList}
                        getOptionLabel={(option) => option}
                        size='small'
                        sx={{
                          mb: 2,
                          width: '100%',
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Вид'
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        onChange={(e, value) => onChange(value)}
                      />
                    );
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading} height={150}>
                <TextField
                  label='Обоснование'
                  {...register('justification')}
                  error={!!errors?.notes}
                  helperText={errors?.notes?.message}
                  fullWidth
                  rows={4}
                  multiline
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Сумма в валюте'
                  {...register('currency_amount', {
                    // required: 'Обязательное поле',
                    pattern: {
                      value: /^\d*$/,
                      message: 'Некорректный ввод',
                    },
                  })}
                  error={!!errors?.currency_amount}
                  helperText={errors?.currency_amount?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        {currencyAdornmentLabel}
                      </InputAdornment>
                    ),
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Сумма в коммерческих часах'
                  {...register('hours_amount', {
                    // required: 'Обязательное поле',
                    pattern: {
                      value: /^\d*$/,
                      message: 'Некорректный ввод',
                    },
                  })}
                  error={!!errors?.hours_amount}
                  helperText={errors?.hours_amount?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>h</InputAdornment>
                    ),
                  }}
                />
              </SkeletonInput>
            </Grid>
            <Grid item sx={{ width: '100%', mb: 2 }}>
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <Button
                    variant='contained'
                    type='submit'
                    sx={{ mr: 2 }}
                    disabled={loading}
                  >
                    Сохранить
                  </Button>
                  <Button variant='text' onClick={handleClose}>
                    Закрыть
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    onClick={() => reset()}
                    disabled={loading}
                  >
                    Сбросить
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </form>
        </Card>
      </Grid>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Grid>
  );
};

export default BudgetsEdit;
