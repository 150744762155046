import {useTranslation} from 'react-i18next';

export const darkLogo = '/assets/icons/navbar/main-white-logo.png';
export const lightLogo = '/assets/icons/navbar/main-blue-logo.png';


export const desiredLocations = [
  // искомые локации
  '/primary-registration',
  '/new-eula',
  '/reset-password',
  '/change-password',
];
export const acceptFileType = ['image/jpeg', 'image/png'];
export const acceptFileTypeXL = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
export const APP_BAR_MOBILE = 64;
export const HEADER_MOBILE = 64;
export const APP_BAR_DESKTOP = 72;
export const HEADER_DESKTOP = 72;
export const NAV_WIDTH = 250;
export const NAV_WIDTH_CLOSE = 60;
export const currencies2 = ['USD', 'EUR', 'BTC', 'JPY', 'KZT'];

export const CURRENCIES = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

export const MONTHES = [
  {value: 1, name: 'Январь', quarter: 1, days: 31},
  {value: 2, name: 'Февраль', quarter: 1, days: 28},
  {value: 3, name: 'Март', quarter: 1, days: 31},
  {value: 4, name: 'Апрель', quarter: 2, days: 30},
  {value: 5, name: 'Май', quarter: 2, days: 31},
  {value: 6, name: 'Июнь', quarter: 2, days: 30},
  {value: 7, name: 'Июль', quarter: 3, days: 31},
  {value: 8, name: 'Август', quarter: 3, days: 31},
  {value: 9, name: 'Сентябрь', quarter: 3, days: 30},
  {value: 10, name: 'Октябрь', quarter: 4, days: 31},
  {value: 11, name: 'Ноябрь', quarter: 4, days: 30},
  {value: 12, name: 'Декабрь', quarter: 4, days: 31},
];

// export const TIMESHEET_STATUSES = [
//   {value: 'Я', color: '#bdd7ee', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
//   {value: 'ЯП', color: '#bdd7ee', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
//   {value: 'ЯН', color: '#bdd7ee', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
//   {value: 'В', color: '#e2efdb', editing: true, options: ['В', 'РП']},
//   {value: 'ОТ', color: '#a9d090', editing: false},
//   {value: 'ОП', color: '#a9d090', editing: false},
//   {value: 'БЛ', color: '#cfbce9', editing: false},
//   {value: 'РП', color: '#d9d9d9', editing: true, options: ['В', 'РП']},
//   {value: 'С', color: '#cfbce9', editing: false},
//   {
//     value: 'НО',
//     color: '#d9d9d9', editing: false
//   },
//   {value: 'У', color: '#d9d9d9', editing: false},
//   {value: 'ПР', color: '#ff8584', editing: true, options: ['Я', 'ЯП', 'ЯН', 'ПР']},
// ];

export const TIMESHEET_STATUSES = [

  {
    value: 'Я',
    color: 'rgba(53,130,0,0.12)',
    colorSvg: '233582001f',
    editing: true,
    options: ['Я', 'ЯП', 'ЯН', 'ПР'],
    description: 'Явка, полный рабочий день'
  },
  {
    value: 'ЯП',
    color: 'rgba(53,130,0,0.12)',
    colorSvg: '233582001f',
    editing: true,
    options: ['Я', 'ЯП', 'ЯН', 'ПР'],
    description: 'Явка с перерывом, полный рабочий день '
  },
  {
    value: 'ЯН',
    color: 'rgb(217,225,255)',
    colorSvg: '23D9E1E1',
    editing: true,
    options: ['Я', 'ЯП', 'ЯН', 'ПР'],
    description: 'Явка-неявка. Отгул за счет переработок в рабочие дни'
  },
  {
    value: 'ОП',
    color: '#d9e1ff',
    colorSvg: '23d9e1ff',
    editing: false,
    description: 'Отгул за счет переработок'
  },
  {
    value: 'ОТ',
    color: '#b6b4ff',
    colorSvg: '23b6b4ff',
    editing: false,
    description: 'Отпуск основной'
  },
  {
    value: 'БЛ',
    color: 'rgba(159,65,233,0.5)',
    colorSvg: '239f41e980',
    editing: false,
    description: 'Больничный лист '
  },
  {
    value: 'В',
    color: 'rgba(253,232,147,0.37)',
    colorSvg: '23fde8935e',
    editing: true,
    options: ['В', 'РП'],
    description: 'Выходные и праздничные дни по трудовому календарю'
  },
  {
    value: 'РП',
    color: 'rgba(255,183,154,0.76)',
    colorSvg: '23ffb79ac2',
    editing: true,
    options: ['В', 'РП'],
    description: 'Рабочие праздники и рабочие выходные'
  },
  {
    value: 'С',
    color: 'rgba(255,183,154,0.76)',
    colorSvg: '23ffb79ac2',
    editing: false,
    description: 'Рабочий день + сверхурочная работа'
  },
  {
    value: 'НО',
    color: '#e8e8e8',
    colorSvg: '23e8e8e8',
    editing: false,
    description: 'Административный отпуск неоплачиваемый (отпуск без сохранения заработной платы)'
  },
  {
    value: 'У',
    color: '#ffffff',
    colorSvg: '23ffffff',
    editing: false,
    description: 'Уволен'
  },
  {
    value: 'ПР',
    color: '#ffb0b0',
    colorSvg: '23ffb0b0',
    editing: true,
    options: ['Я', 'ЯП', 'ЯН', 'ПР'],
    description: 'Прогул без уважительной причины'
  },
];
