import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  project: {},
  selectedProject: null,
};

const ProjectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setProjects(state, { payload }) {
      state.projects = payload;
    },
    setProject(state, { payload }) {
      state.project = payload;
    },
    setSelectedProject(state, { payload }) {
      const index = state.projects.findIndex(
        (project) => project.id === payload,
      );
      state.selectedProject = state.projects[index];
    },
  },
});

export const { actions: projectsActions, reducer: projectsReducer } =
  ProjectsSlice;
export default ProjectsSlice.reducer;
