import { useState } from 'react';
import { TextField } from '@mui/material';
import { SearchInput, StyledLink } from '../../theme/standarts_styles';
import { getRoutes } from '../../routes';

const SearchFieldInAppBar = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const routes = getRoutes();
  const searchList = [];

  const forEachArr = (arr) => {
    arr.forEach((item) => {
      if (item.searchText) {
        searchList.push(item);
      }
      if (Array.isArray(item.children)) {
        forEachArr(item.children);
      }
    });
  };

  forEachArr(routes);

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SearchInput
          disablePortal
          value={autocompleteValue}
          options={searchList}
          size='small'
          sx={{
            width: `${props.width}`
          }}
          blurOnSelect={true}
          forcePopupIcon={false}
          isOptionEqualToValue={(option, value) => option.searchText === value}
          getOptionLabel={(option) => option.searchText}
          noOptionsText='Нет доступных вариантов'
          onChange={(event, newValue) => {
            setAutocompleteValue(null);
            setInputValue('');
          }}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          inputValue={inputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search..."
              InputProps={{
                sx: {
                  color: 'text.primary',
                },
                ...params.InputProps,
              }}
              type='search'
            />
          )}
          renderOption={(props, option) => (
            <li {...props} >
              <StyledLink to={`/${option.path}`} sx={{zIndex: '50000 !important'}}>
                {option.searchText}
              </StyledLink>
            </li>
          )}
        />
      </div>
    </>
  );
};

export default SearchFieldInAppBar;
