import React, { useEffect } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'
import { Grid } from '@mui/material'

const CustomDateRangePicker = ({ startName, endName, startLabel, endLabel, rules, disablePast = false, ...rest }) => {
	const {
		control,
		formState: { errors },
		getValues,
		setValue,
	} = useFormContext()

	const startError = errors[startName]
	const endError = errors[endName]
	const req = !!rules?.required

	const containerStyle = {
		position: 'relative',
		marginBottom: '12px',
		width: '100%',
	}

	const spanStyle = {
		position: 'absolute',
		bottom: '4px',
		fontSize: '12px',
		color: 'red',
		left: 14,
		width: '100%',
	}
	const dividerStyle = {
		fontSize: '1.5em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '24px',
	}
	return (
		<Grid container style={containerStyle}>
			<Grid item xs={5.9}>
				<Controller
					control={control}
					name={startName}
					rules={rules}
					render={({ field: { onChange, value } }) => (
						<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
								label={`${startLabel}${req ? ' *' : ''}`}
								control={control}
								disablePast={disablePast}
								value={getValues(startName) ? dayjs(getValues(startName)) : null}
								onChange={(newValue) => {
									setValue(endName, null, { shouldDirty: true })
									setValue(startName, newValue, { shouldDirty: true })
								}}
								format="DD.MM.YYYY"
								{...rest}
								slotProps={{
									textField: {
										InputLabelProps: { shrink: true },
										size: rest.size || 'medium',
										error: !!endError || !!startError,
									},
								}}
							/>
						</LocalizationProvider>
					)}
				/>
			</Grid>

			<Grid item xs={0.2} style={dividerStyle}>
				<div>-</div>
			</Grid>

			<Grid item xs={5.9}>
				<Controller
					control={control}
					name={endName}
					rules={{
						...rules,
						validate: (value) => {
							const startDate = getValues(startName);
							const endDate = value;
							return startDate && endDate && startDate <= endDate || 'Дата окончания не может быть раньше даты начала';
						},
					}}
					render={({ field: { onChange, value } }) => (
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								label={`${endLabel}${req ? ' *' : ''}`}
								control={control}
								disablePast={disablePast}
								minDate={!rest.disabled ? getValues(startName) : null}
								value={getValues(endName) ? dayjs(getValues(endName)) : null}
								onChange={(newValue) => {
									setValue(endName, newValue, { shouldDirty: true })
								}}
								format="DD.MM.YYYY"
								{...rest}
								slotProps={{
									textField: {
										InputLabelProps: { shrink: true },
										size: rest.size || 'medium',
										error: !!endError || !!startError,
									},
								}}
							/>
						</LocalizationProvider>
					)}
				/>
			</Grid>
			<Grid item xs={12}>
        <span style={spanStyle}>
          {(!!endError || !!startError) && (startError ? startError.message : endError ? endError.message : 'Unknown error!')}
        </span>
			</Grid>
		</Grid>
	)
}

export default CustomDateRangePicker
