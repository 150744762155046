import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: [],
};

const ScheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setEvents(state, { payload }) {
      state.events = payload;
    },
  },
});

export const { actions: scheduleActions, reducer: scheduleReducer } =
  ScheduleSlice;
export default ScheduleSlice.reducer;
