import Router, { getRoutes } from './routes';
import ThemeProvider from './theme';
import { Suspense } from 'react';
import LoadingSpinner from './components/modals/loadingSpinner';
import { AuthProvider } from './hoc/authProvider';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter(getRoutes());

const App = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <ThemeProvider>
      {/*<AuthProvider>*/}
        <RouterProvider router={router} />
      {/*</AuthProvider>*/}
    </ThemeProvider>
  </Suspense>
);
export default App;
