import React, { useState } from "react";
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm, useFormContext } from "react-hook-form";
import BlockFormModal from "../BlockFormModal";
import ConfirmDialog from "../modals/ConfirmDialog";

const AddEditFormButtonGroup = ({ action, handleClose, confirmation }) => {

  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    handleClearFields,
  } = useFormContext()

  return (
    <>
      <Grid item sx={{ width: '100%', mb: 2 }}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item>
            <Button variant="contained" type="submit">
              {action === 'Add'
                ? t('src.components.Intelligence.add')
                : t('main.another.save')}
            </Button>
            <Button
                variant="text"
                color="primary"
                sx={{ ml: 1 }}
                onClick={()=>{
                  !confirmation ? handleClose() : setDialogOpen(true)
                }}
            >
              {t('src.components.Intelligence.close')}
            </Button>
          </Grid>
          <Grid item>
            <Button
                variant="text"
                color="primary"
                sx={{ ml: 1 }}
                onClick={handleClearFields}
            >
              {action === 'Add'
                  ? t('src.components.Intelligence.clear')
                  : t('src.components.Intelligence.reset')
              }
            </Button>
          </Grid>
        </Stack>
        <ConfirmDialog
          isActive={dialogOpen}
          setDialogOpen={setDialogOpen}
          onConf={handleClose}
        />
      </Grid>
    </>
  )
}

export default AddEditFormButtonGroup