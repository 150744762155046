import {createSlice} from "@reduxjs/toolkit";


const initialState = {
  contacts: [],
  selectedContact: null,
  amountClientContact: null
};

const ContactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    logOut() {
      return initialState
    },
    setContacts(state, {payload}) {
      state.contacts = payload;
    },
    setClientsContacts(state, {payload}) {
      state.amountClientContact = payload
    },
    setSelectedContact(state, {payload}) {
      const index = state.contacts.findIndex(
        (project) => project.id === payload,
      );
      state.selectedContact = state.contacts[index];
    },


  },
});

export const {actions: contactsActions, reducer: contactsReducer} = ContactsSlice
export default ContactsSlice.reducer;
