import React, {useCallback, useEffect, useState} from 'react';
import {useActions} from '../hook/useActions';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TableCellsWrap} from './TableCellsWrap';
import MaterialTable from './MaterialTable';
import {Button, Card, Grid, IconButton, Stack, Tooltip} from '@mui/material';
import LoadingSpinner from './modals/loadingSpinner';
import {ButtonStack} from '../theme/standarts_styles';
import BaseAddEditForm from './forms/BaseAddEditForm';
import AddEditFormButtonGroup from './forms/AddEditFormButtonGroup';
import Iconify from './iconify';
import {$authHost} from '../http';
import ContactsForm from './forms/ContactsForm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CustomViewField from './CustomViewField';
import {useParams} from 'react-router-dom';
import BlockFormModal from "./BlockFormModal";
import MaterialTablePagination from "./MaterialTablePagination";

const Contacts = () => {
  const tableId = 'contacts';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const {t} = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState('');
  const [confirmationId, setConfirmationId] = useState(null);
  const [confirmation, setConfirmation] = useState(false);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);


  const {setContacts, setSelectedContact} = useActions();
  const {updateFullSelectedClient} = useActions();
  const {setClientsContacts} = useActions();

  const {
    contacts,
    selectedContact
  } = useSelector((state) => state.contactsReducer);

  const {
    selectedClient
  } = useSelector((state) => state.clientsReducer);

  const {clientId} = useParams();

  useEffect(() => {
    const loadClientData = () => {
      try {
        const newselectedClient = async () => {
          const response = await $authHost.get(`/clients/${clientId}/`);
          return response.data;
        };
        newselectedClient().then((data) => {
          updateFullSelectedClient(data);
        });
      } catch (e) {
      }
    };
    loadClientData();
  }, []);

  const tableColumns = [
    {
      accessorKey: 'person',
      header: 'ФИО',
      accessorFn: (row) => row.person?.full_name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'position',
      header: 'Должность',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'phone',
      header: 'Телефон',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'email',
      header: 'Email',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {
        },
        sx: {
          cursor: 'auto',
        },
      },
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Редактировать'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handleGoToEditPage(row.original)}
              >
                <EditIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title='Удалить'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setConfirmationId(row.original.id)}
              >
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  function handleGoToEditPage(row) {
    setSelectedRow(row);
    setSelectedContact(row.id);
    setOpenForm('Update');
  }

  async function handleDeleteRow(row) {
    try {
      await $authHost.delete(`/contacts/${row}/delete/`).then((response) => {
        loadData();
        setConfirmationId(null);
      });
    } catch (e) {
    }
  }

  const handleAdd = () => {
    setOpenForm((prevState) => {
      return prevState === 'Add' ? '' : 'Add';
    });
    setSelectedRow(null);
  };

  const handleOpenViewPage = (row) => {
    setSelectedRow(row.original);
    setSelectedContact(row.original.id);

    openForm !== 'View' && setOpenForm('View');
  };

  const UpdateAction = async (data) => {
    try {
      const updatePerson = async () => {
        await $authHost.put(`/persons/${selectedContact.person_id}/update/`, {
          full_name: data.full_name,
        });
      };
      const updateContact = async () => {
        let contact_data = data;
        contact_data['person_id'] = selectedContact.person_id;
        contact_data['client_id'] = selectedClient.source_id;
        delete contact_data.full_name;
        return await $authHost.put(
          `/contacts/${selectedContact.source_id}/update/`,
          contact_data,
        );
      };
      updatePerson().then(() => {
        updateContact().then((data) => {
          //console.log(data);
          setOpenForm('');
          loadData();
        });
      });
    } catch (e) {
      setLoading(false);

    } finally {
    }
  };

  const AddAction = async (data) => {
    try {
      const newPerson = async () => {
        const response = await $authHost.post(`/persons/add/`, {
          full_name: data.full_name,
        });
        return response.data.source_id;
      };
      const newContact = async (person_id) => {
        let contact_data = data;
        contact_data['person_id'] = person_id;
        contact_data['client_id'] = selectedClient.source_id;
        delete contact_data.full_name;
        const response = await $authHost.post(`/contacts/add/`, contact_data);
        return response.data;
      };
      newPerson().then((data) => {
        newContact(data).then((data) => {
          // console.log(data);
          setOpenForm('');
          loadData();
        });
      });
    } catch (e) {
      setLoading(false);
    } finally {
    }
  };

  const loadData = async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/contacts/all/?client_id=${selectedClient?.source_id}&search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
      );
      const data = response.data.items;
      setContacts(data);
      setClientsContacts(response.data?.total)
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  useEffect(() => {
    if (!openForm && selectedContact) {
      setSelectedContact(null);
      // resetSandboxFieldForm()
    }
  }, [openForm]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  return (
    <>
      {loading && <LoadingSpinner/>}
      <>
        <ButtonStack>
          <Button
            onClick={handleAdd}
            aria-haspopup='true'
            variant='contained'
            disabled={!selectedClient}
            startIcon={<Iconify icon='eva:plus-fill'/>}
            sx={{mr: 0}}
          >
            Добавить контактное лицо
          </Button>
        </ButtonStack>
        {/*<AlertMessage/>*/}
        {openForm && openForm !== 'View' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card sx={{p: 3, mb: 2}}>
                <BaseAddEditForm
                  setLoading={setLoading}
                  selectedRow={selectedRow}
                  action={openForm}
                  resetValue={{
                    type: '',
                    position: '',
                    phone: '',
                    email: '',
                  }}
                  AddAction={AddAction}
                  UpdateAction={UpdateAction}
                  setConfirmation={setConfirmation}
                >
                  <ContactsForm/>
                  <AddEditFormButtonGroup
                    action={openForm}
                    confirmation={confirmation}
                    handleClose={() => setOpenForm('')}
                  />
                </BaseAddEditForm>
              </Card>
            </Grid>
          </Grid>
        )}
        {openForm === 'View' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card sx={{p: 3, mb: 2}}>
                <CustomViewField label='ФИО' text={selectedRow?.full_name}/>
                <CustomViewField
                  label='Должность'
                  text={selectedRow?.position}
                />
                <CustomViewField label='Телефон' text={selectedRow?.phone}/>
                <CustomViewField label='Email' text={selectedRow?.email}/>
                <CustomViewField
                  label='Комментарий'
                  text={selectedRow?.notes}
                />
                <CustomViewField
                  label='Дополнительноя коммуникация'
                  text={selectedRow?.additional_communication}
                />
                <Grid item sx={{width: '100%', mb: 2}}>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Button
                      variant='contained'
                      onClick={() => setOpenForm('Update')}
                    >
                      Редактировать
                    </Button>
                    <Button
                      variant='text'
                      color='primary'
                      sx={{ml: 1}}
                      onClick={() => setOpenForm('')}
                    >
                      Закрыть
                    </Button>
                  </Stack>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
        <Card sx={{pt: 1}}>
          <MaterialTablePagination
            id={tableId}
            data={contacts}
            columns={tableColumns}
            loading={loading}
            setPagination={setPagination}
            pagination={pagination}
            rowCount={rowCount}
            pageCount={pageCount}
            onGlobalFilterChange={onGlobalFilterChange}
            search={searchText}
            muiTableBodyCellProps={({row}) => ({
              onClick: () => {
                handleOpenViewPage(row);
              },
              sx: {
                cursor: 'pointer',
              },
            })}
          />
        </Card>
        <BlockFormModal when={!!confirmationId} title='Вы не завершили действие внутри таблицы'/>
      </>
    </>
  );
};

export default Contacts;
