import {useTranslation} from 'react-i18next';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import {
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Iconify from '../components/iconify';
import {TableCellsWrap} from '../components/TableCellsWrap';
import {useActions} from '../hook/useActions';
import {useSelector} from 'react-redux';
import {$authHost} from '../http';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import dayjs from 'dayjs';
import BlockFormModal from '../components/BlockFormModal';
import MaterialTablePagination from "../components/MaterialTablePagination";

const Projects = () => {
  const tableId = 'projects';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const {setProjects, setSelectedProject} = useActions();
  const {projects} = useSelector((state) => state.projectsReducer);
  const {roles} = useSelector(state => state.authReducer)
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      id: 'code',
      header: 'Код проекта',
      accessorFn: (row) => row.code || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'name',
      header: 'Название проекта',
      accessorFn: (row) => row.name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'client_name',
      header: 'Клиент',
      accessorFn: (row) => row.client?.name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'client_code',
      header: 'Код клиента',
      accessorFn: (row) => row.client?.code || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'notes',
      header: 'Описание проекта',
      accessorFn: (row) => row.notes || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'status',
      header: 'Статус',
      accessorFn: (row) => row.status || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'manager_display_name',
      header: 'Менеджер проекта',
      accessorFn: (row) => row.manager?.display_name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'team',
      header: 'Команда проекта',
      accessorFn: (row) => {
        if (Array.isArray(row?.team)) {
          const team = row.team.map((t) => t.display_name);
          return team.join(', ');
        }
        return '';
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'start_date',
      header: 'Начало (план)',
      accessorFn: (row) => row.start_date ? dayjs(row.start_date).format('DD.MM.YYYY') : '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'end_date',
      header: 'Конец (план)',
      accessorFn: (row) => row.end_date ? dayjs(row.end_date).format('DD.MM.YYYY') : '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'internal_hours_budget',
      header: 'Бюджет (внутр. часы)',
      accessorFn: (row) => row.internal_hours_budget || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      muiTableBodyCellProps: {
        onClick: (e) => {
        },
        sx: {
          cursor: 'auto',
        },
      },
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Редактировать'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handleEditRow(row.original.id)}
              >
                <EditIcon/>
              </IconButton>
            </Tooltip>
            {roles?.some(role => role === 'ERP_Admins') && (
              <Tooltip title='Удалить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(row.original.id)}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const handleEditRow = (data) => {
    navigate(`/projects/edit/${data}`);
    setSelectedProject(data);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/projects/${id}`);
      setSelectedProject(id);
    }
  };

  function handleGoToAddPage(selectedChannel = null) {
    navigate('/projects/new');
  }

  async function handleDeleteRow(id) {
    setLoading(true);
    try {
      await $authHost.delete(`/projects/${id}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }


  const loadData = async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/projects/all/?search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
      );
      const data = response.data.items;
      setProjects(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  return (
    <Container sx={{pb: 0}}>
      <Stack direction='row' alignItems='center'>
        <Typography variant='h4' gutterBottom sx={{mb: 0, mr: 4}}>
          Проекты
        </Typography>
      </Stack>
      <Stack
        direction='row'
        sx={{height: '70px'}}
        alignItems='center'
        justifyContent='start'
        mb={0}
      >
        <Button
          variant='contained'
          onClick={handleGoToAddPage}
          startIcon={<Iconify icon='eva:plus-fill'/>}
        >
          Добавить проект
        </Button>
      </Stack>
      <Card sx={{pt: 1}}>
        <MaterialTablePagination
          id={tableId}
          data={projects}
          columns={tableColumns}
          loading={loading}
          enableHiding={true}
          initialState={{
            columnVisibility: {client_code: false, notes: false, team: false},
          }}
          setPagination={setPagination}
          pagination={pagination}
          rowCount={rowCount}
          pageCount={pageCount}
          onGlobalFilterChange={onGlobalFilterChange}
          search={searchText}
          muiTableBodyCellProps={({row}) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Card>
      <BlockFormModal
        when={!!confirmationId}
        title='Вы не завершили действие внутри таблицы'
      />
    </Container>
  );
};

export default Projects;
