import Stack from '@mui/material/Stack';
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  IconButton,
  Popover,
  TextField,
} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {TableCellsWrap} from '../../components/TableCellsWrap';
import {$authHost} from '../../http';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
  LocalizationProvider,
  MonthCalendar,
  YearCalendar,
} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import dayjs from 'dayjs';
import Descriptions from './Descriptions';
import {useSelector} from 'react-redux';
import {useActions} from '../../hook/useActions';
import MaterialTablePagination from '../../components/MaterialTablePagination';
import {TIMESHEET_STATUSES} from "../../constants";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import {socket} from "../../utils/socket";
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from "@mui/lab";

const TimesheetMonth = () => {
  const tableId = 'timesheetMonth';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const [editingCell, setEditingCell] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [updateStatus, setUpdateStatus] = useState('started');
  const [tableData, setTableData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const {timesheetsMonth} = useSelector((state) => state.timesheetsReducer);

  const {setTimesheetsMonth} = useActions();

  const onUnload = () => {
    socket.removeAllListeners();
    socket.disconnect();
    console.log('onUnload');
  }

  const onConnect = () => {
    console.log('Connect');
  }

  const onDisconnect = () => {
    console.log('Disconnect');
  }

  const onUpdateStatus = (data) => {
    console.log('onUpdateStatus', data);
    setUpdateStatus(data?.status);

    if (data?.status === 'started') {

    } else if (data?.status === 'finished') {
      loadData();
    }
  }

  useEffect(() => {
    socket.connect();

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('update_report_cards', onUpdateStatus);

    window.addEventListener("beforeunload", onUnload);
    return () => {
      onUnload();
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const response = await $authHost.get(`/report_cards/service/status`);
        setUpdateStatus(response.data?.status);
      } catch (e) {
      }
    }

    getStatus();
  }, [])

  const days = useMemo(() => {
    const daysInMonth = dayjs(month).daysInMonth();
    const daysArr = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArr.push(i);
    }

    return daysArr;
  }, [month]);

  const loadData = async () => {
    setLoading(true);

    const selectedMonth = dayjs(month).month() + 1;
    const selectedYear = dayjs(year).year();
    try {
      const response = await $authHost.get(
        `/report_cards/all/?month=${selectedMonth}&year=${selectedYear}&page=${
          pagination.pageIndex + 1
        }&size=${pagination.pageSize}&search=${searchText}`,
      );
      const data = response.data.items;
      setTimesheetsMonth(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [month, year, pagination.pageSize, pagination.pageIndex, searchText]);

  useEffect(() => {
    const newTableData = [];
    timesheetsMonth.forEach((item) => {
      const edited = editedRows.find((row) => row?.id === item?.id);
      if (edited) {
        newTableData.push(edited);
      } else {
        newTableData.push(item);
      }
    })

    setTableData(newTableData);

    if (editedRows.length > 0) {
      setButtonsDisabled(false);
    } else {
      setButtonsDisabled(true);
    }
  }, [editedRows, timesheetsMonth]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  const tableColumns = [
    {
      accessorKey: 'user',
      header: 'Сотрудник',
      accessorFn: (row) => row.user?.display_name || '',
      enableEditing: false,
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'user_department_name',
      header: 'Отдел',
      accessorFn: (row) => row.user?.department?.name || '',
      enableEditing: false,
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    ...days.map((d) => ({
      accessorKey: `calendar.${d.toString()}`,
      header: d.toString(),
      size: 1,
      enableEditing: updateStatus !== 'started',
      Edit: ({cell, column, table}) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find(
          (status) => status.value === value,
        );

        return (
          status ?
            <Autocomplete
              value={status?.value}
              options={status?.options}
              getOptionLabel={(option) => option}
              disableClearable
              size='small'
              renderInput={(params) => (
                <TextField {...params} variant='standard' autoFocus/>
              )}
              onBlur={(e) => {
                handleBlur(cell, e.target.value);
                setEditingCell(null);
              }}
              // renderOption={(props, option, ownerState) => (
              //   <li {...props} style={{ backgroundColor: option.color }}>
              //     {option.value}
              //   </li>
              // )}
            /> :
            <Autocomplete
              value={value}
              options={TIMESHEET_STATUSES}
              getOptionLabel={(option) => option.value}
              disableClearable
              size='small'
              renderInput={(params) => (
                <TextField {...params} variant='standard' autoFocus/>
              )}
              onBlur={(e) => {
                handleBlur(cell, e.target.value);
                setEditingCell(null);
              }}
            />
        );
      },
      muiTableBodyCellProps: ({cell, table}) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find((status) => status.value === value);
        if ((status && !status?.editing) || !status) {
          return {
            sx: {
              backgroundColor: `${status?.color} !important`,
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              cursor: 'default',
            },
          };
        } else {
          return {
            sx: {
              backgroundColor: `${status?.color} !important`,
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
            onDoubleClick: () => {
              setEditingCell(cell);
            },
          };
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    })),
  ];

  const handleBlur = (cell, value) => {
    const editedRow = cloneDeep(tableData[cell.row.index]);
    editedRow.calendar[cell.column.columnDef.header] = value;
    const originalRow = timesheetsMonth[cell.row.index];
    const isSame = isEqual(editedRow, originalRow);
    const newEditedRows = [...editedRows];

    if (isSame) {
      const index = editedRows.findIndex((row) => row?.id === editedRow?.id);
      if (index > -1) {
        newEditedRows.splice(index, 1);
      }
    }

    if (!isSame) {
      const index = editedRows.findIndex((row) => row?.id === editedRow?.id);
      if (index > -1) {
        newEditedRows.splice(index, 1, editedRow);
      }
      if (index === -1) {
        newEditedRows.push(editedRow);
      }
    }

    setEditedRows(newEditedRows);
  };

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, 'month');

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, 'month');

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, 'year');
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const requests = editedRows.map((row) => {
        if (row.id === '65e2260a1362cc66224fe03d') {
          return $authHost.put(`/report_cards/${row.id}/updated/`, row);
        }
        return $authHost.put(`/report_cards/${row.id}/update/`, row);
      })

      const response = await Promise.allSettled([...requests]);
      console.log(response);
      const unsavedRows = [];
      response.forEach((r, index) => {
        if (r.status === 'rejected') {
          unsavedRows.push(editedRows[index]);
        }
      })
      setEditedRows(unsavedRows);
      loadData();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setEditedRows([]);
  };

  const handleUpdate = () => {
    try {
      $authHost.put(`/report_cards/update/all`);
    } catch (e) {
    }
  };

  return (
    <>
      <Card sx={{my: 2}}>
        <Descriptions/>
      </Card>
      <Card sx={{pb: 2, my: 2}}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack sx={{p: 2}} direction='row' alignItems='center'>
            <IconButton onClick={handlePrev}>
              <ArrowCircleLeftIcon fontSize='large'/>
            </IconButton>
            <ButtonGroup variant='outlined'>
              <Button
                onClick={handleClickMonth}
                sx={{minWidth: '110px !important'}}
              >
                {dayjs(month).format('MMMM')}
              </Button>
              <Button
                onClick={handleClickYear}
                sx={{minWidth: '70px !important'}}
              >
                {dayjs(year).format('YYYY')}
              </Button>
            </ButtonGroup>
            <IconButton onClick={handleNext}>
              <ArrowCircleRightIcon fontSize='large'/>
            </IconButton>
            <Popover
              open={!!anchorMonth}
              anchorEl={anchorMonth}
              onClose={handleCloseMonth}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MonthCalendar onChange={(v) => setMonth(v)} value={month}/>
              </LocalizationProvider>
            </Popover>
            <Popover
              open={!!anchorYear}
              anchorEl={anchorYear}
              onClose={handleCloseYear}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <YearCalendar onChange={(v) => setYear(v)} value={year}/>
              </LocalizationProvider>
            </Popover>
          </Stack>
          <LoadingButton variant='contained' sx={{mr: 2}} onClick={handleUpdate}
                         loading={updateStatus === 'started'}
                         loadingPosition="start"
                         startIcon={<RefreshIcon/>}
          >
            {updateStatus === 'started' ? 'Идет процесс обновления, внесенные изменения могут быть утеряны' : 'Обновить'}
          </LoadingButton>
        </Stack>
        <MaterialTablePagination
          id={tableId}
          data={tableData}
          columns={tableColumns}
          editDisplayMode='cell'
          enableSorting={false}
          enableColumnFilters={false}
          enableEditing
          setPagination={setPagination}
          pagination={pagination}
          rowCount={rowCount}
          pageCount={pageCount}
          onGlobalFilterChange={onGlobalFilterChange}
          search={searchText}
          initialState={{
            density: 'compact',
          }}
          state={{
            editingCell: editingCell,
            isLoading: loading,
          }}
          muiTableContainerProps={{
            sx: {
              borderTop: '1px solid rgba(224, 224, 224, 1)',
              borderLeft: '1px solid rgba(224, 224, 224, 1)',
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              // backgroundColor: '#ffffff'
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              borderBottom: '1px solid rgba(224, 224, 224, 1)',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              backgroundColor: '#ffffff'
            },
          }}
        />
        {/*<Descriptions/>*/}
        <Stack direction='row' sx={{px: 2, pt: 2}} spacing={2}>
          <Button variant='contained' disabled={buttonsDisabled || updateStatus === 'started'}
                  onClick={handleSave}>Сохранить</Button>
          <Button variant='text' disabled={buttonsDisabled} onClick={handleCancel}>Отменить</Button>
        </Stack>
      </Card>
    </>

  );
};

export default TimesheetMonth;
