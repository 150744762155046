import Stack from '@mui/material/Stack';
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  TextField, Typography
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { $authHost } from '../../http';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from "react-hook-form";
import BlockFormModal from '../../components/BlockFormModal';
import { useNavigate, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { TitleStack } from "../../theme/standarts_styles";
import CustomViewField from "../../components/CustomViewField";
import dayjs from "dayjs";
import { useActions } from "../../hook/useActions";
import SkeletonInput from "../../components/SkeletonInput";
import CustomTextFieldAutocomplete from "../../components/TextFields/CustomTextFieldAutocomplete";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import AlertMessage from "../../components/Alert/AlertMessage";
import CustomTextField from "../../components/TextFields/CustomTextField";

const EmployeesForm = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { employeeId } = useParams();


  const {
    departments,
    selectedDepartment,
    selectedManagerId
  } = useSelector((state) => state.departmentsReducer);

  const { selectedEmployee } = useSelector((state) => state.employeesReducer);

  const {
    setDataSelectedEmployee,
    setDepartments,
    setSelectedDepartment,
    setErrorAlertMessage
  } = useActions()

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    getValues,
    setValue,
    handleSubmit,
    register,
    control,
    reset,
  } = methods;

  useEffect(() => {
    reset(selectedEmployee);
  }, [selectedEmployee]);

  const handleReset = () => {
    let _select_depart = {};
    _select_depart.name =  selectedEmployee.department?.name;
    _select_depart.manager = selectedEmployee.manager;
    setSelectedDepartment(_select_depart)
    reset(selectedEmployee);
  };




  const onSubmit = async (data) => {

    setLoading(true);
    const newData = {
      occupation: data?.occupation || null,
      department_id: data.department?.id || null,
      manager_id: data.department?.manager_id || null,
    };

    try {
      await $authHost.put(
        `/users/update/${selectedEmployee.source_id}/`,
        newData,
      );
    } catch (e) {
      if (e.response.status === 400 && e.response.data.detail === 'The manager id cannot be the same as your source id') {
        setErrorAlertMessage('Пользователь е может бть менеджером для себя')
      }
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/org-structure/employees');
    }
  }, [isSubmitSuccessful]);

  const loadData = useCallback(() => {
    setLoading(true)
    try {
      const newChannelList = async () => {
        const response = await $authHost.get(`/users/${employeeId}/`);
        return response.data;
      };
      newChannelList().then((data) => {
        setDataSelectedEmployee(data);
      }).then(()=>{
        handleReset();
        setLoading(false)
      });
    } catch (e) {}
  }, []);

  const loadDeparts = useCallback(() => {
    setLoading(true)
    try {
      const newChannelList = async () => {
        const response = await $authHost.get('/departments/all/');
        return response.data.items;
      };
      newChannelList().then((data) => {
        setDepartments(data);
        setLoading(false)
      });
    } catch (e) {}
  }, []);

  const handleBack = () => {
    navigate(`/org-structure/employees/${employeeId}`);
  };

  useEffect(() => {
    loadData();
    loadDeparts();
  }, []);


  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack
        direction='row'
        alignItems='center'
        sx={{ height: '70px' }}
        justifyContent='space-between'
        mb={0}
      >
        <Typography variant='h4' gutterBottom>
          Управление {selectedEmployee?.display_name}
        </Typography>
      </TitleStack>
      <Card sx={{mb: 2}}>
        <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{m:5}}
          >
            <Grid item xs={4}>
              <Avatar
                id='img'
                src={selectedEmployee?.photo}
                alt='photoURL'
                sx={{ width: 250, height: 250, mb: 4 }}
              />
              <Typography
                variant='subtitle1'
                sx={{
                  mb: 3,
                }}
              >
                Орг. структура
              </Typography>

              <SkeletonInput loading={loading} width={'90%'}>
                <Controller
                  control={control}
                  rules={{
                    // required: t('main.another.requiredField'),
                  }}
                  name='department'
                  render={({
                             field: { onChange, ...field },
                             fieldState: { error },
                           }) => {
                    return (
                      <Autocomplete
                        {...field}
                        options={departments}
                        getOptionLabel={(option) => option?.name || '' }
                        size='small'
                        sx={{
                          mb: 2, pr: 5
                        }}
                        renderInput={(params) => (
                          <TextField
                            label='Отдел'
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, value) => {
                          onChange(value);
                          setSelectedDepartment(value)
                        }}
                      />
                    );
                  }}
                />
              </SkeletonInput>

              <SkeletonInput loading={loading} width={'90%'}>
                <CustomViewField
                  label='Менеджер'
                  text={ selectedDepartment?.manager?.display_name}
                  loading={false}
                  sx={{
                    mb: 1,
                  }}
                />
              </SkeletonInput>

              <SkeletonInput loading={loading} width={'90%'}>
                <CustomTextField
                  name="occupation"
                  label="Должность"
                  sx={{ width: '100%', fontSize: '12px',
                    mb: 2,
                    pr: 5
                  }}
                  size="small"
                />
              </SkeletonInput>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: 2,
                }}
              >
                Общие сведения
              </Typography>
              <CustomViewField
                label='Дата рождения'
                text={dayjs(selectedEmployee?.birth_date).format('DD.MM.YYYY')}
                loading={false}
              />
              <CustomViewField
                label='Email'
                text={selectedEmployee?.email}
                loading={false}
                copyButton={true}
              />
              <CustomViewField
                label='Телефон'
                text={selectedEmployee?.phone}
                loading={false}
                copyButton={true}
              />
              <CustomViewField
                label='Доп. каналы связи'
                text={selectedEmployee?.communication_channels}
                loading={false}
                copyButton={true}
              />

              <CustomViewField
                label='Часовой пояс'
                text={selectedEmployee?.time_zone}
                loading={false}
              />
              <CustomViewField
                label='Рабочий график'
                text={selectedEmployee?.work_schedule}
                loading={false}
              />
              <CustomViewField
                label='Страна'
                text={selectedEmployee?.country}
                loading={false}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{mt:3, mb:1}}
              >
                <Grid item>
                  <Button variant='contained' type='submit' disabled={loading}>
                    Сохранить
                  </Button>
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ ml: 1 }}
                    onClick={handleBack}
                  >
                    Назад
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ ml: 1 }}
                    onClick={() => handleReset()}
                  >
                    Сбросить
                  </Button>
                </Grid>
              </Stack>
              <AlertMessage/>
            </Grid>
          </Grid>

      </form>
        </FormProvider>
      </Card>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>

      <AlertMessage/>
    </Container>
  );
};

export default EmployeesForm;
