import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const RemoteDynamicTabs = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    // Определяем значение вкладки на основе текущего пути
    const activeTabIndex = tabs.findIndex((component) =>
      location.pathname.endsWith(component.path),
    );
    if (activeTabIndex !== -1) {
      setValue(activeTabIndex);
    }
  }, [location.pathname, tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Определяем путь для перенаправления
    const newPath = tabs[newValue].path;
    navigate(newPath);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant='scrollable'>
          {tabs.map((component, index) => (
            <Tab
              key={index}
              label={component.label}
              // Добавим класс активной вкладке, если текущий путь совпадает с путем вкладки
              className={
                location.pathname.endsWith(component.path) ? 'active-tab' : ''
              }
              icon={
                component.count || component.count === 0 ? (
                  <Chip label={component.count} color='primary' size='small' />
                ) : (
                  <></>
                )
              }
              iconPosition='end'
              {...component}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((component, index) => (
        <TabPanel key={index} value={value} index={index}>
          {component.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default RemoteDynamicTabs;
