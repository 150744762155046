import React, {useCallback, useEffect, useState} from 'react';
import {Navigate, redirect, Route, useNavigate} from 'react-router-dom';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import {$authHost, $host} from '../../../http';
import axios from 'axios';
import {useActions} from '../../../hook/useActions';
import { baseUrl } from "../../../http/baseRoute";

export default function LoginForm({authTypeOffice, setAuthTypeOffice}) {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const {logIn, setToken, setRefreshToken, setTokenLifetime, setIdToken, setIdTokenLifetime} = useActions();

  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = React.useState(() => false);
  const [errorEmail, setErrorEmail] = React.useState(() => false);
  const [errorPassword, setErrorPassword] = React.useState(() => false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const loadData = async () => {
    try {
      const response = await $authHost.get('/users/me/');
      const utcResponseTime = new Date(response.headers['date']);
      const startTime = utcResponseTime.getTime(); //localTimeMilliseconds
      const {
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        street_address,
        work_schedule,
        communication_channels,
        groups
      } = response.data;
      logIn({
        display_name,
        first_name,
        last_name,
        email,
        phone,
        birth_date,
        about_me,
        country,
        photo,
        street_address,
        work_schedule,
        communication_channels,
        startTime,
        groups
      });
    } catch (e) {
    }
  };

  const channel = new BroadcastChannel('login-data');
  channel.addEventListener('message', (event) => {
    const {token, refreshToken, tokenLifetime, id_token, idTokenLifetime} = event.data;
    setToken(token);
    setRefreshToken(refreshToken);
    setTokenLifetime(tokenLifetime);
    setIdTokenLifetime(idTokenLifetime);
    setIdToken(id_token);
    loadData();
    channel.close();
  });

  const handleSubmit = () => {
  };

  // const handleSubmit = async () => {
  //
  //     const req = await axios.get('http://s-ax-erp-bk.dev.aspex.cloud/auth/login').then(
  //       (res) => {
  //         return Promise.resolve(res);
  //       }
  //     )
  //     // const newChannelList = async () => {
  //     //   const response = await $host.get('/auth/login');
  //     //   return Promise.reject(response);
  //     // }
  //     // newChannelList().then((data) => {
  //     //   console.log(data);
  //     // });
  //
  // }

  // const fetchAuthUser = async () => {
  //   const response = await axios
  //     .get("https://s-ax-erp-bk.dev.aspex.cloud/auth/callback")
  //     .catch((err) => {
  //       console.log("Not properly authenticated");
  //       // history.push("/login/error");
  //     });
  //
  //   if (response && response.data) {
  //     console.log("User: ", response.data);
  //     // history.push("/welcome");
  //   }
  // };

  const redirectToOfficeSSO = async () => {
    console.log(baseUrl);
    const officeLoginURL = `${baseUrl}/auth/login`;

    const newWindow = window.open(
      officeLoginURL,
      '_blank',
      'width=500,height=600',
    );
  };

  // console.log("draw");
  // const user = true;
  // useEffect(() => {
  //   if (user) {
  //     return <Navigate to='/' />;
  //   }
  // }, [user]);

  return (
    <>
      <Box component='form' onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
        {/*<TextField*/}
        {/*  error={errorEmail ? true : false}*/}
        {/*  margin='normal'*/}
        {/*  required*/}
        {/*  fullWidth*/}
        {/*  id='email'*/}
        {/*  type='email'*/}
        {/*  label='Логин'*/}
        {/*  name='email'*/}
        {/*  autoComplete='email'*/}
        {/*  autoFocus*/}
        {/*  helperText={*/}
        {/*    errorEmail*/}
        {/*      ? t(*/}
        {/*          'src.sections.auth.login.wrongInput',*/}
        {/*          'Некорректный ввод',*/}
        {/*          'ru',*/}
        {/*        )*/}
        {/*      : false*/}
        {/*  }*/}
        {/*/>*/}
        {/*<TextField*/}
        {/*  error={errorPassword ? true : false}*/}
        {/*  margin='normal'*/}
        {/*  required*/}
        {/*  fullWidth*/}
        {/*  name='password'*/}
        {/*  label='Пароль'*/}
        {/*  type={showPassword ? 'text' : 'password'}*/}
        {/*  id='password'*/}
        {/*  autoComplete='current-password'*/}
        {/*  helperText={*/}
        {/*    errorPassword*/}
        {/*      ? t(*/}
        {/*          'src.sections.auth.login.wrongInput',*/}
        {/*          'Некорректный ввод',*/}
        {/*          'ru',*/}
        {/*        )*/}
        {/*      : false*/}
        {/*  }*/}
        {/*  InputProps={{*/}
        {/*    endAdornment: (*/}
        {/*      <InputAdornment position='end'>*/}
        {/*        <IconButton*/}
        {/*          aria-label='toggle password visibility'*/}
        {/*          onClick={handleClickShowPassword}*/}
        {/*          edge='end'*/}
        {/*        >*/}
        {/*          {showPassword ? <VisibilityOff /> : <Visibility />}*/}
        {/*        </IconButton>*/}
        {/*      </InputAdornment>*/}
        {/*    ),*/}
        {/*  }}*/}
        {/*/>*/}
        <Button
          type='button'
          fullWidth
          variant='contained'
          sx={{mt: 3, mb: 2}}
          onClick={() => redirectToOfficeSSO()}
        >
          Войти с помощью Office 365
        </Button>
        {/*<Button*/}
        {/*  // type='submit'*/}
        {/*  onClick={() => setAuthTypeOffice(!authTypeOffice)}*/}
        {/*  fullWidth*/}
        {/*  variant='outlined'*/}
        {/*  sx={{ mt: 3, mb: 2 }}*/}
        {/*>*/}
        {/*  {authTypeOffice ? 'Авторизация' : 'Авторизация Office 365'}*/}
        {/*</Button>*/}
        <Collapse in={open}>
          <Alert
            severity='info'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize='inherit'/>
              </IconButton>
            }
            sx={{mb: 2}}
          >
            Неверный логин или пароль
          </Alert>
        </Collapse>
      </Box>
    </>
  );
}
