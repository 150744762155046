import {useTranslation} from 'react-i18next';
import Container from '@mui/material/Container';
import {
  Button,
  Card,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Iconify from '../components/iconify';
import {TableCellsWrap} from '../components/TableCellsWrap';
import DeleteIcon from '@mui/icons-material/Delete';
import {useActions} from '../hook/useActions';
import {useSelector} from 'react-redux';
import {$authHost} from '../http';
import LoadingSpinner from '../components/modals/loadingSpinner';
import {ButtonStack, TitleStack} from '../theme/standarts_styles';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import BlockFormModal from '../components/BlockFormModal';
import AlertMessage from '../components/Alert/AlertMessage';
import MaterialTablePagination from "../components/MaterialTablePagination";

const Clients = () => {
  const tableId = 'clients';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {setClients, setSelectedClient} = useActions();
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const {
    roles
  } = useSelector(state => state.authReducer)

  const {clients, selectedClient} = useSelector(
    (state) => state.clientsReducer,
  );

  const {setErrorAlertMessage, resetErrorAlertMessage} = useActions();

  const tableColumns = [
    {
      accessorKey: 'name',
      header: 'Наименование',
      accessorFn: (row) => row.name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'full_name',
      header: 'Полное наименование',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'code',
      header: 'Код клиента',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'TIN',
    //   header: 'TIN',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: 'address',
      header: 'Адрес',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'web_site',
      header: 'Веб-сайт',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'phone',
    //   header: 'Телефон',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: 'country',
      header: 'Страна',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'details',
      header: 'Реквизиты',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {
        },
        sx: {
          cursor: 'auto',
        },
      },
      Cell: ({renderedCellValue, row}) => {
        if (confirmationId === row.original.source_id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.source_id)}
                >
                  <CheckIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon/>
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Редактировать'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handleEditRow(row.original.source_id)}
              >
                <EditIcon/>
              </IconButton>
            </Tooltip>
            {roles?.some(role => role === 'ERP_Admins') && (
              <Tooltip title='Удалить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(row.original.source_id)}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const handleAddClient = (event) => {
    navigate(`/clients/new`);
  };

  const handleEditRow = (id) => {
    setSelectedClient(id);
    navigate(`/clients/edit/${id}`);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.source_id;
    if (id) {
      navigate(`/clients/${id}`);
      setSelectedClient(id);
    }
  };


  async function handleDeleteRow(id) {
    setLoading(true);
    resetErrorAlertMessage();
    try {
      await $authHost.delete(`/clients/${id}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
      if (
        e.response.data?.detail === 'Cannot delete client with active projects'
      ) {
        setErrorAlertMessage(
          'Для данного клиента существуют связанные проекты. Удаление невозможно.',
        );
      }
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const loadData = async () => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/clients/all/?search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
      );
      const data = response.data.items;
      setClients(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    resetErrorAlertMessage();
  }, []);

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  return (
    <Container>
      <TitleStack>
        <Typography variant='h4' sx={{mr: 4}}>
          Клиенты
        </Typography>
      </TitleStack>
      <ButtonStack>
        <Button
          onClick={handleAddClient}
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill'/>}
          sx={{mr: 3}}
        >
          Новый клиент
        </Button>
      </ButtonStack>
      <AlertMessage/>
      <Card sx={{pt: 1}}>
        <MaterialTablePagination
          id={tableId}
          data={clients}
          columns={tableColumns}
          loading={loading}
          setPagination={setPagination}
          pagination={pagination}
          rowCount={rowCount}
          pageCount={pageCount}
          onGlobalFilterChange={onGlobalFilterChange}
          search={searchText}
          muiTableBodyCellProps={({row}) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Card>
      <BlockFormModal
        when={!!confirmationId}
        title='Вы не завершили действие внутри таблицы'
      />
    </Container>
  );
};

export default Clients;
