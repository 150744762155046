import { Controller, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { $authHost } from '../../http';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import BlockFormModal from '../BlockFormModal';

const PlanningAdd = ({ handleClose, loadDataPlans }) => {
  const [loading, setLoading] = useState(false);

  const { projectId } = useParams();

  const { project } = useSelector((state) => state.projectsReducer);

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    register,
    reset,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    const { user, time_estimated, start_date, end_date } = data;

    const newData = {
      time_estimated,
      user_id: user?.source_id,
      start_date: dayjs(start_date).format('YYYY-MM-DDTHH:mm:ss'),
      end_date: dayjs(end_date).format('YYYY-MM-DDTHH:mm:ss'),
      type: 'Plan',
      project_id: projectId,
    };

    try {
      const response = await $authHost.post('/calendar_entries/add/', newData);
      setLoading(false);
      handleClose();
      loadDataPlans();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card sx={{ p: 3, mb: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              alignItems='flex-start'
            >
              <Controller
                control={control}
                rules={
                  {
                    // required: 'Обязательное поле',
                  }
                }
                name='user'
                render={({
                  field: { onChange, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <Autocomplete
                      {...field}
                      options={project?.team}
                      getOptionLabel={(option) => option.display_name}
                      sx={{
                        mb: 2,
                        width: '100%',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Сотрудник'
                          error={!!error}
                          helperText={error?.message}
                          size='small'
                        />
                      )}
                      onChange={(e, value) => onChange(value)}
                    />
                  );
                }}
              />
              <TextField
                label='Плановая нагрузка'
                {...register('time_estimated', {
                  // required: 'Обязательное поле',
                  pattern: {
                    value: /^\d*$/,
                    message: 'Некорректный ввод',
                  },
                })}
                error={!!errors?.time_estimated}
                helperText={errors?.time_estimated?.message}
                fullWidth
                size='small'
                sx={{
                  mb: 2,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>h</InputAdornment>
                  ),
                }}
              />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name='start_date'
                    control={control}
                    rules={
                      {
                        // required: 'Обязательное поле',
                      }
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label='Дата начала'
                          control={control}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                          sx={{
                            width: '100%',
                            mb: 2,
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name='end_date'
                    control={control}
                    rules={
                      {
                        // required: 'Обязательное поле',
                      }
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label='Дата конца'
                          control={control}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                            },
                          }}
                          sx={{
                            width: '100%',
                            mb: 2,
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ width: '100%', mb: 2 }}>
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <Button
                    variant='contained'
                    type='submit'
                    sx={{ mr: 2 }}
                    disabled={loading}
                  >
                    Сохранить
                  </Button>
                  <Button variant='text' onClick={handleClose}>
                    Закрыть
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    onClick={() => reset()}
                    disabled={loading}
                  >
                    Очистить
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </form>
        </Card>
      </Grid>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Grid>
  );
};

export default PlanningAdd;
