import Stack from '@mui/material/Stack';
import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomTextField from '../../components/TextFields/CustomTextField';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/modals/loadingSpinner';
import { useActions } from '../../hook/useActions';
import { useSelector } from 'react-redux';
import { $authHost } from '../../http';
import { useNavigate } from 'react-router-dom';
import BlockFormModal from '../../components/BlockFormModal';

const ClientsForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateFullSelectedClient } = useActions();

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(selectedClient.source_id)
    let new_arr = {
      address: data.address || null,
      code: data.code || null,
      country: data.country || null,
      details: data.details || null,
      email: data.email || null,
      full_name: data.full_name || null,
      name: data.name || null,
      web_site: data.web_site || null,
    }
    try {
      await $authHost.put(`/clients/${selectedClient.source_id}/update/`, new_arr);
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/clients');
    }
  }, [isSubmitSuccessful]);

  const handleReset = () => {
    setValue('name', selectedClient.name);
    setValue('full_name', selectedClient.full_name);
    setValue('code', selectedClient.code);
    setValue('address', selectedClient.address);
    setValue('web_site', selectedClient.web_site);
    setValue('details', selectedClient.details);
    setValue('country', selectedClient.country);
    setValue('email', selectedClient.email);
  };

  const handleBack = () => {
    navigate('/clients');
  };

  useEffect(() => {
    handleReset();
  }, []);

  const validateName = async (value) => {
    if (value === selectedClient.name) {
      return true;
    }

    if (value) {
      const trimmedName = value.toString().trim();
      if (trimmedName) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?name=${trimmedName}`,
        );
        if (data?.unique_name === false) {
          return 'Наименование должно быть уникальным';
        }
        if (data?.unique_name === true) {
          return true;
        }
      }
    }
  };

  const validateCode = async (value) => {
    if (value === selectedClient.code) {
      return true;
    }

    if (value) {
      const trimmedCode = value.toString().trim();
      if (trimmedCode) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?code=${trimmedCode}`,
        );
        if (data?.unique_code === false) {
          return 'Код должен быть уникальным';
        }
        if (data?.unique_code === true) {
          return true;
        }
      }
    }
  };

  return (
    <Box sx={{ flexDirection: 'column', px: 0, py: 4 }}>
      {loading && <LoadingSpinner />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTextField
            name='name'
            label='Наименование'
            registerOptions={{
              required: t('main.another.requiredField'),
              validate: (value) => validateName(value),
            }}
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
          />
          <CustomTextField
            name='full_name'
            label='Полное наименование'
            registerOptions={{
              required: t('main.another.requiredField'),
            }}
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
          />
          <CustomTextField
            name='code'
            label='Код клиента'
            registerOptions={{
              required: t('main.another.requiredField'),
              validate: (value) => validateCode(value),
            }}
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
          />
          <CustomTextField
            name='address'
            label='Адрес'
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
            multiline={true}
            rows={3}
          />
          <CustomTextField
            name='web_site'
            label='Веб-сайт'
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
          />
          <CustomTextField
            name='email'
            label='Email'
            registerOptions={{
              // required: t('main.another.requiredField'),
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: t('main.another.wrongInput'),
              },
            }}
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
          />
          <CustomTextField
            name='details'
            label='Реквизиты'
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
            multiline={true}
            rows={3}
          />
          <CustomTextField
            name='country'
            label='Страна'
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            sx={{ width: '100%', fontSize: '12px', mb: 3 }}
            size='small'
          />
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item>
              <Button variant='contained' type='submit'>
                Сохранить
              </Button>
              <Button
                variant='text'
                color='primary'
                sx={{ ml: 1 }}
                onClick={handleBack}
              >
                Назад
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='text'
                color='primary'
                sx={{ ml: 1 }}
                onClick={handleReset}
              >
                Сбросить
              </Button>
            </Grid>
          </Stack>
        </form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Box>
  );
};

export default ClientsForm;
