export const convertToNull = (object) => {
  let newObject = {}
  Object.keys(object).forEach((key) => {
    if (object[key] === "") {
      newObject[key] = null
    } else {
      newObject[key] = object[key]
    }
  })
  return newObject;
}