import {Card} from '@mui/material';
import {TableCellsWrap} from '../TableCellsWrap';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {$authHost} from "../../http";
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import MaterialTablePagination from "../MaterialTablePagination";

const MyContracts = () => {
  const tableId = 'myContracts';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {setMyContracts, setSelectedMyContact} = useActions();
  const {myContracts} = useSelector((state) => state.authReducer);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      accessorKey: 'date_and_number',
      header: 'Номер договора',
      accessorFn: (row) => row.date_and_number || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'start_date',
      header: 'Дата заключения',
      accessorFn: (row) => dayjs(row.start_date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'end_date',
      header: 'Дата окончания',
      accessorFn: (row) => {
        if (row.is_unlimited) {
          return 'Бессрочный';
        } else {
          return dayjs(row.end_date).format('DD.MM.YYYY') || '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Статус договора',
      accessorFn: (row) => row.status || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Тип договора',
      accessorFn: (row) => row.type || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'legal_entity',
      header: 'Юр. лицо',
      accessorFn: (row) => row.legal_entity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'production_calendar',
      header: 'Производственный календарь',
      accessorFn: (row) => row.production_calendar || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'loyalty_program',
      header: 'Программа лояльности',
      accessorFn: (row) => row.loyalty_program || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'interaction_capacity',
      header: 'Вид занятости',
      accessorFn: (row) => row.interaction_capacity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'hour_capacity',
      header: 'Объем часов Fix',
      accessorFn: (row) => {
        if (row.interaction_capacity === 'Fix' && row.hour_capacity) {
          return row.hour_capacity;
        } else {
          return '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'hour_rate',
      header: 'Ставка в час',
      accessorFn: (row) => {
        if (row.interaction_capacity === 'Fix' && row.hour_rate) {
          return row.hour_rate;
        } else {
          return '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'month_rate',
      header: 'Ставка в месяц',
      accessorFn: (row) => {
        if (row.interaction_capacity === 'Fulltime' && row.month_rate) {
          return row.month_rate;
        } else {
          return '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'currency',
      header: 'Валюта договора',
      accessorFn: (row) => row.currency || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'vacation_days',
      header: 'Отпуск в год, дней',
      accessorFn: (row) => row.vacation_days || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'sick_days',
      header: 'Больничный в год, дней',
      accessorFn: (row) => row.sick_days || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/account/contracts/${id}`);
      setSelectedMyContact(id);
    }
  };

  const loadData = () => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(
          `/agreements/me/?search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
        );
        setRowCount(response.data?.total);
        setPageCount(response.data?.pages);
        setMyContracts(response.data.items);
        setLoading(false);
      };
      getData();
    } catch (e) {
    }
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  return (
    <Card sx={{py: 1, my: 2}}>
      <MaterialTablePagination
        id={tableId}
        data={myContracts}
        columns={tableColumns}
        enableHiding={true}
        loading={loading}
        initialState={{
          columnVisibility: {
            date_and_number: false,
            type: false,
            legal_entity: false,
            hour_rate: false,
            month_rate: false,
            currency: false,
            vacation_days: false,
            sick_days: false
          },
        }}
        setPagination={setPagination}
        pagination={pagination}
        rowCount={rowCount}
        pageCount={pageCount}
        onGlobalFilterChange={onGlobalFilterChange}
        search={searchText}
        muiTableBodyCellProps={({row}) => ({
          onClick: () => {
            handleOpenViewPage(row);
          },
          sx: {
            cursor: 'pointer',
          },
        })}
      />
    </Card>
  );
};

export default MyContracts;
