import React, { useCallback, useEffect, useState } from 'react';
import { TableCellsWrap } from '../TableCellsWrap';
import { Button, Card, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Iconify from '../iconify';
import MaterialTable from '../MaterialTable';
import PlanningAdd from './PlanningAdd';
import PlanningEdit from './PlanningEdit';
import { $authHost } from '../../http';
import { useActions } from '../../hook/useActions';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import BlockFormModal from "../BlockFormModal";

const Planning = () => {
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const { projectId } = useParams();

  const { setPlans } = useActions();

  const { plans } = useSelector((state) => state.planningReducer);

  const tableColumns = [
    {
      id: 'user',
      header: 'Сотрудник',
      accessorFn: (row) => row.user?.display_name || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'time_estimated',
      header: 'Плановая загрузка',
      accessorFn: (row) => row.time_estimated || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'start_date',
      header: 'Период (начало)',
      accessorFn: (row) => dayjs(row.start_date).format('DD.MM.YYYY') || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'end_date',
      header: 'Период (конец)',
      accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Редактировать'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handleEditRow(row.original.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Удалить'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setConfirmationId(row.original.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    setOpenEditForm(false);
    setOpenAddForm(true);
  };

  function handleEditRow(id) {
    setPlanId(id);
    setOpenAddForm(false);
    setOpenEditForm(true);
  }

  async function handleDeleteRow(id_row) {
    setLoading(true);
    try {
      await $authHost
        .delete(`/calendar_entries/${id_row}/delete/`)
        .then((response) => {
          loadData();
        });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenAddForm(false);
    setOpenEditForm(false);
  }

  const loadData = useCallback(() => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(
          `/calendar_entries/all/?project_id=${projectId}&types=plan`,
        );
        setPlans(response.data);
        setLoading(false);
      };
      getData();
    } catch (e) {}
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <Stack
        direction='row'
        sx={{ height: '70px' }}
        alignItems='center'
        justifyContent='start'
        mb={0}
      >
        <Button
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill' />}
          sx={{ mr: 0 }}
          onClick={handleAdd}
        >
          Добавить план
        </Button>
      </Stack>
      {/*<AlertMessage/>*/}
      {openAddForm && (
        <PlanningAdd handleClose={handleClose} loadDataPlans={loadData} />
      )}
      {openEditForm && (
        <PlanningEdit
          handleClose={handleClose}
          loadDataPlans={loadData}
          planId={planId}
        />
      )}
      <Card sx={{ pt: 1 }}>
        <MaterialTable data={plans} columns={tableColumns} loading={loading} />
      </Card>
      <BlockFormModal when={!!confirmationId} title='Вы не завершили действие внутри таблицы' />
    </Container>
  );
};

export default Planning;
