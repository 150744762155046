import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import React from 'react'
import MySchedule from '../components/Schedule/MySchedule'
import CompanySchedule from '../components/Schedule/CompanySchedule'
import DynamicTabs from '../components/Tabs/DynamicTabs'
import WorkSchedule from '../components/workSchedule'
import { TitleStack } from '../theme/standarts_styles'
import ApproveSchedule from '../components/ApproveSchedule'
import { useSelector } from "react-redux";

const Schedule = () => {
	const { t, i18n } = useTranslation()
	const { roles } = useSelector(state => state.authReducer)



	return (
		<Container>
			<TitleStack>
				<Typography variant="h4" sx={{ mr: 4 }}>
					Расписание
				</Typography>
			</TitleStack>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<DynamicTabs
						components={[
							{ label: 'Мое расписание', component: <MySchedule admin={roles?.some(role => role === 'ERP_Admins')}/> },
							{ label: 'Мои заявления', component: <ApproveSchedule id={1} admin={false}/> },
							...(roles?.some(role => role === 'ERP_Admins') ? [{ label: 'Согласование', component: <ApproveSchedule id={2} admin={true}/> }] : []),
							// ...(roles?.some(role => role === 'ERP_Admins') ? [{ label: 'Расписание компании', component: <CompanySchedule/> }] : []),
						]}
						orientation="horizontal"
					/>
				</Box>

			</Box>
		</Container>
	)
}

export default Schedule
