import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import SvgColor from '../svg-color';
// @mui
import { Box, Chip, Grid, List, ListItemText } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
//react
import React, { useMemo } from 'react';
//redux
import { useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles';
import palette from '../../theme/palette';
//i18next
import { useTranslation } from 'react-i18next';
import { StyledChip, StyledNavItem } from '../../theme/standarts_styles'
// ----------------------------------------------------------------------

export default function NavSection({ ...other }) {
  const { t, i18n } = useTranslation();
  const { roles } = useSelector(state => state.authReducer)

  const icon = (name) => (
    <SvgColor
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1 }}
    />
  );

  const navConfig = [
    {
      title: 'Главная',
      path: '/',
      // icon: icon('ic_analytics'),
      icon: <HomeOutlinedIcon size="small"/>,
      counterChip: false,
      roles: ['ERP_Users', 'ERP_Admins'],
    },
    {
      title: 'Клиенты',
      path: '/clients',
      // icon: icon('ic_cart'),
      icon: <WorkOutlineIcon size="small"/>,
      roles: ['ERP_Users', 'ERP_Admins'],
      counterChip: false,
    },
    {
      title: 'Проекты',
      path: '/projects',
      // icon: icon('ic_analytics'),
      icon: <FolderOpenIcon size="small"/>,
      roles: ['ERP_Users', 'ERP_Admins'],
      counterChip: true,
    },
    {
      title: 'Расписание',
      path: '/schedule',
      // icon: icon('ic_cart'),
      icon: <CalendarMonthIcon size="small"/>,
      roles: ['ERP_Users', 'ERP_Admins'],
      counterChip: false,
    },
    {
      title: 'Табели',
      path: '/timesheets',
      icon: <CalendarViewMonthIcon size="small"/>,
      roles: ['ERP_Admins'],
      counterChip: false,
    },
    {
      title: 'Договоры',
      path: '/contracts',
      // icon: icon('ic_user'),
      icon: <PeopleOutlineIcon size="small"/>,
      roles: ['ERP_Admins'],
      counterChip: false,
    },
    {
      title: 'Орг. структура',
      path: '/org-structure/employees',
      // icon: icon('ic_user'),
      icon: <AccountTreeIcon size="small"/>,
      roles: ['ERP_Users', 'ERP_Admins'],
      counterChip: false,
    },
  ];
  // const chats = useSelector(state => state.dialoguesReducer.chats)
  // const unreadChats = useMemo(() => (
  //   chats.filter(chat => chat.msg_count > 0).length  // TODO Механика новых сообщений
  // ), [chats])

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => (
          roles.some(role => item.roles?.some(r => r === role)) && (
            <NavItem
              key={item.title}
              item={item}
              // unreadChats={unreadChats}
              unreadChats={[]}
            />
          )
        ))}
      </List>
    </Box>
  )
}

function NavItem({ item, unreadChats }) {

  const { title, path, icon, info, counterChip } = item;
  const { openNav } = useSelector(state => state.serviceReducer)

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        p: 1,
        color: 'layout.element',
        '&.active': {
          bgcolor: 'layout.main',
          fontWeight: 'fontWeightBold',
          borderRadius: '8px',
        },
      }}
    >
      { openNav ?
        <>
          {icon && icon}
          <ListItemText
            disableTypography
            primary={title}
            sx={{
              ml: 2
            }}
          />
          {counterChip && unreadChats > 0 &&
            <StyledChip label={unreadChats} size="small" />
          }
        </>
        :
        <>
          {/*<Grid*/}
          {/*  container*/}
          {/*  direction="column"*/}
          {/*  justifyContent="center"*/}
          {/*  alignItems="center"*/}
          {/*  sx={{*/}
          {/*    width: '100%'*/}
          {/*  }}*/}
          {/*>*/}
            {icon && icon}
          {/*</Grid>*/}
        </>

      }


    </StyledNavItem>
  )
}
