import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { $authHost } from '../../http';
import { useActions } from '../../hook/useActions';
import dayjs from 'dayjs';
import CustomViewField from '../CustomViewField';

const CommonSettings = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const { setProject } = useActions();
  const { project } = useSelector((state) => state.projectsReducer);

  const [loading, setLoading] = useState(false);

  const loadProject = useCallback(() => {
    setLoading(true);
    try {
      const getProject = async () => {
        const response = await $authHost.get(`/projects/${projectId}/`);
        setProject(response.data);
        setLoading(false);
      };
      getProject();
    } catch (e) {}
  }, []);

  useEffect(() => {
    loadProject();
  }, []);

  const handleBack = () => {
    navigate('/projects');
  };

  const handleEdit = () => {
    navigate(`/projects/edit/${projectId}`);
  };

  return (
    <Box sx={{ flexDirection: 'column', px: 0, py: 2 }}>
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={6}>
          <Typography
            variant='subtitle1'
            sx={{
              mb: 2,
            }}
          >
            Общие сведения
          </Typography>
          <CustomViewField
            label='Проект'
            text={`${project.code} ${project.name}`}
            loading={loading}
          />
          <CustomViewField
            label='Клиент'
            text={`${project.client?.code} ${project.client?.name}`}
            type='link'
            path={`/clients/${project.client?.id}`}
            loading={loading}
          />
          <CustomViewField
            label='Статус проекта'
            text={project.status}
            loading={loading}
          />
          <CustomViewField
            label='Описание'
            text={project.notes}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant='subtitle1'
            sx={{
              mb: 2,
            }}
          >
            Интеграция
          </Typography>
          <CustomViewField
            label='ClickUp'
            text={project.clickup}
            loading={loading}
          />
          <CustomViewField
            label='Teams'
            text={project.teams}
            loading={loading}
          />
          <CustomViewField
            label='Azure'
            text={project.azure_dev_ops_space}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant='subtitle1'
            sx={{
              mb: 2,
            }}
          >
            Свойства
          </Typography>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <CustomViewField
                label='Старт проекта'
                text={project.actual_start_date ? dayjs(project.actual_start_date).format('DD.MM.YYYY') : ''}
                loading={loading}
              />
              <CustomViewField
                label='Дата завершения'
                text={project.actual_end_date ? dayjs(project.actual_end_date).format('DD.MM.YYYY') : ''}
                loading={loading}
              />
              <CustomViewField
                label='Валюта проекта'
                text={project.currency}
                loading={loading}
              />
              <CustomViewField
                label='Бюджет в валюте'
                text={project.currency_budget}
                loading={loading}
              />
              <CustomViewField
                label='Бюджет в ком. часах'
                text={project.commercial_hours_budget}
                loading={loading}
              />
              <CustomViewField
                label='Бюджет во вн. часах'
                text={project.internal_hours_budget}
                loading={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomViewField
                label='Старт План'
                text={project.start_date ? dayjs(project.start_date).format('DD.MM.YYYY') : ''}
                loading={loading}
              />
              <CustomViewField
                label='Завершение План'
                text={project.end_date ? dayjs(project.end_date).format('DD.MM.YYYY') : ''}
                loading={loading}
                sx={{ mb: 10 }}
              />
              <CustomViewField
                label='Нач. бюджет в валюте'
                text={project.currency_budget_start}
                loading={loading}
              />
              <CustomViewField
                label='Нач. бюджет в ком. часах'
                text={project.commercial_hours_budget_start}
                loading={loading}
              />
              <CustomViewField
                label='Нач. бюджет во вн. часах'
                text={project.internal_hours_budget_start}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant='subtitle1'
            sx={{
              mb: 2,
            }}
          >
            Команда проекта
          </Typography>
          <CustomViewField
            label='Менеджер проекта'
            text={project.manager?.display_name}
            loading={loading}
          />
          <CustomViewField
            label='Команда'
            text={
              Array.isArray(project.team)
                ? project.team.map((t) => t.display_name).join(', ')
                : ''
            }
            loading={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' onClick={handleBack}>
            Назад к списку
          </Button>
          <Button
            variant='text'
            color='primary'
            sx={{ ml: 1 }}
            onClick={handleEdit}
          >
            Редактировать
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommonSettings;
