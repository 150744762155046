import React, {useState} from 'react';
import {alpha} from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import {useAuth} from '../../hook/useAuth';
import {NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import useResponsive from '../../hook/useResponsive';
import LanguageSwitchMobile from './LanguageSwitchMobile';
import {desiredLocations} from '../../constants';
import {useSelector} from 'react-redux';
import {StyledChip, StyledSatus} from '../../theme/standarts_styles';
import Chip from '@mui/material/Chip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ThemeSwitch from '../../components/ThemeSwitch';
import Button from "@mui/material/Button";

export default function AccountPopover() {
  const {t} = useTranslation();
  const MENU_OPTIONS = [
    {
      label: t('src.layouts.dashboard.header.AccountPopover.munuOption1'),
      icon: 'eva:settings-2-fill',
      path: '/account',
    },
  ];
  const [open, setOpen] = useState(null);
  const isDesktop = useResponsive('up', 'lg');
  const {signOut} = useAuth();
  const location = useLocation();
  const currentLocation = desiredLocations.includes(location.pathname);

  const {sseChannelError} = useSelector((state) => state.serviceReducer);

  const {display_name, email, photo} = useSelector((state) => state.authReducer);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    signOut();
    setOpen(false);
  };

  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        <Stack spacing={1} direction='row' alignItems='center'>
          {/*<Badge*/}
          {/*  color={sseChannelError ? 'error' : 'success'}*/}
          {/*  variant='dot'*/}
          {/*  overlap='circular'*/}
          {/*  badgeContent=' '*/}
          {/*  sx={{mr: isDesktop ? 1 : 0}}*/}
          {/*>*/}
            <Avatar
              src={photo}
              alt='photoURL'
              sx={{
                width: '30px',
                height: '30px',
                mr: isDesktop ? 1 : 0
              }}
            />
          {/*</Badge>*/}
          {isDesktop && (
            <Box>
              <Typography
                variant='subtitle1'
                sx={{
                  color: 'layout.element',
                  textAlign: 'start',
                }}
              >
                {display_name}
              </Typography>
            </Box>
          )}
          <KeyboardArrowDownIcon
            fontSize='medium'
            sx={{
              color: 'layout.element',
            }}
          />
        </Stack>
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        sx={{zIndex: '10000'}}
        PaperProps={{
          sx: {
            zIndex: 5001,
            backgroundColor: 'background.neutral',
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant='subtitle2' noWrap>
            {display_name}
          </Typography>
          <Typography variant='body2' sx={{color: 'text.secondary'}} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>

        {/*more navigation*/}
        {!currentLocation && (
          <>
            <Stack sx={{p: 1}}>
              {MENU_OPTIONS.map((option, index) => (
                <NavLink
                  to={option.path}
                  style={{
                    fontStyle: 'none',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                  onClick={handleClose}
                  key={index}
                >
                  <MenuItem key={option.label}>{option.label}</MenuItem>
                </NavLink>
              ))}
            </Stack>
            <Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>
          </>
        )}
        {/*<MenuItem sx={{m: 1}}>*/}
        {/*  <ThemeSwitch/>*/}
        {/*</MenuItem>*/}
        {/*<Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>*/}

        <MenuItem onClick={handleLogout} sx={{m: 1}}>
          {t('src.layouts.dashboard.header.AccountPopover.exit')}
        </MenuItem>
      </Popover>
    </>
  );
}
