import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";

const Timesheets = () => {
  const location = useLocation();

  const tabs = [
    {label: 'Месяц', path: 'month'},
    {label: 'Квартал', path: 'quarter', disabled: true},
    {label: 'Год', path: 'year', disabled: true},
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith('/')) {
      redirectPath += '/';
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath}/>;
  }

  return (
    <Container sx={{pb: 0}}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={0}
      >
        <Typography variant='h4' gutterBottom>
          Табели
        </Typography>
      </Stack>
      <RemoteDynamicTabs tabs={tabs}/>
      <Outlet/> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
}

export default Timesheets;
