import React from 'react'
import CustomTextField from '../TextFields/CustomTextField'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { matchIsValidTel } from 'mui-tel-input'
import CustomMuiTelInput from '../TextFields/CustomMuiTelInput'

const ContactsForm = () => {
	const { t } = useTranslation()

	const {
		control,
		watch,
		formState: {
			errors,
		},
	} = useFormContext()

	const phone = watch('phone')
	const email = watch('email')

	return (
		<>
			<CustomTextField
				name="full_name"
				label="ФИО"
				registerOptions={{
					required: t('main.another.requiredField'),
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
			/>
			{/*<CustomTextField*/}
			{/*  name="type"*/}
			{/*  label="Тип"*/}
			{/*  registerOptions={{*/}
			{/*    required: t('main.another.requiredField'),*/}
			{/*  }}*/}
			{/*  sx={{ width: '100%', fontSize: '12px', mb: 3 }}*/}
			{/*  size="small"*/}
			{/*/>*/}
			<CustomTextField
				name="position"
				label="Должность"
				registerOptions={
					{
						// required: t('main.another.requiredField'),
					}
				}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
			/>
			<CustomMuiTelInput
				name="phone"
				label={t('src.components.exportComponents.PaymentPlanForm.table_tel', 'Телефон')}
				rules={{
					required: (phone || email) ? false : t('main.another.requiredField'),
					validate: {
						validPhone: (phone) => {
							if (!phone) {
								return true;
							} else if (!matchIsValidTel(phone)) {
								return 'Некорректный номер телефона';
							}
							return true; // Возвращает true, если проверка прошла успешно
						},
					},
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
			/>
			<CustomTextField
				name="email"
				label="Email"
				registerOptions={{
					required: (phone || email) ? false : t('main.another.requiredField'),
					pattern: {
						value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
						message: t('main.another.wrongInput'),
					},
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
			/>
			<CustomTextField
				name="notes"
				label="Комментарий"
				// registerOptions={{
				//   required: t('main.another.requiredField'),
				// }}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
			/>
			<CustomTextField
				name="additional_communication"
				label="Дополнительноя коммуникация"
				// registerOptions={{
				//   required: t('main.another.requiredField'),
				// }}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
			/>
		</>
	)
}

export default ContactsForm
