import {combineReducers} from 'redux';
import {authReducer} from './slice/auth.slice';
import {projectsReducer} from './slice/projects.slice';
import {clientsReducer} from './slice/clients.slice';
import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {departmentsReducer} from './slice/departments.slice';
import {employeesReducer} from './slice/employees.slice';
import {budgetsReducer} from './slice/budgets.slice';
import {scheduleReducer} from './slice/schedule.slice';
import {planningReducer} from './slice/planning.slice';
import {contactsReducer} from './slice/contacts.slice';
import {calendarReducer} from './slice/calendar.slice'; // defaults to localStorage for web
import {serviceReducer} from './slice/service.slice';
import {timesheetsReducer} from './slice/timesheets.slice';
import {tableStatesReducer} from './slice/tableStates.slice';
import {contractsReducer} from "./slice/contracts.slice";
import {usersReducer} from "./slice/users.slice";
import {statisticsReducer} from "./slice/statistics.slice";

const rootReducer = combineReducers({
  authReducer,
  clientsReducer,
  contactsReducer,
  projectsReducer,
  departmentsReducer,
  employeesReducer,
  budgetsReducer,
  scheduleReducer,
  planningReducer,
  calendarReducer,
  serviceReducer,
  timesheetsReducer,
  tableStatesReducer,
  contractsReducer,
  usersReducer,
  statisticsReducer,
  // [sseApi.reducerPath]: sseApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage, // ~ storage: storage
  // blacklist: [''],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
