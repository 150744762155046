import {Card, Box, Grid, Tooltip, Popover, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import {$authHost} from '../../http';
import LoadingIndicator from '../LoadingIndicator';
import dayjs from 'dayjs';
import ScheduleFilters from './ScheduleFilters';
import {EventDiv, StyledChipTime} from '../../theme/standarts_styles';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const utcOffset = dayjs().utcOffset();

const EventTime = ({event}) => {
  const [TAnchorEl, setTAnchorEl] = useState(null);
  const [CAnchorEl, setCAnchorEl] = useState(null);

  const handleClickOne = (event) => {
    setTAnchorEl(event.currentTarget);
  };
  const handleClickTwo = (event) => {
    setCAnchorEl(event.currentTarget);
  };

  const handleCloseOne = () => {
    setTAnchorEl(null);
  };
  const handleCloseTwo = () => {
    setCAnchorEl(null);
  };

  const openT = Boolean(TAnchorEl);
  const openC = Boolean(CAnchorEl);

  const clickUpTimeEntries = event.extendedProps?.time_entries_clickup;
  const teamsTimeEntries = event.extendedProps?.time_entries_teams;

  return (
    <>
      <StyledChipTime
        label={`${(event.extendedProps?.minutes_teams / 60).toFixed(2)} ч`}
        size='small'
        variant='outlined'
        color_type={'#6E70B9'}
        onClick={handleClickOne}
      />
      <StyledChipTime
        sx={{ml: 1}}
        label={`${(event.extendedProps?.minutes_clickup / 60).toFixed(2)} ч`}
        size='small'
        variant='outlined'
        color_type={'#07A092'}
        onClick={handleClickTwo}
      />
      {teamsTimeEntries.length > 0 && (
        <Popover
          open={openT}
          anchorEl={TAnchorEl}
          onClose={handleCloseOne}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{p: 1}} variant='subtitle1'>
            Часы за день:
          </Typography>
          {teamsTimeEntries.map((t) => (
            <Typography key={t.title} sx={{p: 1}}>
              {t.start} - {t.end} <b>{t.title}</b>:{' '}
              <Typography sx={{color: 'text.secondary'}} component='text'>
                {(t.duration / 3600).toFixed(2)} ч.
              </Typography>
            </Typography>
          ))}
        </Popover>
      )}
      {clickUpTimeEntries.length > 0 && (
        <Popover
          open={openC}
          anchorEl={CAnchorEl}
          onClose={handleCloseTwo}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{p: 1}} variant='subtitle1'>
            Часы за день:
          </Typography>
          {clickUpTimeEntries.map((t) => (
            <Typography key={t.title} sx={{p: 1}}>
              {t.start} - {t.end} <b>{t.project}</b> {t.title}:{' '}
              <Typography sx={{color: 'text.secondary'}} component='text'>
                {(t.duration / 3600).toFixed(2)} ч.
              </Typography>
            </Typography>
          ))}
        </Popover>
      )}
    </>
  );
};

const MySchedule = ({admin}) => {
  const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState('');
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams('');

    const filterKeys = Object.keys(filterValues);
    const filterString = filterKeys.map((key) => {
      const currentFilter = filterValues[key];
      if (typeof currentFilter === 'object') {
        const currentValues = Object.keys(currentFilter).map((currentKey) => {
          if (currentFilter[currentKey] === true) {
            return {name: currentKey};
          }
        });
        return {name: key, childrens: currentValues};
      } else if (currentFilter) {
        return {name: key};
      }
    });

    filterString.forEach((f) => {
      if (f && Array.isArray(f.childrens)) {
        const filterChildrens = f.childrens.filter((c) => c);
        if (filterChildrens.length > 0) {
          filterChildrens.forEach((fc) => {
            searchParams.append(f.name, fc.name);
          });
        }
      } else if (f) {
        searchParams.append(f.name, 'true');
      }
    });
    setFilterParams(searchParams.toString());

  }, [filterValues]);

  const renderEventContent = useCallback(({event}) => {
    if (event.extendedProps.type === 'time') {
      return <EventTime event={event}/>;
    }

    if (event.extendedProps?.source === 'Teams') {
      return (
        <Tooltip title={event?.title}>
          <EventDiv color={'rgb(121,122,180)'}>
            <p
              style={{
                textOverflow: 'ellipsis',
                margin: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              <b>{event.extendedProps?.timeStart}</b> {event?.title}
            </p>
          </EventDiv>
        </Tooltip>
      );
    }

    if (event.extendedProps?.source === 'ClickUp') {
      return (
        <Tooltip title={event?.title}>
          <EventDiv color={'rgb(83,171,164)'}>
            <p
              style={{
                textOverflow: 'ellipsis',
                margin: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              <b>{event.extendedProps?.project}</b> {event?.title}
            </p>
          </EventDiv>
        </Tooltip>
      );
    }

    return <></>;
  }, []);

  const getEvents = useCallback(
    async (fetchInfo, successCallback, failureCallback) => {
      setLoading(true);

      const startDate = fetchInfo.startStr;
      const endDate = fetchInfo.endStr;
      let response;
      // let response_backs;
      //
      // let current_month = dayjs(fetchInfo.startStr).add(8, 'day').format('MM')
      // let current_year = dayjs(fetchInfo.startStr).add(8, 'day').format('YYYY')
      // console.log(current_month," " , current_year);

      try {
        // if (admin) {
        //   response = await $authHost.get(
        //     `/calendar_entries/all/?start_date=${startDate}&end_date=${endDate}&${filterParams}`,
        //   );
        // } else {
        response = await $authHost.get(
          `/calendar_entries/me/?start_date=${startDate}&end_date=${endDate}&${filterParams}`,
        );
        // }
        // response_backs = await $authHost.get(`/report_cards/me/?month=${current_month}&year=${current_year}`);

        let myEvents = []; // Все ивенты
        const days = []; // Дни, в которых есть ивенты

        if (Array.isArray(response.data)) {
          // Создаем массив всех ивентов в нужном нам формате
          myEvents = response.data.map((e) => {
            let minutes = null;

            if (Array.isArray(e.time_entries) && e.time_entries.length > 0) {
              e.time_entries.forEach((t) => {
                minutes += t.duration / 60;
              });
            }

            return {
              title: e.name,
              start: dayjs(e.start_date).add(utcOffset, 'm').format(),
              end: dayjs(e.end_date).add(utcOffset, 'm').format(),
              timeStart: dayjs(e.start_date)
                .add(utcOffset, 'm')
                .format('HH:mm'),
              timeEnd: dayjs(e.end_date).add(utcOffset, 'm').format('HH:mm'),
              minutes: minutes,
              source: e.source,
              project: e.project_name,
              time_entries: e.time_entries,
              backgroundColor: 'rgba(255,255,255,0)',
              borderColor: 'rgba(255,255,255,0)'
            };
          });
        }

        // console.log(response_backs.data.calendar);

        // Добавляем в массив days дни, которые содержат ивенты
        myEvents.forEach((e) => {
          const start = dayjs(e.start).format('YYYY-MM-DD');
          const day = days.find((d) => d?.start === start);
          if (!day) {
            days.push({
              start: start,
              minutes_teams: null,
              minutes_clickup: null,
              events_teams: [],
              events_clickup: [],
              time_entries_teams: [],
              time_entries_clickup: [],
              type: 'time',
            });
          }
        });


        // Добавление ивентов и минут для каждого дня из массива days
        myEvents.forEach((e) => {
          const start = dayjs(e.start).format('YYYY-MM-DD');

          days.forEach((d) => {
            if (d.start === start) {
              if (e.source === 'Teams') {
                if (e.minutes) {
                  d.minutes_teams += e.minutes;
                  d.events_teams.push(e);
                  e.time_entries.forEach((t) => {
                    d.time_entries_teams.push({
                      ...t,
                      title: e.title,
                      start: dayjs(t.start).add(utcOffset, 'm').format('HH:mm'),
                      end: dayjs(t.end).add(utcOffset, 'm').format('HH:mm'),
                    });
                  });
                }
              }
            }

            if (e.source === 'ClickUp') {
              if (e.minutes) {
                e.time_entries.forEach((t) => {
                  if (dayjs(t.start).format('YYYY-MM-DD') === d.start) {
                    d.time_entries_clickup.push({
                      ...t,
                      title: e.title,
                      project: e.project,
                      start: dayjs(t.start).add(utcOffset, 'm').format('HH:mm'),
                      end: dayjs(t.end).add(utcOffset, 'm').format('HH:mm'),
                    });
                    d.minutes_clickup += t.duration / 60;
                    d.events_clickup.push(e);
                  }
                });
              }
            }
          });
        });

        successCallback([...myEvents, ...days]);
        setLoading(false);
      } catch (e) {
        failureCallback(e);
        setLoading(false);
      }
    },
    [filterParams],
  );


  return (
    <Box pt={2}>
      <Card>
        <Grid container spacing={2} sx={{padding: 2}}>
          <Grid item md={12}>
            <Box pt={2}>
              <ScheduleFilters setFilterValues={setFilterValues}/>
              <FullCalendar
                events={getEvents}
                eventContent={renderEventContent}
                eventClassNames={({event}) => {
                  if (event.extendedProps?.type === 'time') {
                    return ['fc-type-time-container'];
                  }
                }}
                plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
                initialView='dayGridMonth'
                headerToolbar={{
                  start: 'dayGridMonth,dayGridWeek',
                  center: 'title',
                  end: 'today prev,next',
                }}
                locale='ru'
                firstDay='1'
                dayMaxEventRows={true}
              />
            </Box>
          </Grid>
        </Grid>
        {loading && <LoadingIndicator/>}
      </Card>
    </Box>
  );
};

export default MySchedule;
