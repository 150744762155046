import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hook/useResponsive';
import { LoginForm } from '../sections/auth/login';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { StyledContent, StyledRoot } from "../theme/standarts_styles";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";


export default function Login() {

  const [authTypeOffice, setAuthTypeOffice] = React.useState(false);
  const { t, i18n } = useTranslation();
  //const mdUp = useResponsive('up', 'md');

  let current_token = localStorage.getItem("token");

  const {
    token,
  } = useSelector(state => state.authReducer)

  if (token) {
    return <Navigate to={'/'}/>
  }

  return (
    <>
      <StyledRoot>
        <Container maxWidth='sm'>
          <StyledContent>
            <Typography variant='h4' gutterBottom>
              {authTypeOffice ? 'Авторизация Office 365' : 'Авторизация'}
            </Typography>
            <LoginForm
              authTypeOffice={authTypeOffice}
              setAuthTypeOffice={setAuthTypeOffice}
            />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
