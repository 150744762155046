import {
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { $authHost } from "../../http";
import { useSelector } from "react-redux";
import { useActions } from "../../hook/useActions";
import SkeletonInput from "../../components/SkeletonInput";
import CircularProgress from "@mui/material/CircularProgress";
import BlockFormModal from "../../components/BlockFormModal";

const  DepartmentsForm = ({...props}) => {

  const { t } = useTranslation()

  const {
    register,
    control,
    watch,
    formState: {
      errors,
    },
  } = useFormContext()

  const {
    setManagers,
  } = useActions();

  const {
    managers,
  } = useSelector((state) => state.departmentsReducer);

  const [loading, setLoading] = useState(false);



  const loadData = async () => {
    setLoading(true);
    try {

      const response = await $authHost.get(`/users/all/`);
      const data = response.data.items;
      setManagers(data);
    } catch (e) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    loadData();
  }, []);



  return (
    <>
      <SkeletonInput
        loading={loading}
        height={60}
        sx={{pt:-2, m: 0}}
      >
        <CustomTextField
          name="name"
          label="Название"
          registerOptions={{
            required: t('main.another.requiredField'),
          }}
          sx={{ width: '100%', fontSize: '12px', mb: 3 }}
          size="small"
        />
      </SkeletonInput>
      <SkeletonInput
        loading={loading}
        height={60}
      >
        <Controller
          control={control}
          rules={{
            required: t('main.another.requiredField'),
          }}
          name='manager'
          render={({
                     field: { onChange, ...field },
                     fieldState: { error },
                   }) => {
            return (
              <Autocomplete
                {...field}
                options={managers}
                getOptionLabel={(option) => option?.display_name || '' }
                size='small'
                sx={{
                  mb: 2,
                }}
                renderInput={(params) => (
                  <TextField
                    label='Менеджер *'
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(e, value) => onChange(value)}
              />
            );
          }}
        />
      </SkeletonInput>

      {/*<div style={{ position: 'relative', marginBottom: '12px', width: '100%' }}>*/}
      {/*  <Autocomplete*/}
      {/*    value={selectedManager}*/}
      {/*    getOptionLabel={(option) =>*/}
      {/*      typeof option === 'string' ? option : option.display_name*/}
      {/*    }*/}
      {/*    filterOptions={(x) => x}*/}
      {/*    autoComplete*/}
      {/*    includeInputInList*/}
      {/*    filterSelectedOptions*/}
      {/*    size="small"*/}
      {/*    open={openAutocompleteUsers}*/}
      {/*    onOpen={() => {*/}
      {/*      setOpenAutocompleteUsers(true);*/}
      {/*    }}*/}
      {/*    onClose={() => {*/}
      {/*      setOpenAutocompleteUsers(false);*/}
      {/*    }}*/}
      {/*    name="manager"*/}
      {/*    id="manager"*/}
      {/*    options={managers}*/}
      {/*    sx={{ width: '100%', my: 1 }}*/}
      {/*    loading={loadingUsers}*/}
      {/*    noOptionsText='Нет доступных вариантов'*/}
      {/*    onChange={(event, newValue) => {*/}
      {/*      setManagers(newValue ? [newValue, ...managers] : managers);*/}
      {/*      setSelectedManager(newValue);*/}
      {/*      setValidationManager(false)*/}
      {/*      setValidationErrorManager(false)*/}
      {/*    }}*/}
      {/*    onInputChange={(event, newInputValue) => {*/}
      {/*      setInputValue(newInputValue);*/}
      {/*    }}*/}
      {/*    renderInput={(params) => (*/}
      {/*      <TextField*/}
      {/*        {...params}*/}
      {/*        label="Менеджер *"*/}
      {/*        variant="outlined"*/}
      {/*        size="small"*/}
      {/*        sx={{ width: '100%', fontSize: '12px', mb: 3, color: 'disabled'}}*/}
      {/*        error={validationErrorManager || validationManager}*/}
      {/*        InputProps={{*/}
      {/*          ...params.InputProps,*/}
      {/*          endAdornment: (*/}
      {/*            <React.Fragment>*/}
      {/*              {loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
      {/*              {params.InputProps.endAdornment}*/}
      {/*            </React.Fragment>*/}
      {/*          ),*/}
      {/*        }}*/}
      {/*        InputLabelProps={{*/}
      {/*          shrink: true,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <span*/}
      {/*    style={{*/}
      {/*      position: 'absolute',*/}
      {/*      bottom: '4px',*/}
      {/*      fontSize: '12px',*/}
      {/*      color: 'red',*/}
      {/*      left: 14,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    { validationManager && t('main.another.requiredField')}*/}
      {/*    { validationErrorManager && t('main.another.ErrorManager')}*/}
      {/*  </span>*/}
      {/*</div>*/}
    </>
  );
}

export default DepartmentsForm;
