import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import RemoteDynamicTabs from '../components/Tabs/RemoteDynamicTabs';

const Account = () => {
  const location = useLocation();

  const tabs = [
    { label: 'Профиль', path: 'general' },
    { label: 'Договорные отношения', path: 'contracts' },
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith('/')) {
      redirectPath += '/';
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Container sx={{ pb: 0 }}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={0}
      >
        <Typography variant='h4' gutterBottom>
          Профиль
        </Typography>
      </Stack>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default Account;