import { TitleStack } from '../theme/standarts_styles';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import LoadingSpinner from '../components/modals/loadingSpinner';
import React, { useState } from 'react';
import CustomTextField from '../components/TextFields/CustomTextField';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const ContractsNewPage = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedEmployee } = useSelector((state) => state.employeesReducer);
  const { employeeId } = useParams();

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
    control,
    setError,
    clearErrors,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      console.log(data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/org-structure/employees/edit/${employeeId}/contracts`);
  };

  return (
    <Container>
      <TitleStack>
        <Typography variant='h4'>
          Управление договорными отношениями для{' '}
          {selectedEmployee?.display_name}
        </Typography>
      </TitleStack>
      <Box sx={{ flexDirection: 'column', px: 0, py: 4 }}>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='start_date'
              control={control}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Дата начало'
                    control={control}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                      },
                    }}
                    sx={{
                      width: '100%',
                      mb: 3,
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name='end_date'
              control={control}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Дата конец'
                    control={control}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                      },
                    }}
                    sx={{
                      width: '100%',
                      mb: 3,
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              control={control}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
              name='type'
              render={({
                field: { onChange, ...field },
                fieldState: { error },
              }) => {
                return (
                  <Autocomplete
                    {...field}
                    options={[
                      'Core-full',
                      'Core-fix',
                      'Contractor',
                      'Associate',
                    ]}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Тип отношений'
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                    onChange={(e, value) => onChange(value)}
                  />
                );
              }}
            />
            <CustomTextField
              name='oklad'
              label='Оклад'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='stavka'
              label='Ставка'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='hours'
              label='Объем часов'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='budget1'
              label='Ежегодный бюджет на отпуск'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='budget2'
              label='Ежегодный бюджет на платные отгулы'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                <Button variant='contained' type='submit'>
                  Сохранить
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  sx={{ ml: 1 }}
                  onClick={handleBack}
                >
                  Назад
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='text'
                  color='primary'
                  sx={{ ml: 1 }}
                  onClick={() => reset()}
                >
                  Сбросить
                </Button>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ContractsNewPage;
