import Stack from '@mui/material/Stack';
import { Avatar, Button, Card, Grid, Typography } from "@mui/material";
import Container from '@mui/material/Container';
import React, { useCallback, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import RemoteDynamicTabs from '../components/Tabs/RemoteDynamicTabs';
import { useSelector } from 'react-redux';
import { TitleStack } from "../theme/standarts_styles";
import CustomViewField from "../components/CustomViewField";
import dayjs from "dayjs";
import { $authHost } from "../http";
import { useActions } from "../hook/useActions";

const EmployeePage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { employeeId } = useParams();

  const { selectedEmployee } = useSelector((state) => state.employeesReducer);

  const { roles } = useSelector(state => state.authReducer)

  const {
    setEmployees,
    setSelectedEmployee,
    setDataSelectedEmployee,
    setSelectedDepartment
  } = useActions();


  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get(`/users/${employeeId}/`);
        return response.data;
      };
      newChannelList().then((data) => {
        setDataSelectedEmployee(data);
      });
    } catch (e) {}
  }, []);

  const handleBack = () => {
    navigate('/org-structure/employees');
  };

  const handleEdit = () => {
    navigate(`/org-structure/employees/edit/${selectedEmployee.source_id}`);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack
        direction='row'
        alignItems='center'
        sx={{ height: '70px' }}
        justifyContent='space-between'
        mb={0}
      >
        <Typography variant='h4' gutterBottom>
          Управление {selectedEmployee?.display_name}
        </Typography>
      </TitleStack>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{m:5}}
        >
          <Grid items xs={4}>
            <Avatar
              id='img'
              src={selectedEmployee?.photo}
              alt='photoURL'
              sx={{ width: 250, height: 250, mb:4 }}
            />
            <Typography
              variant='subtitle1'
              sx={{
                mb: 2,
              }}
            >
              Орг. структура
            </Typography>
            <CustomViewField
              label='Отдел'
              text={selectedEmployee?.department?.name}
              loading={false}
            />
            <CustomViewField
              label='Менеджер'
              text={selectedEmployee?.manager?.display_name}
              loading={false}
            />
            <CustomViewField
              label='Должность'
              text={selectedEmployee?.occupation}
              loading={false}
            />
          </Grid>
          <Grid items xs={4}>
            <Typography
              variant='subtitle1'
              sx={{
                mb: 2,
              }}
            >
              Общие сведения
            </Typography>
            <CustomViewField
              label='Дата рождения'
              text={dayjs(selectedEmployee?.birth_date).format('DD.MM.YYYY')}
              loading={false}
            />
            <CustomViewField
              label='Email'
              text={selectedEmployee?.email}
              loading={false}
              copyButton={true}
            />
            <CustomViewField
              label='Телефон'
              text={selectedEmployee?.phone}
              loading={false}
              copyButton={true}
            />
            <CustomViewField
              label='Доп. каналы связи'
              text={selectedEmployee?.communication_channels}
              loading={false}
              copyButton={true}
            />
            <CustomViewField
              label='Часовой пояс'
              text={selectedEmployee?.time_zone}
              loading={false}
            />
            <CustomViewField
              label='Рабочий график'
              text={selectedEmployee?.work_schedule}
              loading={false}
            />
            <CustomViewField
              label='Страна'
              text={selectedEmployee?.country}
              loading={false}
            />
          </Grid>
          <Grid item xs={12} sx={{mt:3}}>
            {roles?.some(role => role === 'ERP_Admins') &&
              <Button
                variant='contained'
                onClick={handleEdit}
                sx={{ mr: 1 }}
              >
                Редактировать
              </Button>
            }
            <Button
              variant='text'
              color='primary'

              onClick={ handleBack}
            >
              Назад к списку
            </Button>
          </Grid>
        </Grid>

      </Card>
    </Container>
  );
};

export default EmployeePage;
