export default function Container(theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
          },
        },
      },
    },
  };
}
