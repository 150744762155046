import React from "react";
import { Box, Button, Dialog } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

const ConfirmDialog = ({ isActive, setDialogOpen, onConf, title='Есть несохраненные данные в форме' }) => {

  const onCancel = () => {
    setDialogOpen(false);
  };

  const onConfirm = () => {
    onConf();
  };

  return (
    <Dialog open={isActive} sx={{ zIndex: 9999 }}>
      <Box sx={{ p: 4 }}>
        <Typography variant='h5' sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Typography variant='body1' sx={{ mb: 2 }}>
          Вы действительно хотите выйти? Все несохраненные данные будут
          утеряны.
        </Typography>
        <Stack direction='row' spacing={1}>
          <Button variant='contained' onClick={onCancel}>
            Отмена
          </Button>
          <Button variant='text' onClick={onConfirm}>
            Выйти
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ConfirmDialog;