import {useEffect, useState} from 'react';
import {$host} from '../../../http';
import jwt_decode from 'jwt-decode';
import {Box} from "@mui/material";

const LoginSuccess = () => {
  const [status, setStatus] = useState('progress');

  useEffect(() => {
    const paramsString = document.location.search;
    const searchParams = new URLSearchParams(paramsString);

    const codeAuth = searchParams.get('code');
    const sessionState = searchParams.get('session_state');

    if (codeAuth && sessionState) {
      getToken(codeAuth, sessionState);
    }
  }, []);

  const getToken = async (codeAuth, sessionState) => {
    try {
      const response = await $host.get(
        `/auth/callback?code=${codeAuth}&session_state=${sessionState}`,
        {
          headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        },
      );

      const {
        data: {access_token, refresh_token, id_token},
        headers: {date},
      } = response;

      const decodedToken = jwt_decode(access_token);
      const tokenLifetime =
        decodedToken.exp * 1000 - (Date.parse(date) || Date.now());

      const decodedIdToken = jwt_decode(id_token);
      const idTokenLifetime =
        decodedIdToken.exp * 1000 - (Date.parse(date) || Date.now());

      const channel = new BroadcastChannel('login-data');
      channel.postMessage({
        token: access_token,
        refreshToken: refresh_token,
        tokenLifetime: tokenLifetime,
        id_token: id_token,
        idTokenLifetime: idTokenLifetime,
      });

      setStatus('success');

      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (e) {
      console.log(e);
      setStatus('error');
    }
  };

  if (status === 'success') {
    return (
      <Box p={2}>
        Авторизация прошла успешно. Вы будете перенаправлены на главную
        страницу.
      </Box>
    );
  }

  if (status === 'error') {
    return <Box p={2}>Произошла ошибка. Пожалуйста, попробуйте еще раз.</Box>;
  }

  return <Box p={2}>Идет авторизация. Пожалуйста, подождите...</Box>;
};

export default LoginSuccess;
