import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  contracts: [],
  selectedContract: null,
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    logOut() { return initialState },
    setContracts(state, { payload }) { state.contracts = payload },
    setSelectedContract(state, { payload }) {
      const index = state.contracts.findIndex(
        (contract) => contract.id === payload,
      );
      state.selectedContract = state.contracts[index];
    },
  },
});

export const { actions: contractsActions, reducer: contractsReducer } = contractsSlice;
export default contractsSlice.reducer;
