import Stack from '@mui/material/Stack';
import { Box, Button, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/modals/loadingSpinner';
import { useActions } from '../../hook/useActions';
import { useSelector } from 'react-redux';
import { $authHost } from '../../http';
import { useNavigate, useParams } from 'react-router-dom';
import CustomViewField from '../../components/CustomViewField';

const ClientsFormView = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const { updateFullSelectedClient } = useActions();

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const loadData = useCallback(() => {
    try {
      const newselectedClient = async () => {
        const response = await $authHost.get(`/clients/${clientId}/`);
        return response.data;
      };
      newselectedClient().then((data) => {
        updateFullSelectedClient(data);
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handleBack = () => {
    navigate('/clients');
  };

  const handleEdit = () => {
    navigate(`/clients/edit/${clientId}`);
  };

  return (
    <Box sx={{ flexDirection: 'column', px: 0, py: 4 }}>
      {loading && <LoadingSpinner />}
      <CustomViewField
        label='Наименование'
        text={selectedClient?.name}
        loading={loading}
      />
      <CustomViewField
        label='Полное наименование'
        text={selectedClient?.full_name}
        loading={loading}
      />
      <CustomViewField
        label='Код клиента'
        text={selectedClient?.code}
        loading={loading}
      />
      <CustomViewField
        label='Адрес'
        text={selectedClient?.address}
        loading={loading}
      />
      <CustomViewField
        label='Веб-сайт'
        text={selectedClient?.web_site}
        loading={loading}
        type='link'
        path={selectedClient?.web_site}
      />
      <CustomViewField
        label='Email'
        text={selectedClient?.email}
        loading={loading}
      />
      <CustomViewField
        label='Реквизиты'
        text={selectedClient?.details}
        loading={loading}
        copyButton={true}
      />
      <CustomViewField
        label='Страна'
        text={selectedClient?.country}
        loading={loading}
      />
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item>
          <Button variant='contained' onClick={handleBack}>
            Назад к списку
          </Button>
          <Button
            variant='text'
            color='primary'
            sx={{ ml: 1 }}
            onClick={handleEdit}
          >
            Редактировать
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ClientsFormView;
