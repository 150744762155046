import React, { useEffect, useState } from 'react';
import useIsOverflow from '../utils/useIsOverflow';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Expand, ExpandLess, ExpandMore } from '@mui/icons-material';

export const TableCellsWrap = ({ children }) => {
  const ref = React.useRef();
  const isOverflow = useIsOverflow(ref, true);
  const list = ref.current;
  const [showMore, setShowMore] = useState(false);

  function moreClick() {
    list?.classList.toggle('less');
    if (list?.classList.contains('less')) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }

  useEffect(() => {
    const list = ref.current;
  }, []);

  return (
    <>
      <p className='less' ref={ref}>
        {children}
      </p>
      {isOverflow && (
        <IconButton onClick={moreClick} size='small'>
          {showMore ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      )}
    </>
  );
};
