import i18n from 'i18next'

export const getLocalizedForDropdownValues = (dropdownValues, name) => {
	const array_obj = dropdownValues.map((dropdownValue, index) => {
		const { original_name } = dropdownValue
		const translationKey = `dropdown.${name}.${dropdownValue.original_name}`
		return {
			key: index,
			name: i18n.t(`${translationKey}`) !== translationKey ? i18n.t(`${translationKey}`) : dropdownValue,
			original_name,
		}
	})
	// console.log("aa", aaa);
	return array_obj;
}