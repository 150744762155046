import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  statistics: {}
};

const StatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setStatistics(state, {payload}) {
      state.statistics = payload;
    },
  },
});

export const {actions: statisticsActions, reducer: statisticsReducer} =
  StatisticsSlice;
export default StatisticsSlice.reducer;
