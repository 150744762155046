import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  timesheetsMonth: [],
  timesheetsQuarter: [{user: {}, calendar: {}}],
  timesheetsYear: [{user: {}, calendar: {}}],
};

const TimesheetsSlice = createSlice({
  name: 'timesheets',
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setTimesheetsMonth(state, {payload}) {
      state.timesheetsMonth = payload;
    },
    setTimesheetsQuarter(state, {payload}) {
      state.timesheetsQuarter = payload;
    },
    setTimesheetsYear(state, {payload}) {
      state.timesheetsYear = payload;
    },
  },
});

export const {actions: timesheetsActions, reducer: timesheetsReducer} =
  TimesheetsSlice;
export default TimesheetsSlice.reducer;
