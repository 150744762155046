import {TitleStack} from '../../theme/standarts_styles';
import {
  Autocomplete,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import {useNavigate} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, FormProvider, useForm, useWatch} from 'react-hook-form';
import {$authHost} from '../../http';
import LoadingSpinner from '../../components/modals/loadingSpinner';
import CustomTextField from '../../components/TextFields/CustomTextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import BlockFormModal from '../../components/BlockFormModal';
import {useSelector} from "react-redux";
import {useActions} from "../../hook/useActions";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {convertToNull} from "../../utils/convertToNull";

const UserContractAdd = () => {
  dayjs.extend(isSameOrBefore);

  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {users} = useSelector((state) => state.usersReducer);
  const {setUsers} = useActions();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {is_unlimited: false, start_date: null, end_date: null, termination_date: null}
  });

  const {
    handleSubmit,
    reset,
    setError,
    control,
    setValue,
    formState: {isDirty, isSubmitSuccessful},
  } = methods;

  const handleReset = () => {
    reset()
  };

  const onSubmit = async ({start_date, end_date, user, ...data}) => {
    setLoading(true);

    const newData = {
      ...data,
      start_date: dayjs(start_date).format('YYYY-MM-DDTHH:mm:ss'),
      end_date: dayjs(end_date).format('YYYY-MM-DDTHH:mm:ss'),
      user_id: user.source_id,
    }

    try {
      await $authHost.post(`/agreements/add/`, convertToNull(newData));
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/contracts');
    }
  }, [isSubmitSuccessful]);

  const handleBack = () => {
    navigate(`/contracts`);
  };

  const unlimited = useWatch({control, name: 'is_unlimited'});
  const capacity = useWatch({control, name: 'interaction_capacity'});
  const startDate = useWatch({control, name: 'start_date'});
  const endDate = useWatch({control, name: 'end_date'});
  const status = useWatch({control, name: 'status'});

  const loadData = () => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(`/users/all/`);
        setUsers(response.data.items);
        setLoading(false);
      };
      getData();
    } catch (e) {
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const startDateIsBefore = dayjs(startDate).isSameOrBefore(endDate, 'day');

    if (!startDateIsBefore) {
      setValue('end_date', startDate);
    }
  }, [startDate]);

  return (
    <Container>
      <TitleStack>
        <Typography variant='h4'>Новый договор</Typography>
      </TitleStack>
      <Card sx={{my: 1, p: 3}}>
        {loading && <LoadingSpinner/>}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='user'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={users}
                    getOptionLabel={(option) => option.display_name}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Сотрудник *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                    // onInputChange={(_, data) => {
                    //   if (data) field.onChange(data);
                    // }}
                  />
                );
              }}
            />
            <CustomTextField
              name='date_and_number'
              label='Номер договора'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{width: '100%', mb: 3}}
              size='small'
            />
            <Controller
              name='start_date'
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              render={({
                         field: {onChange, value},
                         fieldState: {error},
                       }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Дата заключения *'
                    control={control}
                    value={dayjs(value)}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    format='DD.MM.YYYY'
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: 'small',
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                    sx={{
                      width: '100%',
                      mb: 3,
                    }}
                  />
                </LocalizationProvider>
              )}
            />
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name='is_unlimited'
                  render={({field: {value, ...field}}) => {
                    return (
                      <Switch checked={value} color='primary' {...field} />
                    );
                  }}
                />
              }
              label='Бессрочный'
              labelPlacement='start'
              sx={{ml: 0, mb: 2}}
            />
            {!unlimited && (
              <Controller
                name='end_date'
                control={control}
                rules={{
                  required: t('main.another.requiredField'),
                }}
                render={({
                           field: {onChange, value},
                           fieldState: {error},
                         }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Дата окончания *'
                      control={control}
                      value={dayjs(value)}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      minDate={startDate}
                      format='DD.MM.YYYY'
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                      sx={{
                        width: '100%',
                        mb: 3,
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            )}
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='status'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={['На согласовании', 'Действующий', 'Расторгнут', 'Исполнен']}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Статус договора *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                  />
                );
              }}
            />
            {status === 'Расторгнут' && (
              <Controller
                name='termination_date'
                control={control}
                rules={{
                  required: t('main.another.requiredField'),
                }}
                render={({
                           field: {onChange, value},
                           fieldState: {error},
                         }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label='Дата прекращения *'
                      control={control}
                      value={dayjs(value)}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      format='DD.MM.YYYY'
                      slotProps={{
                        textField: {
                          error: !!error,
                          helperText: error?.message,
                          size: 'small',
                          InputLabelProps: {
                            shrink: true,
                          },
                        },
                      }}
                      sx={{
                        width: '100%',
                        mb: 3,
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            )}
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='type'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={['Трудоустройство', 'Самозанятость', 'ИП']}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Тип договора *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                  />
                );
              }}
            />
            <CustomTextField
              name='legal_entity'
              label='Юридическое лицо'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{width: '100%', mb: 3}}
              size='small'
            />
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='production_calendar'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={['Россия', 'Казахстан']}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Производственный календарь *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                  />
                );
              }}
            />
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='loyalty_program'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={['Core', 'Assotiate', 'Contractor']}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Программа лояльности *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                  />
                );
              }}
            />
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='interaction_capacity'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={['Fulltime', 'Fix']}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Вид занятости *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                  />
                );
              }}
            />
            {capacity === 'Fix' && (
              <>
                <CustomTextField
                  name='hour_capacity'
                  label='Объем часов Fix'
                  registerOptions={{
                    required: t('main.another.requiredField'),
                    pattern: {
                      value: /^\d*$/,
                      message: t('main.another.wrongInput'),
                    },
                  }}
                  sx={{width: '100%', mb: 3}}
                  size='small'
                />
                <CustomTextField
                  name='hour_rate'
                  label='Ставка в час'
                  registerOptions={{
                    required: t('main.another.requiredField'),
                    pattern: {
                      value: /^\d*$/,
                      message: t('main.another.wrongInput'),
                    },
                  }}
                  sx={{width: '100%', mb: 3}}
                  size='small'
                />
              </>
            )}
            {capacity === 'Fulltime' && (
              <CustomTextField
                name='month_rate'
                label='Ставка в месяц'
                registerOptions={{
                  required: t('main.another.requiredField'),
                  pattern: {
                    value: /^\d*$/,
                    message: t('main.another.wrongInput'),
                  },
                }}
                sx={{width: '100%', mb: 3}}
                size='small'
              />
            )}
            <Controller
              control={control}
              rules={{
                required: t('main.another.requiredField'),
              }}
              name='currency'
              render={({field: {ref, ...field}, fieldState: {error}}) => {
                return (
                  <Autocomplete
                    {...field}
                    options={['RUB', 'KZT']}
                    getOptionLabel={(option) => option}
                    size='small'
                    sx={{
                      mb: 3,
                    }}
                    renderInput={(params) => (
                      <TextField
                        label='Валюта договора *'
                        {...params}
                        inputRef={ref}
                        error={!!error}
                        helperText={error?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(e, value) => field.onChange(value)}
                  />
                );
              }}
            />
            <CustomTextField
              name='vacation_days'
              label='Отпуск в год, дней'
              registerOptions={{
                required: t('main.another.requiredField'),
                pattern: {
                  value: /^\d*$/,
                  message: t('main.another.wrongInput'),
                },
              }}
              sx={{width: '100%', mb: 3}}
              size='small'
            />
            <CustomTextField
              name='sick_days'
              label='Больничные в год, дней'
              registerOptions={{
                required: t('main.another.requiredField'),
                pattern: {
                  value: /^\d*$/,
                  message: t('main.another.wrongInput'),
                },
              }}
              sx={{width: '100%', mb: 3}}
              size='small'
            />
            <Stack direction='row' justifyContent='space-between'>
              <Grid item>
                <Button variant='contained' type='submit'>
                  Сохранить
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  sx={{ml: 1}}
                  onClick={handleBack}
                >
                  Назад к списку
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='text'
                  color='primary'
                  sx={{ml: 1}}
                  onClick={handleReset}
                >
                  Сбросить
                </Button>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </Card>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>
    </Container>
  );
};

export default UserContractAdd;
