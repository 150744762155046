import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Grid, IconButton, Link, ListItemText, Typography } from '@mui/material'
import useResponsive from '../hook/useResponsive'
import MenuIcon from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux'
import { StyledChip, StyledNavItem } from '../theme/standarts_styles'
import { darkLogo, lightLogo, NAV_WIDTH, NAV_WIDTH_CLOSE } from '../constants'
import { useActions } from '../hook/useActions'

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const { isInitAuth } = useSelector(state => state.authReducer)
  const isDesktop = useResponsive('up', 'lg')
  // const { isDarkTheme } = useSelector(state => state.serviceReducer)
  const isDarkTheme = useSelector(state => state.serviceReducer)
  const srcLogo = isDarkTheme ? lightLogo : darkLogo
  // const srcLogo = isDarkTheme ? 'ASPEX DG' : 'Dark ASPEX DG'
  const { openNav } = useSelector(state => state.serviceReducer)
  const {
    toggleOpenNav,
  } = useActions()

  const isMobile = useResponsive('down', 'sm')

  const MenuIconButton = ({ onClick }) => (
    <IconButton onClick={onClick} m={0}>
      <MenuIcon
        size="small"
        sx={{
          color: 'layout.element',
        }}
      />
    </IconButton>
  )

  const LogoBox = ({ src, sx }) => (
    <Link href="/">
      {/*<Box*/}
      {/*  component="img"*/}
      {/*  src={src}*/}
      {/*  sx={{*/}
      {/*    height: 20,*/}
      {/*    ml: 1,*/}
      {/*    cursor: 'pointer',*/}
      {/*    ...sx,*/}
      {/*  }}*/}
      {/*/>*/}
      <Box
        component="img"
        src={src}
        sx={{
          height: 20,
          ml: 1,
          cursor: 'pointer',
          ...sx,
        }}
      >
      </Box>
    </Link>
  )

  return (
    <>
      {
        isInitAuth === true ?
          <Box
            sx={{
              p: 0,
              width: NAV_WIDTH,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                p: 1,
                width: isMobile ? NAV_WIDTH_CLOSE : NAV_WIDTH,
              }}
            >
              {openNav ?
                <>
                  <MenuIconButton onClick={() => toggleOpenNav()}/>
                  <LogoBox src={srcLogo} sx={sx}/>
                </>
                :
                <>
                  {/*<Grid*/}
                  {/*  item*/}
                  {/*  direction="row"*/}
                  {/*  justifyContent="center"*/}
                  {/*  alignItems="center"*/}
                  {/*  sx={{*/}
                  {/*    width: NAV_WIDTH_CLOSE,*/}
                  {/*    p: 1,*/}
                  {/*  }}*/}
                  {/*>*/}
                    <MenuIconButton onClick={() => toggleOpenNav()}/>
                  {/*</Grid>*/}
                  {!isMobile && <LogoBox src={srcLogo} sx={sx}/>}
                </>
              }
            </Grid>
          </Box>
          :
          <Box sx={{ p: 1, width: NAV_WIDTH }}>
            <LogoBox src={srcLogo} sx={sx}/>
          </Box>
      }
    </>
  )
})

export default Logo
