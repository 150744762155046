import {projectsActions} from './slice/projects.slice';
import {authActions} from './slice/auth.slice';
import {clientsActions} from './slice/clients.slice';
import {departmentsActions} from './slice/departments.slice';
import {employeesActions} from './slice/employees.slice';
import {budgetsActions} from './slice/budgets.slice';
import {scheduleActions} from './slice/schedule.slice';
import {planningActions} from './slice/planning.slice';
import {contactsActions} from "./slice/contacts.slice";
import {calendarActions} from "./slice/calendar.slice";
import {serviceActions} from './slice/service.slice'
import {timesheetsActions} from "./slice/timesheets.slice";
import {tableStatesActions} from "./slice/tableStates.slice";
import {contractsActions} from "./slice/contracts.slice";
import {usersActions} from "./slice/users.slice";
import {statisticsActions} from "./slice/statistics.slice";

export const rootActions = {
  ...projectsActions,
  ...employeesActions,
  ...clientsActions,
  ...contactsActions,
  ...budgetsActions,
  ...departmentsActions,
  ...authActions,
  ...scheduleActions,
  ...planningActions,
  ...calendarActions,
  ...serviceActions,
  ...timesheetsActions,
  ...tableStatesActions,
  ...contractsActions,
  ...usersActions,
  ...statisticsActions,
  logOut: () => (dispatch) => {
    dispatch(authActions.logOut());
    dispatch(projectsActions.logOut());
    dispatch(clientsActions.logOut());
    dispatch(contactsActions.logOut());
    dispatch(departmentsActions.logOut());
    dispatch(employeesActions.logOut());
    dispatch(budgetsActions.logOut());
    dispatch(scheduleActions.logOut());
    dispatch(planningActions.logOut());
    dispatch(calendarActions.logOut());
    dispatch(timesheetsActions.logOut());
    dispatch(tableStatesActions.logOut());
    dispatch(contractsActions.logOut());
    dispatch(usersActions.logOut());
    dispatch(statisticsActions.logOut());
    dispatch(serviceActions.resetAllAlertMessage())
  },
};
