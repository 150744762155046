import { Checkbox, FormControlLabel, IconButton, Switch } from '@mui/material';
import { useEffect, memo } from 'react';
import {
  DropdownMenuItem,
  DropdownNestedMenu,
  DropdownNestedMenuItem,
} from '../NestedMenu/DropdownNestedMenu';
import ArrowRight from '@mui/icons-material/ArrowRight';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const ScheduleFilters = memo(({ setFilterValues }) => {
  const statuses = [
    {
      label: 'Бэклог',
      name: 'backlog',
    },
    {
      label: 'Бэклог спринта',
      name: 'sprint_backlog',
    },
    {
      label: 'В работе',
      name: 'in_progress',
    },
    {
      label: 'На согласованиии',
      name: 'on_approval',
    },
    // {
    //   label: 'Просрочено',
    //   name: 'overdue',
    // },
    {
      label: 'Требуется уточнение',
      name: 'pending',
    },
    {
      label: 'Отменено',
      name: 'canceled',
    },
    {
      label: 'Отложено',
      name: 'deferred',
    },
    {
      label: 'Выполнено',
      name: 'done',
    },
  ];

  const projects = [
    {
      label: 'AX_ERP',
      name: 'AX_ERP',
    },
    {
      label: 'AX_Regl',
      name: 'AX_Regl',
    },
    {
      label: 'LST_KZN',
      name: 'LST_KZN',
    },
    {
      label: 'UMI_Fin',
      name: 'UMI_Fin',
    },
    {
      label: 'ZPI_Exel',
      name: 'ZPI_Exel',
    },
  ];

  const types = [
    {
      label: 'Производство работ',
      name: 'work_execution',
    },
    {
      label: 'Обсуждение работ',
      name: 'work_discussion',
    },
    {
      label: 'Общие созвоны',
      name: 'general_calls',
    },
  ];

  const methods = useForm({
    defaultValues: { sources: { Teams: true, ClickUp: true } },
  });

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue
  } = methods;

  const data = watch();

  const clickup = useWatch({ control, name: 'sources.ClickUp' });
  const teams = useWatch({ control, name: 'sources.Teams' });
  const showClosed = useWatch({ control, name: 'show_closed' });

  useEffect(() => {
    const { sources } = data;
    // setFilterValues(clickup ? data : sources);
    // setFilterValues(teams ? data : sources);
    setFilterValues(data);
  }, [data]);

  useEffect(() => {
    if (showClosed) {
      setValue('statuses.canceled', true);
      setValue('statuses.deferred', true);
      setValue('statuses.done', true);
    } else {
      setValue('statuses.canceled', false);
      setValue('statuses.deferred', false);
      setValue('statuses.done', false);
    }
  }, [showClosed]);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DropdownNestedMenu
          trigger={
            <IconButton size='large' color='inherit' sx={{ mb: 1 }}>
              <FilterAltIcon />
            </IconButton>
          }
          menu={[
            <DropdownMenuItem>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name='sources.Teams'
                    render={({ field: { value, ...field } }) => {
                      return (
                        <Switch checked={value} color='primary' {...field} />
                      );
                    }}
                  />
                }
                label='События Teams'
                labelPlacement='start'
                sx={{ m: 0 }}
              />
            </DropdownMenuItem>,
            <DropdownMenuItem>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name='sources.ClickUp'
                    render={({ field: { value, ...field } }) => {
                      return (
                        <Switch checked={value} color='primary' {...field} />
                      );
                    }}
                  />
                }
                label='Задачи ClickUp'
                labelPlacement='start'
                sx={{ m: 0 }}
              />
            </DropdownMenuItem>,
            <DropdownMenuItem disabled={!clickup}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name='show_closed'
                    render={({ field: { value, ...field } }) => {
                      return (
                        <Switch checked={value} color='primary' {...field} />
                      );
                    }}
                  />
                }
                label='Закрытые задачи'
                labelPlacement='start'
                sx={{ m: 0 }}
              />
            </DropdownMenuItem>,
            <DropdownNestedMenuItem
              label='Статус'
              rightIcon={<ArrowRight />}
              sx={{ py: 1 }}
              disabled={!clickup}
              menu={statuses.map((status) => (
                <DropdownMenuItem sx={{ py: 0 }}>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name={`statuses.${status.name}`}
                        render={({ field: { value, ...field } }) => {
                          return <Checkbox checked={value} {...field} />;
                        }}
                      />
                    }
                    label={status.label}
                    labelPlacement='end'
                    sx={{ m: 0 }}
                  />
                </DropdownMenuItem>
              ))}
            />,
            // <DropdownNestedMenuItem
            //   label='Проект'
            //   rightIcon={<ArrowRight />}
            //   sx={{ py: 1 }}
            //   disabled={!clickup}
            //   menu={projects.map((project) => (
            //     <DropdownMenuItem sx={{ py: 0 }}>
            //       <FormControlLabel
            //         control={
            //           <Controller
            //             control={control}
            //             name={`projects.${project.name}`}
            //             render={({ field: { value, ...field } }) => {
            //               return <Checkbox checked={value} {...field} />;
            //             }}
            //           />
            //         }
            //         label={project.label}
            //         labelPlacement='end'
            //         sx={{ m: 0 }}
            //       />
            //     </DropdownMenuItem>
            //   ))}
            // />,
            // <DropdownNestedMenuItem
            //   label='Задачи'
            //   rightIcon={<ArrowRight />}
            //   sx={{ py: 1 }}
            //   disabled={!clickup}
            //   menu={types.map((type) => (
            //     <DropdownMenuItem sx={{ py: 0 }}>
            //       <FormControlLabel
            //         control={
            //           <Controller
            //             control={control}
            //             name={`types.${type.name}`}
            //             render={({ field: { value, ...field } }) => {
            //               return <Checkbox checked={value} {...field} />;
            //             }}
            //           />
            //         }
            //         label={type.label}
            //         labelPlacement='end'
            //         sx={{ m: 0 }}
            //       />
            //     </DropdownMenuItem>
            //   ))}
            // />,
            <DropdownMenuItem>
              <Stack justifyContent='center' sx={{ width: '100%' }}>
                <Button onClick={() => reset()} variant='outlined'>
                  Сбросить
                </Button>
              </Stack>
            </DropdownMenuItem>,
          ]}
        />
      </form>
    </>
  );
});

export default ScheduleFilters;
