import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, IconButton, Tooltip } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { TableCellsWrap } from '../TableCellsWrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import MaterialTable from '../MaterialTable';
import Iconify from '../iconify';
import { $authHost } from '../../http';
import { useActions } from '../../hook/useActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BudgetsAdd from './BudgetsAdd';
import BudgetsEdit from './BudgetsEdit';
import dayjs from 'dayjs';
import BlockFormModal from "../BlockFormModal";

const Budgets = () => {
  const { t, i18n } = useTranslation();

  const { setBudgets } = useActions();

  const { budgets } = useSelector((state) => state.budgetsReducer);

  const { selectedProject } = useSelector((state) => state.projectsReducer);

  const [budgetId, setBudgetId] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const tableColumns = [
    {
      id: 'date',
      header: 'Дата',
      accessorFn: (row) => dayjs(row.date).format('DD.MM.YYYY') || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'name',
      header: 'Имя бюджета',
      accessorFn: (row) => row.name || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'type',
      header: 'Вид',
      accessorFn: (row) => row.type || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'justification',
      header: 'Обоснование',
      accessorFn: (row) => row.justification || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'currency',
      header: 'Валюта',
      accessorFn: (row) => row.currency || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'currency_amount',
      header: 'Сумма бюджета в валюте',
      accessorFn: (row) => row.currency_amount || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'hours_amount',
      header: 'Сумма бюджета в часах',
      accessorFn: (row) => row.hours_amount || '',
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title='Редактировать'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => handleEditRow(row.original.id)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Удалить'>
              <IconButton
                size='large'
                color='inherit'
                onClick={() => setConfirmationId(row.original.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    setOpenEditForm(false);
    setOpenAddForm(true);
  };

  function handleEditRow(id) {
    setBudgetId(id);
    setOpenAddForm(false);
    setOpenEditForm(true);
  }

  async function handleDeleteRow(id_row) {
    setLoading(true);
    try {
      await $authHost.delete(`/budgets/${id_row}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenAddForm(false);
    setOpenEditForm(false);
  }

  const loadData = useCallback(() => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(
          `/budgets/all/?project_id=${selectedProject.id}`,
        );
        setBudgets(response.data.items);
        setLoading(false);
      };
      getData();
    } catch (e) {}
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Stack
        direction='row'
        sx={{ height: '70px' }}
        alignItems='center'
        justifyContent='start'
        mb={0}
      >
        <Button
          aria-haspopup='true'
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill' />}
          sx={{ mr: 0 }}
          onClick={handleAdd}
        >
          Добавить бюджет
        </Button>
      </Stack>
      {/*<AlertMessage/>*/}
      {openAddForm && (
        <BudgetsAdd handleClose={handleClose} loadDataBudgets={loadData} />
      )}
      {openEditForm && (
        <BudgetsEdit
          handleClose={handleClose}
          loadDataBudgets={loadData}
          budgetId={budgetId}
        />
      )}
      <Card sx={{ pt: 1 }}>
        <MaterialTable
          data={budgets}
          columns={tableColumns}
          loading={loading}
        />
      </Card>
      <BlockFormModal when={!!confirmationId} title='Вы не завершили действие внутри таблицы' />
    </>
  );
};

export default Budgets;
