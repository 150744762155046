import PropTypes from 'prop-types';
import {useContext, useEffect, useMemo} from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import darkPalette from './darkTheme/darkPalette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import {useSelector} from "react-redux";
import darkShadows from "./darkTheme/darkShadows";
import darkCustomShadows from "./darkTheme/darkCustomShadows";


// ----------------------------------------------------------------------


export default function ThemeProvider({ children }) {

  const { isDarkTheme } = useSelector(state => state.serviceReducer)
  let theme;

  const lightThemeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );
  const darkThemeOptions = useMemo(
    () => ({
      palette: darkPalette,
      shape: { borderRadius: 6 },
      typography,
      shadows: darkShadows(),
      customShadows: darkCustomShadows(),
    }),
    []
  );

  const lightTheme = createTheme(lightThemeOptions);
  lightTheme.components = componentsOverride(lightTheme);

  const darkTheme = createTheme(darkThemeOptions);
  darkTheme.components = componentsOverride(darkTheme);

  switch (isDarkTheme) {
    case true:
      theme = darkTheme;
      break;
    case false:
      theme = lightTheme;
      break;
    default:
      theme = lightTheme;
  }


  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
