import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  departments: [],
  selectedDepartment: null,
  selectedManagerId: null,
  selectedManager: null,
  managers:[],
  validationErrorManager: false,
  validationManager: false,

};

const DepartmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    logOut() { return initialState },
    setDepartments(state, { payload }) { state.departments = payload },
    setSelectedManagerId(state, { payload }) { state.selectedManagerId = payload },
    setSelectedDepartment(state, { payload }) {
      state.selectedDepartment = payload;
    },
    setDataSelectedDepartment(state, { payload }) {
      state.selectedDepartment = payload;
    },
    setManagers(state, { payload }) {
      state.managers = payload;
    },
    setSelectedManager(state, { payload }) {
      state.selectedManager = payload;
    },
    setValidationErrorManager(state, { payload }) {
      state.validationErrorManager = payload;
    },
    setValidationManager(state, { payload }) {
      state.validationManager = payload;
    },

  },
});

export const { actions: departmentsActions, reducer: departmentsReducer } = DepartmentsSlice
export default DepartmentsSlice.reducer;
