import React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from 'react-modal';
import '../../index.css';
import {useAuth} from '../../hook/useAuth';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {$authHost} from '../../http';
import {useActions} from '../../hook/useActions';
import {useSelector} from 'react-redux';
import Stack from '@mui/material/Stack';
import jwt_decode from 'jwt-decode';

const TimeoutWarning = ({isOpen, onRequestClose}) => {
  const {setStartTime, setToken, setRefreshToken, setTokenLifetime, setIdToken, setIdTokenLifetime} =
    useActions();
  const {refreshToken} = useSelector((state) => state.authReducer);

  const {t} = useTranslation();
  const {signOut} = useAuth();

  const handleRefreshToken = async () => {
    try {
      const response = await $authHost.post(`/auth/refresh`, {
        refresh_token: refreshToken,
      });
      const {
        data: {access_token, refresh_token, id_token},
        headers: {date},
      } = response;

      const utcTime = new Date(date);
      const localTimeMilliseconds = utcTime.getTime();

      const decodedToken = jwt_decode(access_token);
      const tokenLifetime =
        decodedToken.exp * 1000 - (Date.parse(date) || Date.now());

      const decodedIdToken = jwt_decode(id_token);
      const idTokenLifetime =
        decodedIdToken.exp * 1000 - (Date.parse(date) || Date.now());

      setStartTime(localTimeMilliseconds);
      setToken(access_token);
      setRefreshToken(refresh_token);
      setTokenLifetime(tokenLifetime);
      setIdToken(id_token);
      setIdTokenLifetime(idTokenLifetime);
      onRequestClose();
    } catch (e) {
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '550px',
      width: '100%',
      padding: '30px',
      fontSize: '16px',
      backgroundColor: '#ffffff',
      border: 'none',
    },
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0,0,0,0.6)',
      display: 'flex',
      zIndex: '10000',
    },
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles}>
        <Typography variant='h5' sx={{mb: 2}}>
          Время сессии подходит к концу
        </Typography>
        <Typography variant='body1'>
          Выход произойдет автоматически менее чем через 10 минут.
        </Typography>
        <Typography variant='body1'>
          Советуем переавторизоваться во избежании потери данных.
        </Typography>
        <br/>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={0}
        >
          <Button variant='text' onClick={() => signOut()}>
            Выйти сейчас
          </Button>
          <div>
            <Button
              variant='contained'
              sx={{marginLeft: 'auto', mx: 2}}
              onClick={handleRefreshToken}
            >
              Продлить сессию
            </Button>
            <Button variant='text' onClick={onRequestClose}>
              Понятно
            </Button>
          </div>
        </Stack>
      </Modal>
    </div>
  );
};

export default React.memo(TimeoutWarning);
