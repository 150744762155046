import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomTextField from '../../TextFields/CustomTextField'
import CustomMultiTextFieldAutocompleteForObject from '../../TextFields/CustomMultiTextFieldAutocompleteForObject'
import CustomViewField from '../../CustomViewField'
import dayjs from 'dayjs'
import CustomMultiAutocompleteForObjectLocalized from '../../TextFields/CustomMultiAutocompleteForObjectLocalized'
import { getLocalizedForDropdownValues } from '../../../utils/getLocalizedForDropdownValues'

const LeaveApplicationTopFields = ({ statusOptions, loading, selectedClient }) => {

	const { t } = useTranslation()

	return (
		<>
			<CustomViewField
				label="Отправитель"
				text={
					selectedClient?.user?.display_name
					|| selectedClient && `${selectedClient?.user?.first_name} ${selectedClient?.user?.last_name}`
					|| null
				}
				loading={loading}
			/>
			<CustomViewField
				label="Дата создания"
				text={dayjs(selectedClient.created_at).format('DD.MM.YYYY') || ''}
				loading={loading}
			/>
			<CustomViewField
				label="Отдел"
				text={selectedClient?.department?.name || null}
				loading={loading}
			/>
			<CustomMultiAutocompleteForObjectLocalized
				// multiple={true}
				name="status"
				// label={t('src.components.forms.formFields.LeaveApplicationTopFields.groupName', 'Тип заявления')}
				label="Статус"
				options={getLocalizedForDropdownValues(statusOptions, 'status')}
				rules={{
					required: t('main.another.requiredField'),
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
				onChangeValue={(e)=>{}}
			/>
			<CustomTextField
				name="comment"
				// label={t('src.components.forms.formFields.LeaveApplicationTopFields.groupName', 'Тип заявления')}
				label="Комментарий"
				registerOptions={{
					required: t('main.another.requiredField'),
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
				multiline={true}
				rows={3}
			/>
		</>
	)
}

export default LeaveApplicationTopFields