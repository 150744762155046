import CustomViewField from '../CustomViewField';
import {Button, Card} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import React from "react";

const MyContractView = () => {
  const navigate = useNavigate();
  const {selectedMyContract} = useSelector((state) => state.authReducer);

  const handleBack = () => {
    navigate('/account/contracts');
  };

  return (
    <Card sx={{mx: 3, my: 1, p: 3}}>
      <CustomViewField label='Дата заключения' text={dayjs(selectedMyContract.start_date).format('DD.MM.YYYY')}/>
      <CustomViewField label='Дата окончания' text={
        selectedMyContract.is_unlimited ? 'Бессрочный' : dayjs(selectedMyContract.end_date).format('DD.MM.YYYY')
      }/>
      <CustomViewField
        label='Статус договора'
        text={selectedMyContract.status}
      />
      <CustomViewField
        label='Тип договора'
        text={selectedMyContract.type}
      />
      <CustomViewField label='Юр. лицо' text={selectedMyContract.legal_entity}/>
      <CustomViewField
        label='Производственный календарь'
        text={selectedMyContract.production_calendar}
      />
      <CustomViewField
        label='Программа лояльности'
        text={selectedMyContract.loyalty_program}
      />
      <CustomViewField
        label='Вид занятости'
        text={selectedMyContract.interaction_capacity}
      />
      {selectedMyContract.interaction_capacity === 'Fix' && (
        <>
          <CustomViewField label='Объем часов Fix' text={selectedMyContract.hour_capacity}/>
          <CustomViewField label='Ставка в час' text={selectedMyContract.hour_rate}/>
        </>
      )}
      {selectedMyContract.interaction_capacity === 'Fulltime' && (
        <CustomViewField label='Ставка в месяц' text={selectedMyContract.month_rate}/>
      )}
      <CustomViewField
        label='Валюта договора'
        text={selectedMyContract.currency}
      />
      <CustomViewField
        label='Отпуск в год, дней'
        text={selectedMyContract.vacation_days}
      />
      <CustomViewField
        label='Больничные в год, дней'
        text={selectedMyContract.sick_days}
      />
      <Button variant='contained' onClick={handleBack}>
        Назад к списку
      </Button>
    </Card>
  );
};

export default MyContractView;
