import {useTranslation} from 'react-i18next';
import Container from '@mui/material/Container';
import {
  Button,
  Card, FormControlLabel, IconButton, Stack, Switch, Tooltip,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ButtonStack, TitleStack} from '../../theme/standarts_styles';
import Iconify from '../../components/iconify';
import {TableCellsWrap} from '../../components/TableCellsWrap';
import {useActions} from "../../hook/useActions";
import {useSelector} from "react-redux";
import {$authHost} from "../../http";
import dayjs from "dayjs";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import EditIcon from "@mui/icons-material/Edit";

const Contracts = () => {
  const tableId = 'contracts';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {setContracts, setSelectedContract} = useActions();
  const {contracts} = useSelector((state) => state.contractsReducer);
  const {roles} = useSelector(state => state.authReducer);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const [showExpired, setShowExpired] = useState(false);

  const tableColumns = [
    {
      accessorKey: 'user',
      header: 'Сотрудник',
      accessorFn: (row) => row.user?.display_name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'date_and_number',
      header: 'Номер договора',
      accessorFn: (row) => row.date_and_number || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'start_date',
      header: 'Дата заключения',
      accessorFn: (row) => dayjs(row.start_date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'end_date',
      header: 'Дата окончания',
      accessorFn: (row) => {
        if (row.is_unlimited) {
          return 'Бессрочный';
        } else {
          return dayjs(row.end_date).format('DD.MM.YYYY') || '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Статус договора',
      accessorFn: (row) => row.status || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Тип договора',
      accessorFn: (row) => row.type || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'legal_entity',
      header: 'Юр. лицо',
      accessorFn: (row) => row.legal_entity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'production_calendar',
      header: 'Производственный календарь',
      accessorFn: (row) => row.production_calendar || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'loyalty_program',
      header: 'Программа лояльности',
      accessorFn: (row) => row.loyalty_program || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'interaction_capacity',
      header: 'Вид занятости',
      accessorFn: (row) => row.interaction_capacity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'hour_capacity',
      header: 'Объем часов Fix',
      accessorFn: (row) => {
        if (row.interaction_capacity === 'Fix' && row.hour_capacity) {
          return row.hour_capacity;
        } else {
          return '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'hour_rate',
      header: 'Ставка в час',
      accessorFn: (row) => {
        if (row.interaction_capacity === 'Fix' && row.hour_rate) {
          return row.hour_rate;
        } else {
          return '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'month_rate',
      header: 'Ставка в месяц',
      accessorFn: (row) => {
        if (row.interaction_capacity === 'Fulltime' && row.month_rate) {
          return row.month_rate;
        } else {
          return '';
        }
      },
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'currency',
      header: 'Валюта договора',
      accessorFn: (row) => row.currency || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'vacation_days',
      header: 'Отпуск в год, дней',
      accessorFn: (row) => row.vacation_days || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'sick_days',
      header: 'Больничный в год, дней',
      accessorFn: (row) => row.sick_days || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 1,
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      muiTableBodyCellProps: {
        onClick: (e) => {
        },
        sx: {
          cursor: 'auto',
        },
      },
      Cell: ({renderedCellValue, row}) => {
        return (
          <Tooltip title='Редактировать'>
            <IconButton
              size='large'
              color='inherit'
              onClick={() => handleEditRow(row.original.id)}
            >
              <EditIcon/>
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleEditRow = (id) => {
    navigate(`/contracts/${id}/edit`);
    setSelectedContract(id);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/contracts/${id}`);
      setSelectedContract(id);
    }
  };

  const handleAdd = () => {
    navigate(`/contracts/new`);
  };

  const loadData = () => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(
          `/agreements/all/?search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}${showExpired ? '' : '&status=Действующий'}`,
        );
        setRowCount(response.data?.total);
        setPageCount(response.data?.pages);
        setContracts(response.data.items);
        setLoading(false);
      };
      getData();
    } catch (e) {
    }
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText, showExpired]);

  return (
    <Container sx={{pb: 0}}>
      <TitleStack>
        <Typography variant='h4' sx={{mr: 4}}>
          Договоры
        </Typography>
      </TitleStack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{height: '70px'}}>
        <Button
          onClick={handleAdd}
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill'/>}
          sx={{mr: 3}}
        >
          Новый договор
        </Button>
        <FormControlLabel control={<Switch
          checked={showExpired}
          onChange={(e) => setShowExpired(e.target.checked)}
        />} label="Показать недействующие"/>
      </Stack>
      <Card sx={{pt: 1}}>
        <MaterialTablePagination
          id={tableId}
          data={contracts}
          columns={tableColumns}
          enableHiding={true}
          initialState={{
            columnVisibility: {
              date_and_number: false,
              type: false,
              legal_entity: false,
              hour_rate: false,
              month_rate: false,
              currency: false,
              vacation_days: false,
              sick_days: false
            },
          }}
          loading={loading}
          setPagination={setPagination}
          pagination={pagination}
          rowCount={rowCount}
          pageCount={pageCount}
          onGlobalFilterChange={onGlobalFilterChange}
          search={searchText}
          muiTableBodyCellProps={({row}) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: 'pointer',
            },
          })}
        />
      </Card>
    </Container>
  );
};

export default Contracts;
