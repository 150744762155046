import Container from '@mui/material/Container';
import {TitleStack} from '../../theme/standarts_styles';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useActions} from '../../hook/useActions';
import {TableCellsWrap} from '../../components/TableCellsWrap';
import {$authHost} from '../../http';
import CustomViewField from "../../components/CustomViewField";
import {useSelector} from "react-redux";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import MaterialTablePagination from "../../components/MaterialTablePagination";
import {Card, Typography} from "@mui/material";
import Button from "@mui/material/Button";

const UserContracts = () => {
  const tableId = 'userContracts';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {contractId} = useParams();
  const {selectedContract, contracts} = useSelector((state) => state.contractsReducer);
  const {setContracts, setSelectedContract} = useActions();

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);


  const tableColumns = [
    {
      accessorKey: 'start_date',
      header: 'Дата заключения',
      accessorFn: (row) => dayjs(row.start_date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'end_date',
      header: 'Дата окончания',
      accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Состояние',
      accessorFn: (row) => row.status || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Тип',
      accessorFn: (row) => row.type || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'legal_entity',
      header: 'Юр. лицо',
      accessorFn: (row) => row.legal_entity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'date_and_number',
      header: 'Дата и номер',
      accessorFn: (row) => row.date_and_number || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'interaction_capacity',
      header: 'Емкость взаимодействия',
      accessorFn: (row) => row.interaction_capacity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'hour_capacity',
      header: 'Емкость часов fix',
      accessorFn: (row) => row.hour_capacity || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'loyalty_program',
      header: 'Программа лояльности',
      accessorFn: (row) => row.loyalty_program || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'production_calendar',
      header: 'Производственный календарь',
      accessorFn: (row) => row.production_calendar || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'month_rate',
      header: 'Ставка мес.',
      accessorFn: (row) => row.month_rate || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'hour_rate',
      header: 'Час. ставка',
      accessorFn: (row) => row.hour_rate || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'vacation_days',
      header: 'Отпуск в днях в год',
      accessorFn: (row) => row.vacation_days || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'sick_days',
      header: 'Больничные в год',
      accessorFn: (row) => row.sick_days || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
  ];

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      setSelectedContract(id);
    }
  };

  const handleBack = () => {
    navigate('/contracts');
  };

  const handleEdit = () => {
    navigate(`/contracts/${contractId}/edit`);
  };

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/agreements/all/`, {
          params: {
            user_id: selectedContract.user_id,
            search: searchText,
            page: pagination.pageIndex + 1,
            size: pagination.pageSize
          }
        }
      );
      setContracts(response.data.items);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  return (
    <>
      <Container>
        <TitleStack>
          <Typography variant='h4' sx={{mr: 4}}>
            Договорные отношения ({selectedContract.user?.display_name})
          </Typography>
        </TitleStack>
        <Card sx={{my: 1, p: 3}}>
          <CustomViewField
            label='Номер договора'
            text={selectedContract.date_and_number}
          />
          <CustomViewField label='Дата заключения' text={dayjs(selectedContract.start_date).format('DD.MM.YYYY')}/>
          <CustomViewField label='Дата окончания' text={
            selectedContract.is_unlimited ? 'Бессрочный' : dayjs(selectedContract.end_date).format('DD.MM.YYYY')
          }/>
          <CustomViewField
            label='Статус договора'
            text={selectedContract.status}
          />
          <CustomViewField
            label='Тип договора'
            text={selectedContract.type}
          />
          <CustomViewField label='Юр. лицо' text={selectedContract.legal_entity}/>
          <CustomViewField
            label='Производственный календарь'
            text={selectedContract.production_calendar}
          />
          <CustomViewField
            label='Программа лояльности'
            text={selectedContract.loyalty_program}
          />
          <CustomViewField
            label='Вид занятости'
            text={selectedContract.interaction_capacity}
          />
          {selectedContract.interaction_capacity === 'Fix' && (
            <>
              <CustomViewField label='Объем часов Fix' text={selectedContract.hour_capacity}/>
              <CustomViewField label='Ставка в час' text={selectedContract.hour_rate}/>
            </>
          )}
          {selectedContract.interaction_capacity === 'Fulltime' && (
            <CustomViewField label='Ставка в месяц' text={selectedContract.month_rate}/>
          )}
          <CustomViewField
            label='Валюта договора'
            text={selectedContract.currency}
          />
          <CustomViewField
            label='Отпуск в год, дней'
            text={selectedContract.vacation_days}
          />
          <CustomViewField
            label='Больничные в год, дней'
            text={selectedContract.sick_days}
          />
          <Stack direction='row' spacing={1}>
            <Button variant='contained' onClick={handleBack}>
              Назад к списку
            </Button>
            <Button variant='text' color='primary' onClick={handleEdit}>
              Редактировать
            </Button>
          </Stack>
        </Card>
        <TitleStack>
          <Typography variant='h4' sx={{mr: 4}}>
            Все договорные отношения ({selectedContract.user?.display_name})
          </Typography>
        </TitleStack>
        <Card sx={{my: 1, pt: 1}}>
          <MaterialTablePagination
            id={tableId}
            data={contracts}
            columns={tableColumns}
            enableHiding={true}
            loading={loading}
            setPagination={setPagination}
            pagination={pagination}
            rowCount={rowCount}
            pageCount={pageCount}
            onGlobalFilterChange={onGlobalFilterChange}
            search={searchText}
            muiTableBodyCellProps={({row}) => ({
              onClick: () => {
                handleOpenViewPage(row);
              },
              sx: {
                cursor: 'pointer',
              },
            })}
          />
        </Card>
      </Container>
    </>
  );
};

export default UserContracts;
