import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'

const RequareAuth = ({ children }) => {

  const {
    token,
  } = useSelector(state => state.authReducer)

  if (!token) {
    return <Navigate to="/login"/>
  }

  return children;
};

export default RequareAuth;
