import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ButtonStack } from '../theme/standarts_styles'
import { Avatar, Button, Card, Grid, IconButton, Skeleton } from "@mui/material";
import Iconify from './iconify'
import MaterialTable from './MaterialTable'
import LoadingSpinner from './modals/loadingSpinner'
import { useActions } from '../hook/useActions'
import { useSelector } from 'react-redux'
import { TableCellsWrap } from './TableCellsWrap'
import { $authHost } from '../http'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import AddEditFormButtonGroup from './forms/AddEditFormButtonGroup'
import BaseAddEditForm from './forms/BaseAddEditForm'
import LeaveApplicationFields from './forms/formFields/LeaveApplicationFields'
import Box from '@mui/material/Box'
import LeaveApplicationTopFields from './forms/formFields/LeaveApplicationTopFields'
import MaterialTablePagination from "./MaterialTablePagination";
import Label from "./label/Label";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { hrHR } from "@mui/material/locale";

const status_options = [
	{ name: 'Отказано', original_name: 'rejected' },
	{ name: 'Согласованно', original_name: 'approved' },
]

const ApproveSchedule = ({...props}) => {

	// TODO почистить переменные
	const { t } = useTranslation()
	const [selectedRow, setSelectedRow] = useState(null)
	const [loading, setLoading] = useState(false)
	const [loadingCell, setLoadingCell] = useState(false)
	const [openForm, setOpenForm] = useState('')
	const fieldsRef = useRef(null)
	const [confirmation, setConfirmation] = useState(false);
	const [renderUser, setRenderUser] = useState([])

	const tableId = `ApproveSchedule${props.id}`;
	const tableStates = useSelector((state) => state.tableStatesReducer);

	const { userId, roles } = useSelector(state => state.authReducer)

	const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
	});
	const [rowCount, setRowCount] = useState(0);
	const [pageCount, setPageCount] = useState(-1);

	const [open, setOpen] = React.useState(true);

	const {
		setCalendarRules,
		setSelectedContact,
	} = useActions()

	const {
		calendar_rules,
	} = useSelector(state => state.calendarReducer)

	const getColorByStatus = (renderedCellValue) => {
	  switch (renderedCellValue) {
			case 'approved':
				return "success"
			case 'rejected':
				return "error"
			case 'new':
				return "info"
			default:
				return "default"

		}
	}

	const GetUser = async (source_id) => {
		try {
			await $authHost.get(`/users/${source_id}/`).then((response) => {
				return response.data
			});
		} catch (e) {
			return {};
		}
	}

	function httpGet(source_id) {
		return new Promise(async function(resolve, reject) {
			await $authHost.get(`/users/${source_id}/`).then((response) => {
				if (response.status === 200) {
					setLoadingCell(false)
					resolve(response.data);
				} else {
					reject(response.status);
				}
			})
		});

	}

	async function fetchGet(user, row) {
		let res = await httpGet(user);
		const info = { photo: res?.photo, display_name: res?.display_name, first_name: res?.first_name, last_name: res?.last_name }
		setRenderUser([...renderUser, { source_id: row, user: info }])
	}


	const tableColumns = [

		{
			accessorKey: 'updated_at',
			header: 'Последние изменения',
			accessorFn: (row) => dayjs(row.updated_at).format('DD.MM.YYYY') || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'status',
			header: 'Статус',
			Cell: ({ renderedCellValue, column }) => {
				const translationKey = `dropdown.${column.id}.${renderedCellValue}`
				return (
					<TableCellsWrap>
						{renderedCellValue !== null ?
							<Label
								color={getColorByStatus(renderedCellValue)}
							>
								{
									t(`${translationKey}`) !== translationKey // берем перевод или выводим ключ  приотсутствии перевода
										? t(`${translationKey}`)
										: renderedCellValue
								}
							</Label>
							:
							<Label
								color="default"
							>
								Не указано
							</Label>
						}
					</TableCellsWrap>
				)
			}
		},
		{
			accessorKey: 'type',
			header: 'Тип заявки',
			Cell: ({ renderedCellValue, column }) => {
				const translationKey = `dropdown.${column.id}.${renderedCellValue}`
				return (
					<TableCellsWrap>
						{
							t(`${translationKey}`) !== translationKey // берем перевод или выводим ключ  приотсутствии перевода
								? t(`${translationKey}`)
								: renderedCellValue
						}
					</TableCellsWrap>
				)
			},
		},
		{
			id: 'employee',
			header: 'Сотрудник',
			accessorFn: (row) => row,
			Cell:  ({ renderedCellValue }) => {
				switch (renderedCellValue.type) {
					case 'fired':
						{
							setLoadingCell(true);
							if(renderUser.filter(el => el.source_id === renderedCellValue.users[0]) <= 0){
								fetchGet(renderedCellValue.users[0], renderedCellValue.users[0])
							}
							setLoadingCell(false)
							let user = renderUser.find(el => el.source_id === renderedCellValue.users[0]);
							return(
								<>
									{loadingCell === false ?
										<TableCellsWrap>
											<Chip
												variant="outlined"
												avatar={
													<Avatar
														src={user?.user?.photo}
													/>
												}
												label={user?.user?.display_name || `${user?.user?.first_name} ${user?.user?.last_name}`}
											/>
										</TableCellsWrap>
										:
										<TableCellsWrap>
											<Skeleton/>
										</TableCellsWrap>
									}
								</>
							)
						}
					default:
						return (
							<TableCellsWrap>
								<Chip
									variant="outlined"
									avatar={
										<Avatar
											src={renderedCellValue?.user?.photo}
										/>
									}
									label={renderedCellValue?.user?.display_name || `${renderedCellValue?.user?.first_name} ${renderedCellValue?.user?.last_name}`}
								/>
							</TableCellsWrap>
						)
				}
			}
		},
		{
			id: 'department',
			header: 'Отдел',
			accessorFn: (row) => row.department?.name || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'start_date',
			header: 'Дата начала',
			accessorFn: (row) => dayjs(row.start_date).format('DD.MM.YYYY') || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'end_date',
			header: 'Дата окончания',
			accessorFn: (row) => row,
			Cell:  ({ renderedCellValue }) => {
				switch (renderedCellValue.type) {
					case 'fired':
					{
						return(
							<TableCellsWrap>
								{""}
							</TableCellsWrap>
						)
					}
					default:
						return (
							<TableCellsWrap>
								{dayjs(renderedCellValue.end_date).format('DD.MM.YYYY') || ''}
							</TableCellsWrap>
						)
				}
			}
		},
		{
			id: 'request_text',
			header: 'Текст запроса',
			accessorFn: (row) => row.request_text || '',
			// accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},

		{
			accessorKey: 'user_approved',
			header: 'Кто решил',
			// accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>
					{
						renderedCellValue !== null ?
							<Chip
								variant="outlined"
								avatar={
									<Avatar
										alt={
											renderedCellValue?.display_name.substring(0, 1)
											|| renderedCellValue && renderedCellValue?.first_name.substring(0, 1)
											|| null
										}
										src={renderedCellValue?.photo}
									/>
								}
								label={
									renderedCellValue?.display_name
									|| renderedCellValue && `${renderedCellValue?.first_name} ${renderedCellValue?.last_name}`
									|| null
								}
							/>
							:
							""
					}
				</TableCellsWrap>
			),
		},
		{
			id: 'comment',
			header: 'Комментарий',
			accessorFn: (row) => row?.comment || '',
			// accessorFn: (row) => dayjs(row.end_date).format('DD.MM.YYYY') || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
		{
			accessorKey: 'created_at',
			header: 'Дата подачи',
			accessorFn: (row) => dayjs(row.created_at).format('DD.MM.YYYY') || '',
			Cell: ({ renderedCellValue }) => (
				<TableCellsWrap>{renderedCellValue}</TableCellsWrap>
			),
		},
	]
	//
	// function handleGoToEditPage (row) {
	//   setSelectedRow(row)
	//   setSelectedContact(row.id)
	//   setOpenForm('Update')
	// }
	//
	// async function handleDeleteRow(row) {
	//   try {
	//     await $authHost.delete(`/contacts/${row}/delete/`).then(response=>{
	//       loadData()
	//     })
	//   } catch (e) {
	//   }
	// }

	const handleAdd = () => {
		setOpenForm(prevState => {
			return prevState === 'Add' ? '' : 'Add'
		})
		setSelectedRow(null)
	}
	const handleOpenViewPage = (row) => {
		// console.log(row);
		// if(row.status === "new"){
			setSelectedRow(row.original)
			setSelectedContact(row.id)
			openForm !== 'View' && setOpenForm('View')
		// } else {
		// 	console.log("ytf");
		// }

	}

	const AddAction = async (data) => {
		const { type, payer, start_date, end_date, users, request_text } = data;
		switch ( type === 'fired' && roles?.some(role => role === 'ERP_Admins') ) {
			case true: {
				let data1 = {
					calendar_rules_data: {
						status: "approved",
						subtype: "claim",
						type: type || null,
						start_date: dayjs(start_date).format('YYYY-MM-DD') || null,
						end_date: dayjs(start_date).format('YYYY-MM-DD') || null,
						users: [users?.source_id] || null,
						request_text: request_text || null
					}
				}
				try {
					await $authHost.post(`/calendar_rules/add/?accept_to_all=false`, data1)
					loadData()
					setOpenForm('')
				} catch (e) {} finally {}
			}
			break;
			default: {
				let new_data = {
					type: type || null,
					payer: payer || null,
					start_date: dayjs(start_date).format('YYYY-MM-DD') || null,
					end_date: end_date ? dayjs(end_date).format('YYYY-MM-DD') : dayjs(start_date).format('YYYY-MM-DD'),
					request_text: request_text || null
				}
				try {
					await $authHost.post(`/calendar_rules/me/add/`, new_data)
					loadData()
					setOpenForm('')
				} catch (e) {} finally {}
			}
			break;
		}
	}

	const ViewAction = async (data) => {
		const { status, comment, id } = data
		try {
			await $authHost.patch(`/calendar_rules/claim/${id}/update/`, { status, comment })
			loadData()
			setOpenForm('')
		} catch (e) {
		} finally {
		}
	}

	const onGlobalFilterChange = (v) => {
		setSearchText(v);
	}

	// const loadData = useCallback(() => {
	// 	try {
	// 		const newChannelList = async () => {
	// 			const response = await $authHost.get(
	// 				top
	// 					? `/calendar_rules/all/?page=1&size=50`// TODO новые поля и добавить фильтра
	// 					: `/calendar_rules/me/?page=1&size=50`,
	// 			)
	// 			return response.data.items
	// 		}
	// 		newChannelList().then((data) => {
	// 			setCalendarRules(data) // TODO альтернативное хранилище?
	// 		})
	// 	} catch (e) {
	// 	}
	// }, [])

	const loadData = async () => {
		setLoading(true);

		try {
			const response = await $authHost.get(
				props.id === 1 ?
					`/calendar_rules/me/?subtype=claim&search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`
					: `/calendar_rules/all/?subtype=claim&search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`,
			);
			const data = response.data.items;
			setCalendarRules(data);
			setRowCount(response.data?.total);
			setPageCount(response.data?.pages);
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadData()
	}, [pagination.pageIndex, pagination.pageSize, searchText]);


	return (
		<>
			{loading && <LoadingSpinner/>}
			<Box pt={props.admin ? 2 : 0}>
				{!props.admin &&
					<ButtonStack>
						<Button
							onClick={handleAdd}
							aria-haspopup="true"
							variant="contained"
							startIcon={<Iconify icon="eva:plus-fill"/>}
							sx={{ mr: 3 }}
						>
							{/*{t('src.pages.products.ResourcesPage.addResource', 'Создать заявление')}*/}
							Создать заявление
						</Button>
					</ButtonStack>
				}
				{/*<AlertMessage/>*/}
				{(openForm) &&
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Card sx={{ p: 3, mb: 2 }}>
								<BaseAddEditForm selectedRow={selectedRow}
																 action={openForm}
																 fieldsRef={fieldsRef}
																 handleClose={() => setOpenForm('')}
																 setLoading={setLoading}
																 AddAction={AddAction}
																 ViewAction={ViewAction}
																 setConfirmation={setConfirmation}
								>
									<LeaveApplicationFields openForm={openForm}/>
									{openForm === 'View' &&
										<LeaveApplicationTopFields statusOptions={status_options}
																							 loading={loading}
																							 selectedClient={selectedRow}
										/>
									}
									<AddEditFormButtonGroup
										action={openForm}
										handleClose={() => setOpenForm('')}
										confirmation={confirmation}
										// confirmationForEditForm={confirmationForEditForm}
										// setConfirmationForEditForm={setConfirmationForEditForm}
									/>
								</BaseAddEditForm>
							</Card>
						</Grid>
					</Grid>
				}
				<Card sx={{ pt: 1 }}>
					<MaterialTablePagination
						id={tableId}
						data={calendar_rules}
						columns={tableColumns}
						loading={loading}
						enableHiding={true}
						initialState={
							props.id === 1 && {
								columnVisibility: {
									user: false
								}
							}
						}
						setPagination={setPagination}
						pagination={pagination}
						rowCount={rowCount}
						pageCount={pageCount}
						onGlobalFilterChange={onGlobalFilterChange}
						search={searchText}
						muiTableBodyCellProps={
							props.admin
								? ({ row }) => (
									row.original.status === 'new' &&
									{
										onClick: () => {
											handleOpenViewPage(row)
										},
										sx: {
											cursor: 'pointer',
										}
									}
								)
								: undefined
						}
					/>
				</Card>
			</Box>
		</>
	)
}

export default ApproveSchedule