import {
  Box,
  Button,
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Stack,
  Avatar,
  Tooltip, Grid
} from "@mui/material";
import {filter} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import {UserListHead, UserListToolbar} from '../../sections/@dashboard/user';
import {TableCellsWrap} from '../TableCellsWrap';
import EditIcon from '@mui/icons-material/Edit';
import Iconify from '../iconify';
import {useActions} from '../../hook/useActions';
import {useSelector} from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {$authHost} from '../../http';
import MaterialTable from '../MaterialTable';
import Chip from '@mui/material/Chip';
import {stringAvatar} from '../../utils/textAvatar';
import {ButtonStack} from "../../theme/standarts_styles";
import LoadingSpinner from "../modals/loadingSpinner";
import BaseAddEditForm from "../forms/BaseAddEditForm";
import ContactsForm from "../forms/ContactsForm";
import AddEditFormButtonGroup from "../forms/AddEditFormButtonGroup";
import BlockFormModal from "../BlockFormModal";
import DepartmentsForm from "../../pages/forms/DepartmentsForm";
import MaterialTablePagination from "../MaterialTablePagination";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const Departments = () => {
  const tableId = 'departments';
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState('');

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(tableStates[tableId]?.globalFilter || '');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const {roles} = useSelector(state => state.authReducer)

  const {
    setDepartments,
    setSelectedDepartment,
    setSelectedManager,
    setValidationErrorManager,
    setValidationManager
  } = useActions();

  const {
    departments,
    selectedDepartment,
    selectedManager
  } = useSelector((state) => state.departmentsReducer);

  const handleAddClient = (event) => {
    setSelectedRow(null);
    setSelectedDepartment(null);
    setSelectedManager(null);
    setOpenForm((prevState) => {
      return prevState === 'Add' ? '' : 'Add';
    });
  };

  function handleEditClient(row) {
    setSelectedRow(row);
    setSelectedDepartment(row);
    setOpenForm('Update');
  }

  async function handleDeleteRow(id) {
    setLoading(true);
    try {
      await $authHost.delete(`/departments/${id}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }


  const tableColumns = [
    {
      accessorKey: 'name',
      header: 'Название',
      accessorFn: (row) => row.name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: 'manager',
      header: 'Менеджер',
      accessorFn: (row) => row.manager?.display_name || '',
      Cell: ({renderedCellValue, row}) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: 'actions',
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {

        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title='Подтвердить'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Отмена'>
                <IconButton
                  size='large'
                  color='inherit'
                  onClick={() => setConfirmationId(null)}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            {roles?.some(role => role === 'ERP_Admins') &&
              <>
                <Tooltip title='Редактировать'>
                  <IconButton
                    size='large'
                    color='inherit'
                    onClick={() => handleEditClient(row.original)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Удалить'>
                  <IconButton
                    size='large'
                    color='inherit'
                    onClick={() => setConfirmationId(row.original.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
          </>
        )
      },
    },
  ];


  const UpdateAction = async (data) => {
      try {
        const updateContact = async () => {
          return await $authHost.put(
            `/departments/${selectedDepartment.id}/update/`,
            {
              manager_id: data.manager.source_id,
              name: data.name
            },
          );
        };

        updateContact().then(() => {
          setOpenForm('');
          loadData();
        });
      } catch (e) {
      } finally {
      }
  }

  const AddAction = async (data) => {
    try {
      const newPerson = async () => {
        const response = await $authHost.post(`/departments/add/`, {
          name: data.name,
          manager_id: data.manager.source_id
        })
        return response.data;
      };
      newPerson().then(() => {
        setOpenForm('');
        loadData();
      });
    } catch (e) {
    } finally {
    }
  };


  const loadData = async () => {
    setLoading(true);
    try {

      const response = await $authHost.get(`/departments/all/?search=${searchText}&page=${pagination.pageIndex + 1}&size=${pagination.pageSize}`);
      const data = response.data.items;
      setDepartments(data);
      setRowCount(response.data?.total);
      setPageCount(response.data?.pages);

    } catch (e) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    loadData();
  }, [pagination.pageIndex, pagination.pageSize, searchText]);

  useEffect(() => {
    if (!openForm && selectedDepartment) {
      setSelectedDepartment(null);
      setSelectedManager(null);
    }
    if (openForm === ''){
      setSelectedManager(null);
    }
  }, [openForm]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  }

  return (
    <>
      <Box pt={roles?.some(role => role === 'ERP_Admins') ? 0 : 2}>
        {roles?.some(role => role === 'ERP_Admins') &&
          <ButtonStack>
              <Button
                onClick={handleAddClient}
                aria-haspopup='true'
                variant='contained'
                disabled={!!selectedDepartment}
                startIcon={<Iconify icon='eva:plus-fill' />}
                sx={{ mr: 0}}
              >
                Добавить отдел
              </Button>
          </ButtonStack>
        }
        {openForm && openForm !== 'View' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card sx={{p: 3, mb: 2}}>
                <BaseAddEditForm
                  setLoading={setLoading}
                  selectedRow={selectedRow}
                  action={openForm}
                  resetValue={{
                    name: '',
                    manager: '',
                  }}
                  AddAction={AddAction}
                  UpdateAction={UpdateAction}
                  setConfirmation={setConfirmation}
                >
                  <DepartmentsForm selectedRow={selectedRow} action={openForm}/>
                  <AddEditFormButtonGroup
                    action={openForm}
                    confirmation={confirmation}
                    handleClose={() => setOpenForm('')}
                  />
                </BaseAddEditForm>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <Card sx={{pt: 1}}>
          <MaterialTablePagination
            id={tableId}
            data={departments}
            columns={tableColumns}
            loading={loading}
            setPagination={setPagination}
            pagination={pagination}
            rowCount={rowCount}
            pageCount={pageCount}
            onGlobalFilterChange={onGlobalFilterChange}
            search={searchText}
          />
        </Card>
      </Box>
      <BlockFormModal when={!!confirmationId} title='Вы не завершили действие внутри таблицы'/>
    </>
  );
};

export default Departments;
