import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {Controller, FormProvider, useForm, useWatch} from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useSelector} from 'react-redux';
import {$authHost} from '../../http';
import {useActions} from '../../hook/useActions';
import dayjs from 'dayjs';
import {currencies2} from '../../constants';
import Stack from '@mui/material/Stack';
import SkeletonInput from '../SkeletonInput';
import BlockFormModal from '../BlockFormModal';
import VirtualizedAutocomplete from "../VirtualizedAutocomplete";

const CommonSettings = () => {
  const {projectId} = useParams();

  const {setProject, setClientsAll, setEmployees} = useActions();

  const {clientsAll} = useSelector((state) => state.clientsReducer);
  const {employees} = useSelector((state) => state.employeesReducer);
  const {project} = useSelector((state) => state.projectsReducer);

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const loadProject = useCallback(() => {
    setLoading(true);
    try {
      const getProject = async () => {
        const response = await $authHost.get(`/projects/${projectId}/`);
        setProject(response.data);
      };
      getProject();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  const loadClients = useCallback(() => {
    try {
      const getClients = async () => {
        const response = await $authHost.get('/clients/all/dropdown/');
        setClientsAll(response.data);
      };
      getClients();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  const loadUsers = useCallback(() => {
    try {
      const getUsers = async () => {
        const response = await $authHost.get('/users/all/');
        setEmployees(response.data.items);
      };
      getUsers();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadProject();
    loadClients();
    loadUsers();
  }, []);

  const methods = useForm({
    mode: 'onBlur',
  });

  const {
    formState: {errors, isDirty, isSubmitSuccessful},
    getValues,
    setValue,
    setError,
    handleSubmit,
    register,
    control,
    reset,
  } = methods;

  useEffect(() => {
    reset({
      ...project,
      team: Array.isArray(project.team) ? project.team : [],
    });
  }, [project]);

  const icon = <CheckBoxOutlineBlankIcon fontSize='small'/>;
  const checkedIcon = <CheckBoxIcon fontSize='small'/>;

  const onSubmit = async (data) => {
    setLoading(true);
    const {
      client,
      code,
      currency,
      start_date,
      end_date,
      actual_start_date,
      actual_end_date,
      manager,
      name,
      notes,
      status,
      team,
      currency_budget,
      commercial_hours_budget,
      internal_hours_budget,
    } = data;

    const newData = {
      code,
      name,
      notes,
      status,
      currency_budget,
      commercial_hours_budget,
      internal_hours_budget,
      currency: currency?.value,
      client_id: client?.source_id || client?.id,
      start_date: start_date ? dayjs(start_date).format('YYYY-MM-DDTHH:mm:ss') : null,
      end_date: end_date ? dayjs(end_date).format('YYYY-MM-DDTHH:mm:ss') : null,
      actual_start_date: actual_start_date ? dayjs(actual_start_date).format('YYYY-MM-DDTHH:mm:ss') : null,
      actual_end_date: actual_end_date ? dayjs(actual_end_date).format('YYYY-MM-DDTHH:mm:ss') : null,
      manager_id: manager?.source_id,
      team:
        Array.isArray(team) && team.length > 0
          ? team.map((user) => user?.source_id)
          : [],
    };
    // console.log(data);
    // console.log(newData);
    try {
      await $authHost.put(
        `/projects/${projectId}/update/`,
        newData,
      );
    } catch (e) {
      setError('root.serverError', {
        type: 'server',
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate('/projects');
    }
  }, [isSubmitSuccessful]);

  // const clickupList = [
  //   { id: 1, name: 'name 1' },
  //   { id: 2, name: 'name 2' },
  //   { id: 3, name: 'name 3' },
  //   { id: 4, name: 'name 4' },
  //   { id: 5, name: 'name 5' },
  // ];

  const clickupList = ['name 1', 'name 2', 'name 3', 'name 4', 'name 5'];

  // TODO: Придумать лучший способ обновления формы после редактирования поля

  const navigate = useNavigate();
  const manager = useWatch({control, name: 'manager'});

  useEffect(() => {
    const team = getValues('team');
    if (manager && Array.isArray(team)) {
      const managerInTeam = team.find((v) => v?.id === manager?.id);
      if (!managerInTeam) {
        setValue('team', [manager, ...team]);
      }
    }
  }, [manager]);

  const handleBack = () => {
    navigate('/projects');
  };

  const [currencyAdornmentLabel, setCurrencyAdornmentLabel] = useState('$');
  const currency = useWatch({control, name: 'currency'});

  useEffect(() => {
    currency?.label && setCurrencyAdornmentLabel(currency.label);
  }, [currency]);

  return (
    <Box sx={{flexDirection: 'column', px: 0, py: 2}}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={6}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: 2,
                }}
              >
                Общие сведения
              </Typography>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Код проекта *'
                  {...register('code', {
                    required: 'Обязательное поле',
                  })}
                  error={!!errors?.code}
                  helperText={errors?.code?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Наименование проекта *'
                  {...register('name', {
                    required: 'Обязательное поле',
                  })}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <VirtualizedAutocomplete
                  name='client'
                  label='Клиент'
                  options={clientsAll}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  required
                  loading={loading}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Статус проекта *'
                  {...register('status', {
                    required: 'Обязательное поле',
                  })}
                  error={!!errors?.status}
                  helperText={errors?.status?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label='Описание'
                  {...register('notes')}
                  error={!!errors?.notes}
                  helperText={errors?.notes?.message}
                  fullWidth
                  size='small'
                  sx={{
                    mb: 2,
                  }}
                  rows={4}
                  multiline={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </SkeletonInput>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: 2,
                }}
              >
                Интеграция
              </Typography>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                  name='clickup'
                  render={({
                             field: {ref, ...field},
                             fieldState: {error},
                           }) => {
                    return (
                      <Autocomplete
                        {...field}
                        size='small'
                        sx={{
                          mb: 2,
                        }}
                        options={clickupList}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            label='ClickUp'
                            {...params}
                            inputRef={ref}
                            error={!!error}
                            helperText={error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, value) => field.onChange(value)}
                        onInputChange={(_, data) => {
                          if (data) field.onChange(data);
                        }}
                      />
                    );
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                  name='teams'
                  render={({
                             field: {ref, ...field},
                             fieldState: {error},
                           }) => {
                    return (
                      <Autocomplete
                        {...field}
                        options={clickupList}
                        getOptionLabel={(option) => option}
                        size='small'
                        sx={{
                          mb: 2,
                        }}
                        renderInput={(params) => (
                          <TextField
                            label='Teams'
                            {...params}
                            inputRef={ref}
                            error={!!error}
                            helperText={error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, value) => field.onChange(value)}
                        onInputChange={(_, data) => {
                          if (data) field.onChange(data);
                        }}
                      />
                    );
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                  name='azure_dev_ops'
                  render={({
                             field: {ref, ...field},
                             fieldState: {error},
                           }) => {
                    return (
                      <Autocomplete
                        {...field}
                        options={clickupList}
                        getOptionLabel={(option) => option}
                        size='small'
                        sx={{
                          mb: 2,
                        }}
                        renderInput={(params) => (
                          <TextField
                            label='Azure'
                            {...params}
                            inputRef={ref}
                            error={!!error}
                            helperText={error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, value) => field.onChange(value)}
                        onInputChange={(_, data) => {
                          if (data) field.onChange(data);
                        }}
                      />
                    );
                  }}
                />
              </SkeletonInput>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: 2,
                }}
              >
                Свойства
              </Typography>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <SkeletonInput loading={loading}>
                    <Controller
                      name='actual_start_date'
                      control={control}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                      render={({
                                 field: {onChange, value},
                                 fieldState: {error},
                               }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='Старт проекта'
                            control={control}
                            value={dayjs(value)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            format='DD.MM.YYYY'
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                                InputLabelProps: {
                                  shrink: true,
                                },
                              },
                            }}
                            sx={{
                              width: '100%',
                              mb: 2,
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <Controller
                      name='actual_end_date'
                      control={control}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                      render={({
                                 field: {onChange, value},
                                 fieldState: {error},
                               }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='Дата завершения'
                            control={control}
                            value={dayjs(value)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            format='DD.MM.YYYY'
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                                InputLabelProps: {
                                  shrink: true,
                                },
                              },
                            }}
                            sx={{
                              width: '100%',
                              mb: 2,
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <Controller
                      control={control}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                      name='currency'
                      render={({
                                 field: {onChange, ...field},
                                 fieldState: {error},
                               }) => {
                        return (
                          <Autocomplete
                            {...field}
                            options={currencies2}
                            getOptionLabel={(option) => option}
                            size='small'
                            sx={{
                              mb: 2,
                            }}
                            renderInput={(params) => (
                              <TextField
                                label='Валюта проекта'
                                {...params}
                                error={!!error}
                                helperText={error?.message}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            onChange={(e, value) => onChange(value)}
                          />
                        );
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <TextField
                      label='Бюджет в валюте'
                      {...register('currency_budget', {
                        // required: 'Обязательное поле',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Некорректный ввод',
                        },
                      })}
                      error={!!errors?.currency_budget}
                      helperText={errors?.currency_budget?.message}
                      fullWidth
                      size='small'
                      sx={{
                        mb: 2,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {currencyAdornmentLabel}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <TextField
                      label='Бюджет в ком. часах'
                      {...register('commercial_hours_budget', {
                        // required: 'Обязательное поле',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Некорректный ввод',
                        },
                      })}
                      error={!!errors?.commercial_hours_budget}
                      helperText={errors?.commercial_hours_budget?.message}
                      fullWidth
                      size='small'
                      sx={{
                        mb: 2,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>h</InputAdornment>
                        ),
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <TextField
                      label='Бюджет во вн. часах'
                      {...register('internal_hours_budget', {
                        // required: 'Обязательное поле',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Некорректный ввод',
                        },
                      })}
                      error={!!errors?.internal_hours_budget}
                      helperText={errors?.internal_hours_budget?.message}
                      fullWidth
                      size='small'
                      sx={{
                        mb: 2,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>h</InputAdornment>
                        ),
                      }}
                    />
                  </SkeletonInput>
                </Grid>
                <Grid item xs={6}>
                  <SkeletonInput loading={loading}>
                    <Controller
                      name='start_date'
                      control={control}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                      render={({
                                 field: {onChange, value},
                                 fieldState: {error},
                               }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='Старт План'
                            control={control}
                            value={dayjs(value)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            format='DD.MM.YYYY'
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                                InputLabelProps: {
                                  shrink: true,
                                },
                              },
                            }}
                            sx={{
                              width: '100%',
                              mb: 2,
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <Controller
                      name='end_date'
                      control={control}
                      rules={
                        {
                          // required: 'Обязательное поле',
                        }
                      }
                      render={({
                                 field: {onChange, value},
                                 fieldState: {error},
                               }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='Завершение План'
                            control={control}
                            value={dayjs(value)}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            format='DD.MM.YYYY'
                            slotProps={{
                              textField: {
                                error: !!error,
                                helperText: error?.message,
                                size: 'small',
                                InputLabelProps: {
                                  shrink: true,
                                },
                              },
                            }}
                            sx={{
                              width: '100%',
                              mb: 9,
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <TextField
                      label='Нач. бюджет в валюте'
                      {...register('currency_budget_start', {
                        // required: 'Обязательное поле',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Некорректный ввод',
                        },
                      })}
                      error={!!errors?.currency_budget}
                      helperText={errors?.currency_budget?.message}
                      fullWidth
                      size='small'
                      sx={{
                        mb: 2,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {currencyAdornmentLabel}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <TextField
                      label='Нач. бюджет в ком. часах'
                      {...register('commercial_hours_budget_start', {
                        // required: 'Обязательное поле',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Некорректный ввод',
                        },
                      })}
                      error={!!errors?.commercial_hours_budget}
                      helperText={errors?.commercial_hours_budget?.message}
                      fullWidth
                      size='small'
                      sx={{
                        mb: 2,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>h</InputAdornment>
                        ),
                      }}
                    />
                  </SkeletonInput>
                  <SkeletonInput loading={loading}>
                    <TextField
                      label='Нач. бюджет во вн. часах'
                      {...register('internal_hours_budget_start', {
                        // required: 'Обязательное поле',
                        pattern: {
                          value: /^\d*$/,
                          message: 'Некорректный ввод',
                        },
                      })}
                      error={!!errors?.internal_hours_budget}
                      helperText={errors?.internal_hours_budget?.message}
                      fullWidth
                      size='small'
                      sx={{
                        mb: 2,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>h</InputAdornment>
                        ),
                      }}
                    />
                  </SkeletonInput>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: 2,
                }}
              >
                Команда проекта
              </Typography>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  rules={{
                    required: 'Обязательное поле',
                  }}
                  name='manager'
                  render={({
                             field: {onChange, value, ...field},
                             fieldState: {error},
                           }) => {
                    return (
                      <Autocomplete
                        {...field}
                        value={value || null}
                        options={employees}
                        getOptionLabel={(option) => option.display_name}
                        isOptionEqualToValue={(option, value) =>
                          option?.source_id === value?.source_id
                        }
                        size='small'
                        sx={{
                          mb: 2,
                        }}
                        renderInput={(params) => (
                          <TextField
                            label='Менеджер проекта *'
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, value) => onChange(value)}
                      />
                    );
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                  name='team'
                  render={({
                             field: {onChange, value, ...field},
                             fieldState: {error},
                           }) => {
                    return (
                      <Autocomplete
                        {...field}
                        value={value || []}
                        options={employees}
                        getOptionLabel={(option) => option.display_name}
                        isOptionEqualToValue={(option, value) =>
                          option?.source_id === value?.source_id
                        }
                        multiple
                        size='small'
                        sx={{
                          mb: 2,
                        }}
                        renderInput={(params) => (
                          <TextField
                            label='Команда *'
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(e, value) => onChange(value)}
                      />
                    );
                  }}
                />
              </SkeletonInput>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <Button variant='contained' type='submit' disabled={loading}>
                    Сохранить
                  </Button>
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ml: 1}}
                    onClick={handleBack}
                  >
                    Отменить
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='text'
                    color='primary'
                    sx={{ml: 1}}
                    onClick={() => reset()}
                  >
                    Сбросить
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>
    </Box>
  );
};

export default CommonSettings;
