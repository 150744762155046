import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid, Stack,
  Switch,
} from '@mui/material';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Chip from '@mui/material/Chip';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import IconButton from '@mui/material/IconButton';
import BlockFormModal from '../BlockFormModal';
import {useActions} from '../../hook/useActions';
import {$authHost} from '../../http';
import LoadingSpinner from '../modals/loadingSpinner';
import AlertMessage from '../Alert/AlertMessage';
import CustomTextField from '../TextFields/CustomTextField';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const acceptFileType = ['image/jpeg', 'image/png'];

const UserData = () => {
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  // const { update, user } = useAuth();
  const [errorImport, setErrorImport] = useState(false);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [uploadedAvatarLabel, setUploadedAvatarLabel] = useState(null);

  const methods = useForm({
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: {isDirty, isSubmitSuccessful},
    reset,
    setValue,
    control,
  } = methods;

  const {
    first_name,
    last_name,
    email,
    photo,
    phone,
    birth_date,
    country,
    street_address,
    work_schedule,
    communication_channels,
  } = useSelector((state) => state.authReducer);

  const handleReset = () => {
    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('email', email);
    setValue('phone', phone);
    setValue('birth_date', birth_date);
    setValue('country', country);
    setValue('street_address', street_address);
    setValue('work_schedule', work_schedule);
    setValue('communication_channels', communication_channels);
  };

  useEffect(() => {
    handleReset();
  }, []);

  const {setErrorAlertMessage, updateUser} = useActions();

  const inputRef = useRef(null);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (
      fileObj &&
      acceptFileType.indexOf(fileObj.type) !== -1 &&
      fileObj.size / 1024 ** 2 < 3.6
    ) {
      const FR = new FileReader();
      FR.addEventListener('load', function (evt) {
        setUploadedAvatar(evt.target.result);
      });
      FR.readAsDataURL(event.target.files[0]);
      setUploadedAvatarLabel(fileObj.name);
    } else {
      setErrorAlertMessage(t('src.pages.AccountPage.UserData.extension'));
    }
  };

  const cleanInput = () => {
    setUploadedAvatar(null);
    setUploadedAvatarLabel(null);
  };

  const loadData = useCallback(() => {
    try {
      const getUser = async () => {
        const response = await $authHost.get(`/users/me/`);
        return response.data;
      };
      getUser().then(
        ({
           display_name,
           first_name,
           last_name,
           email,
           phone,
           birth_date,
           work_schedule,
           country,
           photo,
           street_address,
           communication_channels,
           groups,
         }) => {
          updateUser({
            display_name,
            first_name,
            last_name,
            email,
            phone,
            birth_date,
            work_schedule,
            country,
            photo,
            street_address,
            communication_channels,
            groups,
          });
        },
      );
    } catch (e) {
    }
  }, []);

  const onSubmit = async ({email, photo, ...data}) => {
    setLoading(true);
    const newData = data;
    newData.display_name = `${data.first_name} ${data.last_name}`;
    if (uploadedAvatar !== null) {
      newData.photo = uploadedAvatar.split(',')[1];
    }
    try {
      await $authHost.put(`/users/me/update/`, newData);
      cleanInput();
      loadData();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{px: 2, py: 4, my: 2}}>
      {loading && <LoadingSpinner/>}
      <AlertMessage/>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Grid container>
                <Grid item md={4}>
                  <Stack alignItems='center'>
                    <div className='containerImg'>
                      <Avatar
                        id='img'
                        // className="image"
                        src={uploadedAvatar || photo}
                        alt='photoURL'
                        sx={{width: 200, height: 200, mb: 2}}
                      />
                      <input
                        id='avatar'
                        style={{display: 'none'}}
                        ref={inputRef}
                        type='file'
                        onChange={handleFileChange}
                      />
                      <div
                        className='overlay'
                        onClick={() => {
                          inputRef.current.click();
                        }}
                      >
                        <IconButton aria-label='delete' className='icon'>
                          <DriveFolderUploadIcon fontSize='inherit'/>
                        </IconButton>
                      </div>
                    </div>
                    {uploadedAvatarLabel && (
                      <Chip
                        label={uploadedAvatarLabel}
                        variant='outlined'
                        onDelete={cleanInput}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item md={8}>
                  <CustomTextField
                    name='first_name'
                    label='Имя'
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    sx={{width: '100%', mb: 3}}
                    size='small'
                  />
                  <CustomTextField
                    name='last_name'
                    label='Фамилия'
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    sx={{width: '100%', mb: 3}}
                    size='small'
                  />
                  <CustomTextField
                    name='email'
                    label='Email'
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    sx={{width: '100%', mb: 3}}
                    size='small'
                    disabled
                  />
                  <CustomTextField
                    name='phone'
                    label='Телефон'
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    sx={{width: '100%', mb: 3}}
                    size='small'
                  />
                  <CustomTextField
                    name='communication_channels'
                    label='Доп каналы связи'
                    sx={{width: '100%', mb: 3}}
                    size='small'
                    rows={3}
                    multiline
                  />
                  <Controller
                    name='birth_date'
                    control={control}
                    rules={{
                      required: t('main.another.requiredField'),
                    }}
                    render={({
                               field: {onChange, value},
                               fieldState: {error},
                             }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label='Дата рождения'
                          control={control}
                          value={dayjs(value)}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          format='DD/MM/YYYY'
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: 'small',
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                          sx={{
                            width: '100%',
                            mb: 3,
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <CustomTextField
                    name='country'
                    label='Страна'
                    sx={{width: '100%', mb: 3}}
                    size='small'
                  />
                  <CustomTextField
                    name='street_address'
                    label='Почтовый адрес'
                    sx={{width: '100%', mb: 3}}
                    size='small'
                    rows={3}
                    multiline
                  />
                  <CustomTextField
                    name='work_schedule'
                    label='Рабочий график'
                    registerOptions={{
                      required: t('main.another.requiredField'),
                    }}
                    sx={{width: '100%', mb: 3}}
                    size='small'
                    rows={3}
                    multiline
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button variant='contained' color='primary' type='submit'>
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful}/>
    </Card>
  );
};

export default UserData;
