import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack, Switch,
  TextField,
  Toolbar, Typography
} from "@mui/material";
import Iconify from '../../components/iconify'
import AccountPopover from './AccountPopover'
import LanguagePopover from './LanguagePopover'
import Logo from '../../components/Logo'
import SearchFieldInAppBar from './SearchFieldInAppBar'
import useResponsive from '../../hook/useResponsive'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, {useRef} from 'react'
import {HEADER_DESKTOP, HEADER_MOBILE, NAV_WIDTH_CLOSE} from '../../constants'
import {SearchInput, StyledRootAppBar, StyledToolbar} from '../../theme/standarts_styles'
import { useActions } from '../../hook/useActions'
import MenuIcon from '@mui/icons-material/Menu';
import {Icon} from "@iconify/react";
import SearchIcon from "@mui/icons-material/Search";
import Collapse from "@mui/material/Collapse";
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';


Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header () {
  const { t } = useTranslation()

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const containerRef = useRef(null);
  const { sseChannelError } = useSelector(state => state.serviceReducer)
  const { isInitAuth, roles } = useSelector(state => state.authReducer)

  // const currentLocation = status === 'Active' || status === 'Locked'
  const currentLocation = isInitAuth === true
  const isDesktop = useResponsive('up', 'lg')

  return (
    <StyledRootAppBar>
      <StyledToolbar>
        <Logo/>
        <Box sx={{ flexGrow: 1 }}/>
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            width="100%"
            // xs={6}

        >
          <Grid item ref={containerRef}>
            {isInitAuth === true && isDesktop &&
              <Collapse
                  in={checked}
                  orientation="horizontal"
              >
                <SearchFieldInAppBar width="300px" />
              </Collapse>
            }
          </Grid>
          <Grid item>
            {isInitAuth === true && isDesktop &&
              <IconButton
                  sx={{
                    color: 'layout.element'
                  }}
                  onClick={handleChange}
              >
                <SearchIcon/>
              </IconButton>
            }
          </Grid>
          <Grid item>
            {/*{ isDesktop && <LanguagePopover/>}*/}
            {/*<LanguagePopover/>*/}

          </Grid>
          <Grid item>
            {currentLocation && <AccountPopover/>}
          </Grid>
        </Grid>
      </StyledToolbar>
    </StyledRootAppBar>
  )
}
