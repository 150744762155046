import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  calendar_rules: [],
};

const CalendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setCalendarRules(state, { payload }) {
      state.calendar_rules = payload;
    },
  },
});

export const { actions: calendarActions, reducer: calendarReducer } =
  CalendarSlice;
export default CalendarSlice.reducer;
