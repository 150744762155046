import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomTextField from '../../TextFields/CustomTextField'
import CustomDatepicker from '../../TextFields/CustomDatepicker'
import { $authHost } from '../../../http'
import CustomMultiAutocompleteForObjectLocalized from '../../TextFields/CustomMultiAutocompleteForObjectLocalized'
import CustomDateRangePicker from '../../TextFields/CustomDateRangePicker'
import { getLocalizedForDropdownValues } from '../../../utils/getLocalizedForDropdownValues'
import { useSelector } from "react-redux";
import { useActions } from "../../../hook/useActions";
import { Autocomplete, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Alert from "@mui/material/Alert";

const LeaveApplicationFields = ({ openForm }) => {

	const { t } = useTranslation()
	const { roles } = useSelector(state => state.authReducer)
	const {users} = useSelector((state) => state.usersReducer);
	const {setUsers} = useActions();

	const {
		control,
		formState: { errors },
		setValue
	} = useFormContext()

	const [payerOptions, setPayerOptions] = useState([])
	const [typeOptions, setTypeOptions] = useState([])

	const [selectedTypeOption, setSelectedTypeOption] = useState(null)

	const loadData = useCallback(async () => {
		try {
			const [payerOptionsResponse, typeOptionsResponse, usersResponse] = await Promise.all([
				$authHost.get(`/dropdowns/payer_options/`),
				$authHost.get(`/dropdowns/type_options/`),
				$authHost.get(`/users/all/`)
			])
			setPayerOptions(payerOptionsResponse.data.dropdown)
			setTypeOptions(typeOptionsResponse.data.dropdown)
			setUsers(usersResponse.data.items);
		} catch (e) {
		}
	}, [])

	const FilterPayOptionForTypes = (options) => {
		let baseRule = [
			{
				original_name: "company_expense",
				available_types: ["vacation", "sick_leave", "overtime_work_days"]
			},
			{
				original_name: "self_expense",
				available_types: ["vacation", "sick_leave"]
			},
			{
				original_name: "overwork_expense",
				available_types: ["day_off"]
			}
		]
		let available_original_name = baseRule.filter((el) => el.available_types.includes(selectedTypeOption));
		return options.filter((el) => available_original_name.map(x => x.original_name).includes(el.original_name));
	}

	useEffect(() => {
		// if (openForm !== 'View') {
		loadData()
		// }
	}, [loadData])

	return (
		<>
			<CustomMultiAutocompleteForObjectLocalized
				name="type"
				label="Тип заявления"
				options={getLocalizedForDropdownValues(typeOptions, 'type')}
				getoptionlabel={(option) => option.name}
				rules={{
					required: openForm !== 'View' && t('main.another.requiredField'),
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
				disabled={openForm === 'View'}
				onChangeValue={(e)=>{
					setSelectedTypeOption(e);
					setValue("payer", null)
					setValue("start_date", null)
					setValue("end_date", null)
					setValue("users", null)
					setValue("request_text", null)
				}}
			/>
			{selectedTypeOption !== 'fired' &&
				<CustomMultiAutocompleteForObjectLocalized
					name="payer"
					label="За чей счет"
					options={openForm === 'View'? getLocalizedForDropdownValues(payerOptions, 'payer') : FilterPayOptionForTypes(getLocalizedForDropdownValues(payerOptions, 'payer'))}
					rules={{
						required: selectedTypeOption !== 'fired' && openForm !== 'View' && t('main.another.requiredField'),
					}}
					sx={{ width: '100%', fontSize: '12px', mb: 3 }}
					size="small"
					disabled={openForm === 'View'}
					onChangeValue={(e)=>{}}
				/>
			}
			{selectedTypeOption !== 'fired' &&
				<CustomDateRangePicker
					startName="start_date"
					endName="end_date"
					// label={t('src.components.exportComponents.PaymentPlanForm.table_date', 'Дата начала')}
					startLabel="Дата начала"
					endLabel="Дата окончания"
					disablePast={true}
					rules={{
						required: selectedTypeOption !== 'fired' && openForm !== 'View' && t('main.another.requiredField'),
					}}
					sx={{ width: '100%', fontSize: '12px', mb: 3 }}
					size="small"
					disabled={openForm === 'View'}
				/>
			}
			{selectedTypeOption === 'fired' &&
				<CustomDatepicker
					name="start_date"
					label="Дата увольнения"
					format="DD.MM.YYYY"
					disablePast={true}
					rules={{
						required: selectedTypeOption === 'fired' && openForm !== 'View' && t('main.another.requiredField'),
					}}
					sx={{ width: '100%', fontSize: '12px', mb: 3 }}
					size="small"
					disabled={openForm === 'View'}
				/>
			}

			{selectedTypeOption === 'fired' && roles?.some( role => role === 'ERP_Admins') &&
				<>
					<Controller
						control={control}
						rules={{
							required: selectedTypeOption === 'fired' && roles?.some( role => role === 'ERP_Admins') && openForm !== 'View' && t('main.another.requiredField'),
						}}
						name='users'
						render={({
											 field: { onChange, ...field },
											 fieldState: { error },
										 }) => {
							return (
								<Autocomplete
									{...field}
									options={users}
									getOptionLabel={(option) => option?.display_name || '' }
									size='small'
									renderInput={(params) => (
										<TextField
											label='Сотрудник *'
											{...params}
											error={!!error}
											helperText={error?.message}
											InputLabelProps={{
												shrink: true,
											}}
											sx={{ width: '100%', fontSize: '12px', mb: 3 }}
										/>
									)}
									onChange={(e, value) => {
										onChange(value);
									}}
								/>
							);
						}}
					/>
					<Alert severity="info" sx={{mb: 3}}>
						Обратите внимание, что заявлению на увольнение сотрудника, созданному Администратором, не требуется согласование, оно будет автоматически принято.
					</Alert>
				</>
			}
			<CustomTextField
				name="request_text"
				// label={t('src.components.forms.formFields.LeaveApplicationFields.groupName', 'Тип заявления')}
				label="Текст запроса"
				registerOptions={{
					required: openForm !== 'View' && t('main.another.requiredField'),
				}}
				sx={{ width: '100%', fontSize: '12px', mb: 3 }}
				size="small"
				multiline={true}
				rows={3}
				disabled={openForm === 'View'}
			/>
		</>
	)
}

export default LeaveApplicationFields