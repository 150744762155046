import Stack from '@mui/material/Stack';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from '../../components/TextFields/CustomTextField';
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/modals/loadingSpinner';
import { $authHost } from '../../http';
import { useNavigate } from 'react-router-dom';
import BlockFormModal from '../../components/BlockFormModal';
import { TitleStack } from '../../theme/standarts_styles';
import Container from '@mui/material/Container';

const ClientsAddForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
    control,
    setError,
    clearErrors,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let new_arr = {
        address: data.address || null,
        code: data.code || null,
        country: data.country || null,
        details: data.details || null,
        email: data.email || null,
        full_name: data.full_name || null,
        name: data.name || null,
        web_site: data.web_site || null,
      }
      new_arr['contacts'] = [];
      return await $authHost.post(`/clients/add/`, new_arr);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/clients');
  };

  const checkUniqueCode = async (value) => {
    const {data} = await $authHost.get(`/clients/unique_check/?code=${value}`);
    if (data?.unique_code === false) {
      setError('code', { type: 'custom', message: 'Код должен быть уникальным' });
    }
    if (data?.unique_code === true) {
      clearErrors('code');
    }
  }

  const validateName = async (value) => {
    if (value) {
      const trimmedName = value.toString().trim();
      if (trimmedName) {
        const {data} = await $authHost.get(`/clients/unique_check/?name=${trimmedName}`);
        if (data?.unique_name === false) {
          return 'Наименование должно быть уникальным';
        }
        if (data?.unique_name === true) {
          return true;
        }
      }
    }
  }

  const validateCode = async (value) => {
    if (value) {
      const trimmedCode = value.toString().trim();
      if (trimmedCode) {
        const {data} = await $authHost.get(`/clients/unique_check/?code=${trimmedCode}`);
        if (data?.unique_code === false) {
          return 'Код должен быть уникальным';
        }
        if (data?.unique_code === true) {
          return true;
        }
      }
    }
  }

  return (
    <Container>
      <TitleStack>
        <Typography variant='h4'>Сведения о клиенте</Typography>
      </TitleStack>
      <Box sx={{ flexDirection: 'column', px: 0, py: 4 }}>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form>
            <CustomTextField
              name='name'
              label='Наименование'
              registerOptions={{
                required: t('main.another.requiredField'),
                validate: (value) => validateName(value),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='full_name'
              label='Полное наименование'
              registerOptions={{
                required: t('main.another.requiredField'),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='code'
              label='Код клиента'
              registerOptions={{
                required: t('main.another.requiredField'),
                validate: (value) => validateCode(value),
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='address'
              label='Адрес'
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
              multiline={true}
              rows={3}
            />
            <CustomTextField
              name='web_site'
              label='Веб-сайт'
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='email'
              label='Email'
              registerOptions={{
                // required: t('main.another.requiredField'),
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: t('main.another.wrongInput'),
                },
              }}
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <CustomTextField
              name='details'
              label='Реквизиты'
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
              multiline={true}
              rows={3}
            />
            <CustomTextField
              name='country'
              label='Страна'
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              sx={{ width: '100%', fontSize: '12px', mb: 3 }}
              size='small'
            />
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item>
                <Button
                  variant='contained'
                  onClick={handleSubmit((data) => {
                    onSubmit(data).then((res) => {
                      if (res) {
                        navigate('/clients');
                      }
                    });
                  })}
                >
                  Сохранить
                </Button>
                <Button
                  variant='contained'
                  sx={{ ml: 1 }}
                  onClick={handleSubmit((data) => {
                    onSubmit(data).then((res) => {
                      if (res?.data?.id) {
                        navigate(`/clients/edit/${res.data.id}/contacts`);
                      } else if (res) {
                        navigate('/clients');
                      }
                    });
                  })}
                >
                  Сохранить и создать контакты
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  sx={{ ml: 1 }}
                  onClick={handleBack}
                >
                  Назад
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='text'
                  color='primary'
                  sx={{ ml: 1 }}
                  onClick={() => reset()}
                >
                  Сбросить
                </Button>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Box>
    </Container>
  );
};

export default ClientsAddForm;
