import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Controller, useFormContext } from 'react-hook-form'

const CustomMultiAutocompleteForObjectLocalized = ({
	name,
	label,
	options,
	rules,
	multiple = false,
																										 onChangeValue,
	size,
	...rest
}) => {
	const { control, formState } = useFormContext()

	const error = formState.errors[name]
	const req = !!rules?.required

	return (
		<div style={{ position: 'relative', marginBottom: '12px', width: '100%' }}>
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<Autocomplete
						multiple={multiple}
						// value={value ? options.filter(option => value.some(val => val.name === option.name)) : []}
						value={
							multiple
								? options && value ? options.filter(option => value.some(val => val.original_name === option.original_name)) : []
								: options && value && options.find((option) => option.original_name === value) || null
						}
						id={name}
						options={options}
						getOptionLabel={(option) => option.name}
						onChange={(_, selectedOptions) => {
							onChange(
								multiple
									? selectedOptions || []
									: selectedOptions?.original_name || null
							);
							onChangeValue(multiple
								? selectedOptions || []
								: selectedOptions?.original_name || null);
						}}
						size={size}
						disabled={!!rest.disabled}
						renderInput={(params) => (
							<TextField
								{...params}
								{...rest}
								variant="outlined"
								label={`${label}${req ? ' *' : ''}`}
								error={!!error}
								size={size}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						)}
					/>
				)}
			/>
			<span
				style={{
					position: 'absolute',
					bottom: '4px',
					fontSize: '12px',
					color: 'red',
					left: 14,
				}}
			>
        {error && (error?.message || 'Unknown error!')}
      </span>
		</div>
	)
}

export default CustomMultiAutocompleteForObjectLocalized
