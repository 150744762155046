import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errorAlertMessage: '', // TODO выделить сообщения в отдельный массив
  successAlertMessage: '',  // TODO выделить сообщения в отдельный массив
  infoAlertMessage: '',  // TODO выделить сообщения в отдельный массив
  openNav: true,
  sseChannelStatus: false,
  sseChannelError: false,
  isDarkTheme: false
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetAllAlertMessage (state) {
      return {
        ...state,
        errorAlertMessage: '',
        successAlertMessage: '',
        infoAlertMessage: '',
      }
    },
    setSuccessAlertMessage (state, { payload }) { state.successAlertMessage = payload },
    resetSuccessAlertMessage (state) { state.successAlertMessage = initialState.successAlertMessage },
    setErrorAlertMessage (state, { payload }) { state.errorAlertMessage = payload },
    resetErrorAlertMessage (state) { state.errorAlertMessage = initialState.errorAlertMessage },
    setInfoAlertMessage (state, { payload }) { state.infoAlertMessage = payload },
    resetInfoAlertMessage (state) { state.infoAlertMessage = initialState.infoAlertMessage },
    setOpenNav (state, { payload }) { state.openNav = payload },
    setIsDarkTheme (state, { payload }) { state.isDarkTheme = payload },
    toggleOpenNav (state) { state.openNav = !state.openNav },
    changeSseChannelStatus (state, { payload }) {
      state.sseChannelStatus = payload
    },
    changeSseChannelError (state, { payload }) {
      state.sseChannelError = payload
    },
  },
})

export const { actions: serviceActions, reducer: serviceReducer } = serviceSlice
export default serviceSlice.reducer
